@import "../_util/_color.scss";

.textarea-container {
    position: relative;
    border: 1px solid #d3d3d3;
    border-radius: 10px;

    textarea {
        border-radius: 10px;
        border: none;
        resize: none;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 16px 16px;
        outline: none;

        &:focus,
        &:valid {
            outline: none;

            & ~ label {
                &.animated-label {
                    left: 0;
                    margin: -18px 0 40px 0;
                    padding-left: 0;
                    transform: translate(-14px, -21px) scale(0.75);
                    width: 195px;
                    white-space: nowrap;
                }
            }
        }

        & ~ label {
            color: $blue-5;
            font-size: 17px;
            font-weight: 500;
            line-height: 140.5%;
            padding: 15px 0 15px 0;
            pointer-events: none;
            position: absolute;
            top: -55px;

            &.animated-label {
                font-weight: 300;
                line-height: 140.5%;
                padding: 15px 16px 15px 16px;
                top: 0;
                transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            }
        }
    }
}

.textarea-container-outer {
    position: relative;

    > p {
        position: absolute;
        bottom: -23px;
        left: 0px;
        margin: 3px 14px 0;
    }
}
