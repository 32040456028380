@import '../../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .page-container {
    .content-wrap {
      .styled-section-image {

        .styled-section-image-title {
          font-weight: bold;
          color: $blue-1;
          font-size: 20px;
        }

        img {
          &.item-image {
            object-fit: cover;
          }
        }

        .plus-sign {
          max-width: calc(30 / 695 * 100%);

          &.left-plus-sign {
            top: calc(-20 / 695 * 100%);
            left: calc(-17 / 695 * 100%);
          }

          &.right-plus-sign {
            bottom: calc(-20 / 695 * 100%);
            right: calc(-17 / 695 * 100%);
          }

          img {
            display: block;
            height: auto;
            width: 100%;
          }
        }

        .color-rectangle {
          max-width: calc(191 / 695 * 100%);
          border-radius: 20px;

          &.left-rectangle {
            left: calc(-3px - 17px);
            left: 0;
            bottom: -17px;
          }

          &.right-rectangle {
            right: calc(-3px - 17px);
            right: 0;
            top: -17px;
          }
        }
      }
    }
  }
}
