
@media (min-width: 768px) {
    .MuiDialog-root {

        .MuiDialog-paper {

            .MuiDialogTitle-root {

                h2 {
                    font-size: 1.5rem;
                    line-height: 1.3;
                    color: #0063be;
                }
            }
    
            .MuiDialogContent-root {

                .MuiDialogContentText-root {
                }
    
                .MuiTypography-body1 {
                    font-size: 1rem;
                    line-height: 1.5;
                }
            }
    
            .MuiDialogActions-root {
    
                .button2 {
    
                    &:hover{
                        background-color: $orange-1;
                        
                        .MuiButton-label {
                            color: white
                        }
                    }
    
                    .MuiButton-label {
                        color: $orange-1
                    }
                }
    
                .MuiButton-root {
                }
            }
        }
    }
    
    .restricted-popup, .form {
        &.MuiDialog-root {
            .MuiDialog-paper {
                .MuiDialogContent-root {
                }
            }
        }
    }
    
    .form {
        &.MuiDialog-root {
            .MuiDialog-paper {
                .MuiDialogContent-root {
                }
    
                .MuiDialog-paper {
                    .MuiDialogActions-root {
                    }
                }
                }
        }
    }
    
    .ConfirmDialog {
        .MuiBackdrop-root {
        }
    }
}