$padding-around: 165;
$design-width: 1440;
$content-width: $design-width - $padding-around * 2;

@import '../../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .section-article {
    &-container {
      /*flex-direction: row;
      justify-content: space-between;*/
      padding:6rem Max(40px, unquote("(100vw - 1200px) / 2"));
      margin-bottom: 0px;

      &.reverse {
        //Frontera deleted this functionality
        //flex-direction: row-reverse;
        //justify-content: flex-end;

        .section-article-description {
          margin-right: calc(68 / #{$content-width} * 100 * 1%);
        }
      }
    }

    &-title {
      margin: 0;
      max-width: calc(409 / #{$content-width} * 100 * 1%);
      width: 100%;
    }

    &-description {
      /*margin-right: calc(37 / #{$content-width} * 100 * 1%);
      max-width: calc(500 / #{$content-width} * 100 * 1%);*/
      width: 100%;
    }
  }
}

