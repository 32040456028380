.symptom-gallery-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;  
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 30px;
  width: 100%;
}
