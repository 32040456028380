@import '../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .home {
    .hero {
      padding:0px 0px 8rem;
      
      .hero-image-container {
        overflow: hidden;
        aspect-ratio: unset;
        position:absolute;
        padding-bottom:0px;

        &::after {
          display: none;
        }
      }

      .hero-img {
        img {
          //width: 100%;
          //height: 100%;
          object-position: center;
        }
      }
    }

    .hero-content {
      top: 0px;
      height:100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 6rem 0 4rem;
      width: 100%;
      left: 0;
      position:relative;
      
      .hero-content-container{
        gap:1rem;
        position:relative;
      }

      &-container {
        max-width: 598px;

        .site-title {
          font-size: 60px;
          line-height: 1;
          letter-spacing: 0;
          color: #00c2df;
          font-weight: bold;
          margin: 0;

          & + p {
            a {
              margin-top: 30px;
              width: unset;
              font-size: 16px;
              line-height: 19px;
            }
          }
        }

        & > p {
          max-width: 650px;
          margin: 20px 0 0;

          p {
            color: #003565;
            font-size: 1.2rem;
            font-weight: normal;
            line-height: 1.4;
            letter-spacing: 0;
          }
        }

        .cta-link-wrapper {
          a {
            width: unset;
            margin-top: 0rem;
            display: inline-block;
            color: #fff;
            background: $orange-1;
            border-radius: 20px;
            text-transform: uppercase;
            letter-spacing: 0;
            padding: 11px 20px 10px;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }

      img {
        width: 382px;
        height: 88px;
      }
    }

    .fill {
      display: block !important;
    }

    .lower-section {
      background-color: transparent;
      display: flex;
      height: 10rem;
      justify-content: center;
      left: Max(calc((100vw - 1200px) / 2), 40px);
      margin: 0;
      padding: 0;
      position: relative;
      bottom: 0;
      width: 100%;
      z-index: 3;
      max-width: Min(1200px, unquote("100vw - 80px"));

      & * {
        box-sizing: border-box;
      }

      .gallery {
        & > div {
          &.photo {
            height: 550px;
            width: 535px;

            picture {
              img {
                height: 550px;
                width: 535px;
                object-fit: cover;
              }
            }
          }
        }

      .photo {
        top: 50px;
        width: 535px;
        height: 550px;
      }
      }

      .home-page-carousel {
        display: flex;
        gap: 1rem;
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        padding-left:0px;
        padding-right:0px;
        height:auto;

        .carousel-item {
          border-radius: 10px;
          cursor: pointer;
          object-fit: contain;
          overflow: hidden;
          position: relative;
          width: 100%;
          height:auto;

          picture {
            width: 100%;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            z-index: 1;

            img {
              border-radius: unset;
              height: 100%;
              width: 100%;
              object-fit:cover;
            }
          }

          .text {
            display: flex;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;

            h2 {
              color: #fff;
              font-size: 1.2rem;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1.3;
              margin: auto auto 1rem;
              text-shadow:0px 3px 9px rgba(0,0,0,0.6);
            }
          }
        }
      }
    }

    .left-side-linear-blur {
      background: transparent linear-gradient(90deg, #FFFFFF 10%, #FFFFFF00 100%) 0 0 no-repeat padding-box;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: calc(1356 / 1920 * 100%);
    }
  }

  .flex-container {
    margin: 0;
  }
}

@media (min-width: 1920px) {
  .home {
    .hero {
      .hero-image-container {
        height: calc(100vh / 3 * 2);
      }

      .left-side-linear-blur {
        height: calc(100vh / 3 * 2);
      }
    }
  }
}

@media (min-width: $desktop_width_from) and (max-width: 1366px) {
  .home {
    .lower-section {
      .home-page-carousel {
        .carousel-item {
          picture {
            img {
              display: block;
              aspect-ratio: 210 / 160;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
