@import "../../scss/_util/color";
@import "../../scss/_util/mixin";

$border-padding-on-side: Max(40px, unquote("(100vw - 1400px) / 2"));

.dynamic-page {
  overflow: hidden;
  padding: 0;
  background: #f4f8fa;

  h1 {
    font-family: 'interstate',arial,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 34px;
    letter-spacing: 0.4px;
    color: $blue-2;
  }

  h2 {
    text-align: left;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.4px;
    color: #00c2df;
  }

  h4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
    letter-spacing: 0.4px;
    color: $blue-1;
  }

  .subtitle {
    font-weight: 400;
    max-width: 610px;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.4px;
    color: $blue-7;
  }

  .body-18 {
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.5;
    letter-spacing: 0.4px;
  }

  .MuiBreadcrumbs-root {
    display: none;
  }

  .upper-section {
    align-items: center;
    background: linear-gradient(180deg, $blue-8 40.56%, $blue-10 157.78%);
    display: flex;
    justify-content: center;
    margin-left: -25px;
    margin-right: -25px;
    min-height: 250px;
    mix-blend-mode: normal;
    overflow: hidden;
    position: relative;

    .hero {
      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 25px 25px;

        .logo-container {
          display: none;
        }

        > .MuiFormControl-root {
          width: 325px;
        }
      }

      &-title {
        color: $white-1;
        font-size: 33px;
        font-weight: bold;
        letter-spacing: 0.4px;
        line-height: 41px;
        text-align: center;
      }
    }

    &::after {
      background: #f4f8fa;
      border-radius: 100%;
      bottom: -10%;
      content: "";
      height: 20%;
      left: -10%;
      max-width: 120%;
      position: absolute;
      right: -10%;
      width: 120%;
    }
  }

  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////// Lower section default
  
  .lower-section {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    
    
    /////////////////////////////////////////////////////////
    ///////////// Hero Area
    
    .hero-section-text {
      max-width: 100%;
      width:100%;
      padding: 0;
      display: flex;
      flex-direction: column-reverse;
      //gap: 25px;
      background: #fff;
      margin: 0;
      position:relative;

      .hero-section-text-inner-container {
          position: relative;
          display:flex;
          flex-flow:column;
          max-width: 100%;
          width: 100%;
          top: 0;
          bottom: 0;
          justify-content: center;
          left: 0px;
          gap: 1rem;
          z-index: 1;
          padding: 6rem 25px;
          margin-top: 0;
          

        h1 {
          margin: 0;
          text-align: left;
          font-size: 2.5rem;
          line-height: 1.1;
          font-weight: bold;
          letter-spacing: 0;
          
          sup,sub{
            color:inherit;
          }
        }

        .subtitle {
          margin: 0;
          text-align: left;
          letter-spacing: 0;
        }

        p {
          font-size: 1.2rem;
          line-height: 1.4;
          margin: 0;
          color: rgba(255,255,255,0.9);
          font-weight: normal;
          letter-spacing: 0;
            sup,sub{
              color:inherit;
              font-size:10px;
              line-height:14px;
            }          
        }
      }

      .hero-section-image-container {
            display: block;
            width: 100%;
            object-position: unset;
            max-width: 100%;
            object-fit: cover;
            min-height: unset;
            position: absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;
            max-height: 100%;
            overflow: hidden;        

        .hero-section-image {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          min-height: calc(220 / 375 * 100vw);
          object-position: calc(100 / 3 * 2%) 50%;
        }
        
        &::before {
          content: "";
          background: transparent linear-gradient(91deg, #182c4c 0%, rgba(24,44,76,0.8) 100%) 0% 0% no-repeat padding-box;
          top: 0;
          left: 0;
          bottom: 0;
          /*width: calc(1356 / 1920 * 100%);*/
          width:100%;
          position: absolute;
        }             

        &::after {
          content: "";
          bottom: 0;
          left: 0;
          right: 0;
          height: 25px;
          width: 100%;
          position: absolute;
          display:none;
          background: linear-gradient(
                          180deg,
                          rgba(244, 248, 250, 0) 0%,
                          rgba(244, 248, 250, 0.75) 67%,
                          rgba(244, 248, 250, 1) 100%
          );
        }
        
        
      }

      & ~ .simple-section {
        gap: 15px;

        .simple-section-title {
          letter-spacing: 0;
          font-size: 2.25rem;
          line-height: 1.2;
        }

        .description-container {
          p {
            font-size: 1.2rem;
            line-height: 1.5;
            letter-spacing: 0;
            font-weight: normal;
            color: #003565;
          }
        }
      }
    }    
    
    
    /////////////////////////////////////////////////////////
    ///////////// Carousel Container and cards    
    
    .carousel-container {
      max-width: 100%;
      margin-top:3rem;
      margin-bottom:3rem;
      padding:0px 2rem;

      .MuiBox-root {
        margin: 0 0 25px;
        padding: 0 0px;

        h4 {
          font-size: 1.2rem;
          line-height: 1.4;
          margin: 0 0 15px;
          letter-spacing: 0;
          font-weight: bold;
        }

        .body-18,
        p {
          font-size: 1.2rem;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: 0;
          color: #003565;
          margin: 0;
        }
      }

      .cards-wrapper {
        gap: 2rem;
        padding: 0px;
        margin: 0;

        .card-wrapper {
          padding: 2rem;
          border-radius: 10px;
          background: #fff;
          max-width: 100%;
          width: 100%;

          .card {
            height: 100%;

            .link {
              max-width: 100%;
              max-height: unset;
              height: 100%;
              width:100%;
              display:flex;

              picture {
                width: 100%;
                height: 100%;

                img {
                  object-fit: cover;
                  display: block;
                  width: 100%;
                  height: auto;
                  aspect-ratio: 271 / 220;
                  border-radius: unset;
                }
              }

              .content {
                position: relative;
                margin: 0;
                margin-top:2rem;
                padding: 0;
                min-height: unset;

                .content-title {
                  color: #0063be;
                  font-size: 1.4rem;
                  font-weight: bold;
                  letter-spacing: 0;
                  line-height: 1.3;
                  text-align: left;
                  margin: 0;
                }

                .content-description {

                  color: #003565;
                  font-size: 1.2rem;
                  letter-spacing: 0;
                  line-height: 1.5;
                  font-weight: normal;

                  p,
                  span {
                    color: #003565;
                    font-size: 1.2rem;
                    letter-spacing: 0;
                    line-height: 1.5;
                    font-weight: normal;
                  }
                }
              }

              .read-more {
                padding-top: 20px;
                margin-top: auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .text {
                  color: $orange-1;
                  font-size: 1.2rem;
                  font-weight: normal;
                  letter-spacing: 0;
                  line-height: 1.5;
                  text-transform: uppercase;
                  margin-right: 5px;
                  text-align: left;
                }

                .arrow-container {
                  color: $orange-1;
                  transition: margin-left 0.5s ease;
                  letter-spacing: 0;
                  min-width: 13px;

                  img {
                    max-height: 11px;
                    max-width: 13px;
                    width: 100%;
                    height: 100%;
                    display: block;
                  }
                }

                &:hover {
                  .arrow-container {
                    margin-left: 0.5em;
                  }
                }
              }
            }
          }
        }
      }

      &:nth-of-type(even) {
        background: #fff;

        .cards-wrapper {
          .card-wrapper {
            background: #f4f8fa;

            .card {
              a {
                .content {
                  background: #f4f8fa;
                }
              }
            }
          }
        }
      }

      & ~ .carousel-container {
        margin-top: 0;

        .MuiBox-root {
          padding-top: 50px;
        }
      }
    }


    &.no-max-width {
      max-width: unset;
      width: 100%;
      margin-bottom:50px;

      & > * {
        max-width: unset;
        background: #fff;

        &:nth-child(2n) {
          background: #f4f8fa;
        }
      }
      .hero-section-text{
        background:transparent linear-gradient(91deg, #152845 5%, #182c4c 100%) 0% 0% no-repeat padding-box;  
        
      }
      
      .below-hero {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 50px 25px;

        &-inner-container {
          max-width: 100%;
          margin: 0 auto;

          h4 {
            margin: 0;
            text-align: left;
            font-size: 1.2rem;
            line-height: 1.4;
            font-weight: bold;
            letter-spacing: 0;
          }

          .description-container {
            p {
              font-size: 1.2rem;
              line-height: 1.5;
              margin: 0;
              color: #003565;
              font-weight: normal;
              letter-spacing: 0;
            }
          }

          .cta-small-container {
            padding: 0;

            .cta-small {
              margin: 0;
              padding: 0 10px 0 0;
              text-transform: uppercase;
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
              font-weight: normal;
            }
          }

          .simple-section {
            display: flex;
            flex-direction: column;
            gap: 15px;
          }
        }
      }

      .content-with-simple-image{
         padding: 3rem 1.5rem;
         margin-top:0px;
         display:flex;
         flex-direction:column;
         flex-wrap:nowrap;
         gap:3rem;
         
         .simple-image-container{
           width:100%;
           display:flex;
           
           &.simple-image-framed{
             background:#e7edf0;
             border-radius:1.25rem;
             padding:1rem;
           }
           
           div{
            margin-left: auto;
            width: 100%;
            height: auto;
            display: flex;
            flex-direction:column;
             
             img{
               width:100%;
               height:auto;
             }
           }
         }
         .content-container{
           width:100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 2rem;
          
          .simple-section{
            display:flex;
            flex-direction:column;
            gap:1.25rem;
            
            h4{
              font-size:36px;
              line-height:44px;
              margin-bottom:0px;
              margin-top:0px;
            }
          }
           
         }
      }

      .content-with-image{
        margin: 0;
        padding: 3rem 1.5rem;
        display: flex;

        &.split-title {
          .content-with-image-inner-container {
            .text-column {
              .text-container {
                h4 {
                  &.simple-section-subtitle {
                    font-size: 12px;
                    line-height: 15px;
                    color: #003565;
                    text-transform: uppercase;
                    order: 1;
                    margin-bottom: -10px;
                  }

                  &.simple-section-title {
                    order: 2;
                  }
                }

                .description-container {
                  order: 3;
                }

                .cta-small-container {
                  order: 4;
                }
              }
            }
          }
        }

        .content-with-image-inner-container {
          margin: 0 auto;
          max-width: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 30px;

          & > * {
            width: 100%;
          }

          .image-container {
            h4,
            .description-container {
              display: none;
            }
          }

          .text-column {
            display: flex;
            flex-direction: column;
            gap: 50px;
          }

          .text-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            & > * {
              display: none;
            }

            .cta-small-container,
            .description-container {
              display: flex;
            }

            h4 {
              display: block;
              font-size: 1.2rem;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1.4;
              text-align: left;
              margin: 0;
            }

            .description-container {
              flex-direction: column;
              gap: 20px;

              p {
                margin: 0;
                font-weight: normal;
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
              }

              ul {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 0;

                li {
                  margin: 0;
                  font-weight: normal;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  display:flex;
                  flex-wrap:wrap;
                  
                  span{
                    width:calc(100% - 35px);
                  }
                  
                  ul{
                    padding-left:1rem;
                    display:flex;
                    gap:0.5rem;
                    padding-top:1rem;
                    
                    li{
                      
                    }
                  }

                  strong {
                    font-weight: bold;
                  }
                }
              }
            }

            .cta-small-container {
              margin: 0;
              padding: 0;

              a {
                p {
                  margin: 0;

                  &.cta-small {
                    padding-right: 0px;
                    font-size: 1.2rem;
                    line-height: 1.5;
                    letter-spacing: 0;
                    text-transform: uppercase;
                    font-weight: normal;
                  }
                }
              }
            }
          }
        }

        &.image-on-left {
          .content-with-image-inner-container {
            flex-direction: column;

            .right-rectangle {
              background: #0063be;
            }
          }
        }

        &.image-on-right {
          .content-with-image-inner-container {
            flex-direction: column;

            .plus-sign {
              &::before,
              &::after {
                background: #f7a900;
              }
            }

            .styled-section-image-inner {
              border-color: #fff;
            }

            .left-rectangle {
              background: #0063be;
            }
          }
        }

        &.tablet-image {
          .content-with-image-inner-container {
            .image-container {
              .styled-section-image {
                .styled-section-image-inner {
                  position: relative;

                  .image-wrapper {
                    overflow: hidden;
                    border-radius: 7px;

                    &::before {
                      content: "";
                      background-image: url("../../../resources/images/common/tablet_border.png");
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      top: 17px;
                      left: 17px;
                      right: 17px;
                      bottom: 17px;
                      position: absolute;
                      display:none;
                    }

                    img {
                      border-radius: 0;
                    }
                  }
                }
              }
            }
          }
        }

        &.notebook-image {
          .content-with-image-inner-container {
            .image-container {
              max-width: 340px;
              margin: auto;

              .styled-section-image {
                .styled-section-image-inner {
                  position: relative;

                  .left-plus-sign {
                    top: 2em;
                  }

                  .right-plus-sign {
                    bottom: 2em;
                  }

                  .image-wrapper {
                    overflow: hidden;
                    border-radius: 0;

                    &::before {
                      content: "";
                      background-image: url("../../../resources/images/common/laptop_border.png");
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      top: 10px;
                      bottom: 3px;
                      left: -23px;
                      right: -23px;
                    }

                    img {
                      border-radius: 0;
                    }
                  }
                }
              }
            }
          }
        }

        &.mobile-image {
          .content-with-image-inner-container {
            .image-container {
              .styled-section-image {
                max-width: 219px;
                //min-height: 373px;
                margin: 0 auto;

                .styled-section-image-inner {
                  max-width: 219px;
                  //min-height: 373px;
                  display: flex;
                  position: relative;

                  .image-wrapper {
                    overflow: hidden;
                    border-radius: 20px;
                    display: flex;
                    margin-inline: auto;

                    padding: 10px;
                    aspect-ratio: 298 / 575;
                    justify-content: center;
                    align-items: center;

                    &::before {
                      content: "";
                      background-image: url("../../../resources/images/common/mobile_border_cropped.png");
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      top: 17px;
                      left: 17px;
                      right: 17px;
                      bottom: 17px;
                      position: absolute;

                      aspect-ratio: 298 / 575;
                    }

                    img {
                      border-radius: 0;
                      width:100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .section-article-container {
        margin: 0;
        padding: 50px 25px;
        display: flex;

        .section-article-inner-container {
          max-width: 100%;
          // margin: 0 auto;

          .section-article-title,
          .section-article-description {
            max-width: 100%;
          }

          .section-article-title {
            gap: 8px;
            margin-bottom: 15px;

            .section-article-string-1 {
              color: #003565;
              font-weight: bold;
              font-size: 12px;
              line-height: 1;
            }

            .section-article-string-2 {
              color: #0063be;
              font-size: 1.2rem;
              line-height: 1;
              font-weight: bold;
            }
          }

          .section-article-description {
            & > div {
              &:not(.cta-small-container) {
                gap: 20px;
                display: flex;
                flex-direction: column;

                p {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  font-weight: normal;
                }
              }
            }

            .cta-small-container {
              padding-top: 25px;
              font-size: 18px;
              line-height: 30px;
              letter-spacing: 0;
              font-weight: normal;

              a {
                .cta-small {
                  font-size: 1.2rem;
                  letter-spacing: 0;
                  line-height: 1.5;
                  font-weight: normal;
                  text-transform: uppercase;
                  padding-right: 0px;
                }
              }
            }
          }
        }
      }

      .single-card-gallery-outer-container {
        max-width: calc(100% - 50px);
        width: 100%;
        margin: 0 auto;
        padding: 50px 0;
        display: flex;
        border-radius: 10px;

        .single-card-gallery-container {
          padding: 0;
          background: transparent;
          border-radius: unset;
          max-width: 100%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          gap: 3rem;
          width: 100%;

          .single-card-text-container {
            padding: 0;
            text-align:left;
            display:flex;
            flex-direction:column;
            gap:1rem;

            .single-card-title {
              margin: 0 !important;
              font-weight: bold;
              font-size: 1.2rem;
              letter-spacing: 0;
              line-height: 1.4;
              color: #0063be;
              text-transform: unset;
            }

            .single-card-description {
              margin:0px;
              p,
              span {
                letter-spacing: 0;
                font-weight: normal;
              }
            }
          }

          .cards-wrapper {
            margin: 0;
            gap: 20px;
            justify-content: space-around;

            .card-wrapper {
              width: 100%;
              max-width:100%;
              padding:0px 1rem;
              
              p{
                text-align:left;
              }
            }
          }
        }
      }

      .text-left-symptom-carousel-right {
        margin: 0;
        padding: 50px 25px;

        .text-left-symptom-carousel-right-inner {
          max-width: 100%;
          display: flex;
          width: 100%;
          padding: 0;
          margin: 0 auto;
          gap: 50px;
          flex-direction: column;

          .left-side-container {
            display: flex;
            flex-direction: column;
            gap: 50px;
            width: 100%;

            .cta-small-container {
              padding-top: 5px;

              a {
                color: $orange-1;
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
                font-weight: normal;
                text-transform: uppercase;

                p {
                  padding-right: 10px;
                  line-height: 1.5;
                  text-transform: uppercase;
                }
              }
            }

            .section-article-container {
              padding: 0;

              .section-article-description {
                & > div {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                }

                p {
                  font-size: 18px;
                  line-height: 30px;
                }
              }
            }

            & > section {
              display: flex;
              flex-direction: column;
              gap: 20px;

              h4 {
                font-size: 1.2rem;
                line-height: 1.4;
                letter-spacing: 0;
                font-weight: bold;
                text-align: left;
                margin: 0;
              }

              & > div {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  padding: 0;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  font-weight: normal;
                }
              }
            }
          }

          & > section {
            width: 100%;

            &:last-child {
              & > div {
                border-radius: 10px;
              }

              .symptom-gallery-container {
                padding: 25px;
                gap: 2rem;
                display: flex;

                .symptom-gallery-item {
                  width: 100%;

                  img {
                    max-width: 63px;
                    max-height: 63px;
                  }

                  .item-title {
                    font-size: 1.2rem;
                    letter-spacing: 0;
                    line-height: inherit;
                    font-weight: normal;
                    margin-left: 0px;
                  }
                }
              }
            }
          }
        }

        &:nth-child(2n) {
          .text-left-symptom-carousel-right-inner {
            & > section {
              &:last-child {
                & > div {
                  background: #fff;
                }
              }
            }
          }
        }

        &:nth-child(2n + 1) {
          .text-left-symptom-carousel-right-inner {
            & > section {
              &:last-child {
                & > div {
                  background: #f4f8fa;
                }
              }
            }
          }
        }

        &.accordion-carousel {
          .text-left-symptom-carousel-right-inner {
            & > section {
              .symptom-gallery-container {
                background: transparent !important;
                padding: 0;

                .symptom-gallery-item {
                  border-bottom: 2px solid rgba(0, 99, 190, 0.5);

                  .no-link-image {
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-end;
                    padding-bottom: 15px;
                    position: relative;

                    .item-alt {
                      font-size: 12px;
                      line-height: 15px;
                      max-width: calc(100% - 93px);
                      opacity: 0;
                      margin-top: -300px;
                      margin-left: 10px;
                      z-index: -1;
                      width:100%;

                      a {
                        display: inline-block;
                      }
                    }

                    .item-title {
                      margin-left: 10px;
                      max-width: calc(100% - 93px);
                      width: 100%;
                      cursor: pointer;

                      &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top:6px;
                        width: 12px;
                        height: 12px;
                        margin-top: 2px;
                        border-top: 2px solid $orange-1;
                        border-left: 2px solid $orange-1;
                        transform: rotate(135deg);
                      }

                      &.open {
                        & ~ .item-alt {
                          opacity: 1 !important;
                          transition: opacity 0.3s linear 0.3s, margin-top 0.3s linear, z-index 0.1s linear;
                          margin-top: 0;
                          z-index: 1;
                        }

                        &::after {
                          transform: rotate(225deg);
                          transition-duration: 0.3s;
                        }

                        &.closing {
                          & ~ .item-alt {
                            opacity: 0 !important;
                            transition: opacity 0.25s linear, margin-top 0.3s linear 0.25s, z-index 0.1s linear;
                            margin-top: -300px;
                            z-index: 0;
                          }

                          &::after {
                            transform: rotate(135deg);
                            transition-duration: 0.3s;
                          }
                        }
                      }
                    }

                    img {
                      background: #fff;
                      padding: 5px;
                      max-width: 73px;
                      max-height: 73px;
                      border-radius: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .text-top-symptom-carousel-bottom {
        padding: 50px 25px;

        .text-top-symptom-carousel-bottom-inner {
          display: flex;
          flex-direction: column;
          gap: 60px;

          .top-side-container {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                margin: 0;
                font-size: 1.2rem;
                letter-spacing: 0;
                text-align: left;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width:100%;

                p,
                li,
                span,
                a,
                strong {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  margin: 0;
                  color: #182c4c;
                }

                a {
                  color: $orange-1;

                  sup {
                    color: #182c4c;
                  }
                }

                strong {
                  font-weight: bold;
                }

                ul {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;
                }
              }
            }
          }

          & > section {
            .symptom-gallery-container {
              .symptom-gallery-item {
                .no-link-image,
                a {
                  flex-wrap: wrap;
                  row-gap: 15px;
                  column-gap: 20px;

                  img {
                    padding: 10px;
                    border-radius: 20px;
                    background: #fff;
                    margin-right: 0;
                  }

                  .item-title {
                    max-width: calc(100% - 127px);
                    width: 100%;
                    text-align: left;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0;
                    font-weight: bold;
                  }

                  .item-alt {
                    font-size: 1.2rem;
                    line-height: 1.5;
                    letter-spacing: 0;

                    a {
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .simple-section-with-multiple-title-and-body {
        padding: 50px 25px;
        margin: 0;

        .simple-section {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .simple-section-title,
          .simple-section-subtitle {
            margin: 0;
          }

          .description-container {
            display: flex;
            flex-direction: column;
            gap: 40px;

            p,
            span {
              margin: 0;
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
            }

            ul {
              padding: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;
              width:100%;

              li {
                margin: 0;
              }
            }

            .manual-body-1,
            .manual-body-2 {
              display: flex;
              flex-direction: column;
              gap: 20px;
            }
          }
        }
      }

      .content-top-multi-boxes-bottom-outer {
        padding: 50px 25px;
        margin: 0;

        &:nth-child(even) {
          .content-top-multi-boxes-bottom-inner {
            .box-wrapper {
              & > .simple-section {
                .description-container {
                  background: #fff;
                }
              }
            }
          }
        }

        .content-top-multi-boxes-bottom-inner {
          display: flex;
          flex-direction: column;
          gap: 40px;

          & > .simple-section {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .simple-section-title {
              font-size: 1.2rem;
              letter-spacing: 0;
              line-height: 1.4;
              margin: 0;
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 20px;
              width:100%;

              p {
                font-size: 1.2rem;
                letter-spacing: 0;
                line-height: 1.5;
                margin: 0;
              }
            }
          }

          .box-wrapper {
            display: flex;
            flex-direction: column;
            gap: 20px;

            & > .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                font-size: 1.2rem;
                letter-spacing: 0;
                line-height: 1.5;
                margin: 0;
                color: #182c4c;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;
                border-radius: 0 0 20px 20px;
                padding: 20px;
                background: #f4f8fa;
                position: relative;
                margin-top: 14px;

                &::before {
                  height: 14px;
                  width: 100%;
                  border-radius: 20px 20px 0 0;
                  content: "";
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: -14px;
                  background: #0063be;
                }

                p,
                span {
                  font-size: 1.2rem;
                  letter-spacing: 0;
                  line-height: 1.5;
                  margin: 0;
                }

                ul {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    margin: 0;
                  }
                }
              }

              &:nth-child(even) {
                .description-container {
                  &::before {
                    background: #31b7cf;
                  }
                }
              }
            }
          }
        }
      }

      .content-with-pros-and-cons-boxes-outer {
        margin: 0;
        padding: 50px 25px;

        .content-with-pros-and-cons-boxes-inner {
          display: flex;
          flex-direction: column;
          gap: 30px;

          .row-wrapper {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title,
              .simple-section-subtitle {
                margin: 0;
                letter-spacing: 0;
                color: #182c4c;
              }

              .simple-section-title {
                font-size: 2.25rem;
                line-height: 1.2;
              }

              .simple-section-subtitle {
                font-size: 1.2rem;
                line-height: 1.5;
                color: #182c4c;
                font-weight: normal;
              }
            }

            .pros-cons-box {
              border-radius: 20px;

              &.pro-icon {
                .header-wrapper {
                  .header-icon {
                    background-image: url("../../../resources/images/common/Icon-ionic-ios-checkmark-circle-outline.png");
                  }
                }
              }

              &.con-icon {
                .header-wrapper {
                  background: #31b7cf;

                  .header-icon {
                    background-image: url("../../../resources/images/common/Icon-ionic-ios-close-circle-outline.png");
                  }
                }
              }

              .header-wrapper {
                background: #0063be;
                border-radius: 20px 20px 0 0;
                padding: 10px 20px;
                display: flex;
                gap: 10px;
                align-items: center;

                .header-icon {
                  height: 25px;
                  width: 25px;
                  position: relative;

                  &::after {
                    content: "";
                    position: relative;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                  }
                }

                .simple-section-title {
                  margin: 0;
                  color: #fff;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                }
              }

              .description-container {
                padding: 15px 20px;
                background: #f4f8fa;
                border-radius: 0 0 20px 20px;

                ul {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    margin: 0;
                    font-size: 14px;
                    line-height: 1.5;
                    letter-spacing: 0;
                  }
                }
              }
            }
          }

          .content-wrapper {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title,
              .simple-section-subtitle {
                margin: 0;
                letter-spacing: 0;
              }

              .simple-section-title {
                font-size: 1.2rem;
                line-height: 1.4;
              }

              .simple-section-subtitle {
                font-size: 1.2rem;
                line-height: 1.5;
                color: #182c4c;
                font-weight: normal;
              }

              &:nth-child(2) {
                .simple-section-title {
                  font-size: 18px;
                  line-height: 1.5;
                  color: #182c4c;
                }
              }
            }
          }

          .box-wrapper {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .pros-cons-box {
              border-radius: 20px;

              &.pro-icon {
                .header-wrapper {
                  .header-icon {
                    background-image: url("../../../resources/images/common/Icon-ionic-ios-checkmark-circle-outline.png");
                  }
                }
              }

              &.con-icon {
                .header-wrapper {
                  background: #31b7cf;

                  .header-icon {
                    background-image: url("../../../resources/images/common/Icon-ionic-ios-close-circle-outline.png");
                  }
                }
              }

              .header-wrapper {
                background: #0063be;
                border-radius: 20px 20px 0 0;
                padding: 10px 20px;
                display: flex;
                gap: 10px;
                align-items: center;

                .header-icon {
                  height: 25px;
                  width: 25px;
                  position: relative;

                  &::after {
                    content: "";
                    position: relative;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                  }
                }

                .simple-section-title {
                  margin: 0;
                  color: #fff;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                }
              }

              .description-container {
                padding: 15px 20px;
                background: #f4f8fa;
                border-radius: 0 0 20px 20px;

                ul {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    margin: 0;
                    font-size: 14px;
                    line-height: 1.5;
                    letter-spacing: 0;
                  }
                }
              }
            }
          }
        }
      }

      .full-width-content-with-cta {
        margin: 0;
        padding: 50px 0;
        display: flex;

        & + .full-width-content-with-cta {
          .full-width-content-with-cta-inner {
            background: #00c2df;
          }
        }

        .full-width-content-with-cta-inner {
          max-width: 1400px;
          width: 80%;
          margin: 0 auto;
          padding: 50px 25px;
          background: #0063be;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          gap: 25px;

          & > section {
            display: flex;
            flex-direction: column;
            gap: 15px;

            h4 {
              color: #fff;
              text-align: left;
              font-size: 1.2rem;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1.4;
              margin: 0;
            }

            .description-container {
              margin: 0;

              p {
                color: #fff;
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
                font-weight: normal;
                margin: 0;
              }

              sup {
                color: $orange-1;

                a {
                  color: $orange-1;
                }
              }
            }
          }

          .call-to-action-container {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 25px;

            .cta-container {
              margin: 0;
              padding: 0;
              width: 100%;
              background: transparent;

              .cta-title {
                &:empty {
                  display: none;
                }
              }

              .cta-button {
                background: #fff;
                box-shadow: none;
                border-radius: 20px;
                padding: 11px 30px 10px;
                width: 100%;

                span {
                  color: $orange-1;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  font-weight: normal;
                  text-transform: uppercase;
                  display: block;
                }
              }

              &:last-child {
                .cta-button {
                  background: $orange-1;

                  span {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }

      & > .simple-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 3rem 2rem;
        margin: 0;
        
        .simple-image{
          width:100%; 
          
          div{
            width:100%;
            
            img{
              width:100%;
            }
          }
        }
        
        & > * {
          max-width: 100%;
          margin: 0 auto;

          &.cta-small-container {
            width: 100%;
          }

          p {
            margin: 0;
            padding: 0;
            font-size: 1.2rem;
            line-height: 1.5;
            letter-spacing: 0;
            font-weight: normal;
          }
        }

        .simple-section-subtitle {
          color: #003565;
          font-size: 1.2rem;
          line-height: 1.5;
          letter-spacing: 0;
          font-weight: normal;
        }

        .simple-section-title {
          width: 100%;
        }

        .video-container {
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          width: calc(100vw - 50px);
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin: 0;
        }
      }

      .single-card-outer-container {
        padding: 50px 25px;

        .single-card-container {
          &.white-card {
            &.has-body-content {
              .single-card-description {
              }
            }

            .single-card-title {
              margin: 0;
            }

            .single-card-description {
              margin-inline: 0;
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
              font-weight: normal;
              color: #003565;
            }

            .single-card-inner-container {
              gap: 15px;
              flex-direction: column-reverse;

              div {
                &[data-rmiz-wrap="visible"] {
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .content-with-multiple-images {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 50px 25px;
        gap: 25px;

        &:nth-child(even) {
          .multi-image-container {
            background: #fff;
          }
        }

        .multi-image-container {
          display: flex;
          flex-direction: column;
          gap: 40px;
          padding: 40px;
          border-radius: 1px;
          background: #f4f8fa;

          .simple-section {
            .description-container {
              display: none;
            }

            div {
              &[data-rmiz-wrap="visible"] {
                display: flex;
                flex-direction: column;

                img {
                  display: block;
                  max-width: 100%;
                  height: auto;
                  width: 100%;
                }

                .single-card-image-text {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                  .single-card-image-title {
                    font-size: 18px;
                    line-height: 30px;
                    letter-spacing: 0;
                    font-weight: bold;
                    color: #0063be;
                  }

                  .single-card-image-alt {
                    font-size: 1.2rem;
                    line-height: 1.5;
                    letter-spacing: 0;
                    font-weight: normal;
                    color: #003565;
                  }
                }
              }
            }
          }
        }

        & > .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 25px;

            p {
              margin: 0;
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
              font-weight: normal;
              color: #003565;
            }
          }
        }
      }
    }

    &:not(.no-max-width) {
      .content-with-image {
        &.tablet-image {
          .content-with-image-inner-container {
            .image-container {
              .styled-section-image {
                .styled-section-image-inner {
                  position: relative;

                  .image-wrapper {
                    overflow: hidden;
                    border-radius: 7px;

                    &::before {
                      content: "";
                      background-image: url("../../../resources/images/common/tablet_border.png");
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      top: 17px;
                      left: 17px;
                      right: 17px;
                      bottom: 17px;
                      position: absolute;
                      display:none;
                    }

                    img {
                      border-radius: 0;
                    }
                  }
                }
              }
            }
          }
        }

        &.notebook-image {
          .content-with-image-inner-container {
            .image-container {
              max-width: 340px;
              margin: auto;

              .styled-section-image {
                .styled-section-image-inner {
                  position: relative;

                  .left-plus-sign {
                    top: 2em;
                  }

                  .right-plus-sign {
                    bottom: 2em;
                  }

                  .image-wrapper {
                    overflow: hidden;
                    border-radius: 0;

                    &::before {
                      content: "";
                      background-image: url("../../../resources/images/common/laptop_border.png");
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      top: 10px;
                      bottom: 3px;
                      left: -23px;
                      right: -23px;
                    }

                    img {
                      border-radius: 0;
                    }
                  }
                }
              }
            }
          }
        }

        &.mobile-image {
          .content-with-image-inner-container {
            .image-container {
              .styled-section-image {
                max-width: 219px;
                //min-height: 373px;
                margin: 0 auto;

                .styled-section-image-inner {
                  max-width: 219px;
                  //min-height: 373px;
                  display: flex;
                  position: relative;

                  .image-wrapper {
                    overflow: hidden;
                    border-radius: 20px;
                    display: flex;
                    margin-inline: auto;

                    padding: 10px;
                    aspect-ratio: 298 / 575;
                    justify-content: center;
                    align-items: center;

                    &::before {
                      content: "";
                      background-image: url("../../../resources/images/common/mobile_border_cropped.png");
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      top: 17px;
                      left: 17px;
                      right: 17px;
                      bottom: 17px;
                      position: absolute;

                      aspect-ratio: 298 / 575;
                    }

                    img {
                      border-radius: 0;

                      aspect-ratio: 298 / 575;
                      max-height: calc(298 / 575 * 100%);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .fullwidth-transparent {
      .single-card-gallery-container {
        & > section {
          .cards-wrapper {
            .card-wrapper {
              .card {
                .card-image-title {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    &.main-page {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom:$section-padding-mobile;
    }

    .logo-container {
      display: none;
    }

    .select {
      margin-top: 1rem;
      margin-bottom: 0px;
    }

    .MuiAccordion-root {
      box-shadow: none;
      background-color: transparent;

      .MuiAccordionSummary-root {
        flex-direction: row-reverse;
        justify-content: flex-end;
        min-height: auto;

        .MuiAccordionSummary-content {
          margin: 0;
        }

        .MuiIconButton-root {
          margin-right: 0px;
        }

        .MuiSvgIcon-root {
          font-size: 1.2rem;
          color: $blue-7;
        }
      }

      h5 {
        font-size: 12px;
        line-height: 1.5;
        font-weight: 600;
      }
    }

    .text-align-center {
      text-align: center;
    }

    > section {

      h4 {
        text-align: start;
      }
    }

    li {
      &::marker {
        color: $blue-1;
      }
    }

    a,
    .link {
      color: $blue-1;
      font-weight: 500;
    }

    a {
      cursor: pointer;
    }

    .carousel-container {

    }

    .column-container {
      margin-top: 50px;

      .text-container {
        height: 223px;
      }

      img {
        height: 100%;
        width: 100%;
      }

      h4 {
        margin-block-end: 10px;
        margin-block-start: 10px;
      }

      .subtitle {
        font-size: 21px;
      }

      .title {
        margin-bottom: 19px;
      }
    }

    iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
      margin: 0;
    }

    .cards-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 30px;
      row-gap: 30px;
      margin-top: 30px;
      text-align: center;

      .card-wrapper {
        max-width: 368px;

        h4 {
          margin-top: 20px;
        }

        .card {
          border-radius: 30px;
          display: flex;
          justify-content: left;

          .link {
            display: flex;
            max-width: 100%;
            position: relative;
            justify-content: center;
            flex-direction: column;

            .card-image-title {
              margin-bottom: 10px;
            }

            picture {
              display: flex;
            }

            .content {
              background: rgba(255, 255, 255, 0.85);
              border-radius: 0 0 29px 29px;
              bottom: 0;
              left: 0;
              padding: 15px 18px;
              position: absolute;
              right: 0;
              min-height: 120px;
              align-items: flex-start;
              display: flex;
              flex-direction: column;
              justify-content: left;
              text-align: start;
              gap:1rem;

              &-title {
                color: $blue-1;
                font-weight: bold;
                margin-bottom: 15px;
                font-size: 20px;
                line-height: 27px;
                letter-spacing: 0.4px;
              }

              &-description {
                font-weight: 400;
                color: $blue-7;
                font-size: 1.2rem;
                line-height: 23px;

                p,
                span {
                  margin: 0;
                  width: 100%;
                  display: block;
                }
              }
            }
          }

          img {
            border-radius: 30px;
            height: 288px;
            width: 288px;
            object-fit: cover;
          }
        }
      }
    }

    .cta-container,
    .cta-double-container {
      max-width: none;
    }
    
    .cta-double-container{
      padding:50px 25px;
      gap:2rem;      
    
      .cta-container{
          background: #0063BE;
          color: #fff;
          gap: 0rem;
          border-radius: 20px;
          
          .cta-title{
            color:#ffffff;
          }   
          
          &:nth-child(2){
            background: #00c2df;  
          }
      }
    }
  

    .single-card {
      &-gallery-container {
        .cards-wrapper {
          .card {
            img {
              height: auto;
              width: auto;
              max-width: 270px;
              border-radius: 0;
            }
          }
        }
      }
    }

    .video-container {
      &.loaded {
        margin-bottom: 23px;
      }
    }

    #references {
      margin: 0;
      padding: 50px 25px;
      display: flex;

      & > .MuiAccordion-root {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0 auto;
        max-width: 100%;
        width:100%;

        .MuiButtonBase-root {
          padding: 0;

          .MuiIconButton-root {
            display: none;
          }
        }

        .MuiAccordionDetails-root {
          padding: 0;
          max-width: 100%;

          div {
            max-width: 100%;
          }
        }

        h5 {
          font-size: 12px;
          line-height: 20px;
          text-transform: uppercase;
          letter-spacing: 0;
          font-weight: bold;
          margin: 0;
          text-align: left;
        }

        .MuiIconButton-root {
          margin-top: 10px;
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 15px;
          list-style-position: outside;
          padding: 0;
          padding-left:2rem;

          li {
            margin: 0;
            display: list-item;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 20px;
            font-weight: normal;
            list-style-type:decimal;

            padding-left: 1rem;
            position: relative;
            text-align: left;

            &::before {
              position: absolute;
              top: 0;
              left: 0;
              display:none;
            }
            
            &::marker{
              color:#003565;
            }

            a {
              color: $orange-1;
              word-break: break-word;
            }
          }
        }
      }
    }

    .attachment-section {
      padding: 50px 0;
      margin: 0 25px;

      .attachment-section-inner-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .attachment-title {
          margin: 0;
          padding: 0;

          h4 {
            margin: 0;
            color: #0063be;
            font-size: 1.2rem;
            line-height: 1.4;
            letter-spacing: 0;
            font-weight: bold;
          }
        }

        .attachment-body {
          & > div {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p,
            span,
            strong,
            li {
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
              color: #003565;
              font-weight: normal;
              margin: 0;
            }

            strong {
              font-weight: bold;
            }

            ul {
              padding: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;
            }
          }
        }

        .attachment-cards-wrapper {
          margin: 10px 0 0;
          gap: 25px;
          flex-wrap: nowrap;
          flex-direction: column;

          .card-wrapper {
            max-width: unset;
            padding: 25px;
            background: #fff;
            border-radius: 10px;

            .card {
              border-radius: unset;

              .link {
                display: flex;
                flex-direction: column;
                gap: 25px;

                img {
                  width: 100%;
                  height: auto;
                  border-radius: unset;
                }

                .content {
                  position: relative;
                  padding: 0;
                  min-height: unset;
                  top: unset;
                  bottom: unset;
                  left: unset;
                  right: unset;

                  .content-title {
                    margin: 0;
                    color: #0063be;
                    font-size: 1.2rem;
                    line-height: 1.4;
                    letter-spacing: 0;
                    font-weight: bold;
                  }
                }

                .read-more {
                  margin-top: auto;
                  padding-top: 25px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  width: 100%;

                  .text {
                    text-align: left;
                    color: #f7a900;
                    font-size: 1.2rem;
                    line-height: 1.5;
                    font-weight: normal;
                    letter-spacing: 0;
                    text-transform: uppercase;
                    margin-right: 5px;
                  }

                  .arrow-container {
                    color: $orange-1;
                    transition: margin-left 0.5s ease;
                    letter-spacing: 0;

                    img {
                      max-height: 11px;
                      max-width: 13px;
                      width: 100%;
                      height: 100%;
                      display: block;
                    }
                  }

                  &:hover {
                    .arrow-container {
                      margin-left: 0.5em;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .condition-select {
      margin: 0;
      background: #fff;
      padding: 0 0 50px;
      display: flex;
      flex-direction: column-reverse;
      gap: unset;
      position: relative;

      .condition-select-inner-container {
        max-width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-inline: 25px;
        margin-top: 25px;

        h1 {
          margin: 0 0 15px;
          line-height: 36px;
          font-size: 30px;
          letter-spacing: 0;
          font-weight: bold;
          text-align: left;
          width: 100%;
        }

        .subtitle {
          position: absolute;
          max-width: 530px;
          width: 100%;
          top: 0;
          bottom: 220px;
          left: $border-padding-on-side;
          z-index: 1;
          box-sizing: border-box;
          display: flex;
          align-items: center;

          color: #00c2df;
          font-size: 40px;
          line-height: 48px;
          font-weight: bold;
          letter-spacing: 0;

          display: none;
        }

        .select {
          margin: 0 auto;
          max-width: 325px;
          width: 100%;
          min-width: 325px;
          height: unset;
          margin-right: auto;

          .MuiInputBase-root {
            height: unset;
          }

          .MuiSelect-root {
            color: #003565;
            font-size: 1.2rem;
            line-height: 1.5;
            letter-spacing: 0;
            font-weight: normal;
            padding: 1.2rem 20px 15px;

            &:focus {
              background: #fff;
            }
          }

          .select-square {
            background: transparent;
            width: 50px;
            height: 49px;
            display: flex;
            z-index: 1;
            align-items: center;
            border-radius: 0 10px 10px 0;
            pointer-events: none;
            justify-content: center;

            .chevron {
              &::before {
                color: #003565;
                width: 6px;
                height: 6px;
                border-width: 1px 1px 0 0;
                top: -5px;
                vertical-align: baseline;
                bottom: unset;
              }
            }
          }

          fieldset {
            border: 1px solid #d3d3d3;
          }
        }

        p {
          &:empty {
            margin: 0;
          }
        }
      }

      .hero-section-image-container {
        max-height: unset;

        img {
          display: block;
          object-fit: cover;
          width: 100%;
          height: auto;
          min-height: 220px;
          max-height: 550px;
        }

        &::after {
          content: "";
          bottom: 0;
          left: 0;
          right: 0;
          height: 25px;
          width: 100%;
          position: absolute;
          background: linear-gradient(
                          180deg,
                          rgba(255, 255, 255, 0) 0%,
                          rgba(255, 255, 255, 0.75) 67%,
                          rgba(255, 255, 255, 1) 100%
          );
        }
      }
    }


    section {
      &.section-0 {
        margin-top: $section-padding-mobile;
        padding:0px 2rem;
      }
    }



    .gallery-items-outer-container {
      margin: 0;
      padding: 50px 25px;

      .gallery-items-inner-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .section-article-container {
          padding: 0;

          .section-article-inner-container {
            width: 100%;

            .section-article-title {
              margin: 0 0 20px;

              .section-article-string-2 {
                font-size: 1.2rem;
                line-height: 1.4;
              }
            }
          }
        }

        .symptom-gallery-container {
          gap: 20px;

          .symptom-gallery-item {
            .no-link-image {
              display: flex;
              flex-direction: column;
              gap: 0;

              img {
                display: block;
                width: 100%;
                height: auto;
                max-width: 120px;
                max-height: 120px;
              }

              .item-title {
                color: #003565;
                line-height: 1.5;
                letter-spacing: 0;
                font-weight: normal;
                text-align: left;
              }
            }
          }
        }
      }
    }

    .single-card-gallery-outer-container {
      a {
        sup,sub {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    .simple-section {
      .simple-section-title {
        a {
          sup,sub {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }

      .description-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width:100%;

        ul {
          padding: 0;
          display: flex;
          flex-direction: column;
          gap: 15px;

          li {
            margin: 0;
            letter-spacing: 0;
            line-height: 1.5;
            font-size: 1.2rem;
            color: #003565;
            font-weight: normal;
          }
        }
      }
    }
  }

  &.dynamic-wellbeing-and-support-page,
  &.dynamic-your-wellbeing-page,
  &.dynamic-uw-welzijn-page,
  &.dynamic-bienestar-y-apoyo-page,
  &.dynamic-su-bienestar-page,
  &.dynamic-valbefinnande-och-support-page{
    .lower-section {
      &.main-page {
        max-width: 100%;
        margin: 0;
        width: 100%;

        .hero-section-text {
          & ~ .simple-section {
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 50px 25px 0;

            h4 {
              margin: 0;
              font-size: 1.2rem;
              line-height: 1.4;
              letter-spacing: 0;
              font-weight: bold;
            }

            .description-container {
              p,
              span {
                margin: 0;
                font-weight: normal;
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
              }
            }
          }
        }

        .carousel-container {
          background: transparent;

          .cards-wrapper {
            .card-wrapper {
              background: #fff;

              .card {
                a {
                  .content {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-autoimmune-diets-page,
  &.dynamic-IBD-diets-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          display: flex;
          flex-direction: column-reverse;
        }

        & > .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            p {
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
              font-weight: normal;
              color: #003565;
            }

            ul {
              padding: 0;

              li {
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
                font-weight: normal;
                color: #003565;
                margin: 0;

                strong {
                  color: #003565;
                  font-weight: bold;
                }
              }
            }
          }

          & + .content-with-image {
            .content-with-image-inner-container {
              .text-container {
                [data-rmiz-wrap="visible"] {
                  //display: inline-flex;
                }
              }
            }
          }
        }

        .full-width-content-with-cta {
          .full-width-content-with-cta-inner {
            .simple-section {
              .simple-section-title {
                text-align: center;
              }
            }
          }
        }

        .content-with-simple-image {
          padding: 50px 25px;
          margin: 0;

          .simple-image-container {
            width: 100%;

            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              max-width: 100%;
              border-radius: 20px;

              img {
                width: 100%;
                height: auto;
                display: block;
              }

              button {
                display: none;
              }
            }
          }

          .content-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                font-size: 1.2rem;
                letter-spacing: 0;
                margin: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                }
              }
            }
          }
        }

        .content-with-pros-and-cons-boxes-outer {
          padding-bottom: 40px;

          & + .content-with-pros-and-cons-boxes-outer {
            background: #fff;
            padding-top: 0;
            padding-bottom: 40px;

            & + .simple-section {
              padding-top: 0;
            }
          }
        }

        .content-with-multiple-images {
          .multi-image-container {
            .simple-section {
              [data-rmiz-wrap="visible"] {
                button {
                  display: none;
                }
              }

              .simple-section-title,
              .description-container {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-the-microbiome-page,
  &.dynamic-the-microbiome-in-IBD-page,
  &.dynamic-het-microbioom-page,
  &.dynamic-ditt-mikrobiom-med-IBD-page,
  &.dynamic-mikrobiom-kod-upalne-bolesti-crijeva-page{
    .lower-section {
      &.content-page {
        .hero-section-text {
          //1st child of lower-section
          display: flex;
          flex-direction: column-reverse;

          & + .content-with-simple-image {
            & + .content-with-simple-image {
              .simple-image-container {
                [data-rmiz-wrap="visible"] {
                  overflow-x: hidden;
                  max-width: 100%;

                  img {
                    max-height: unset;
                    width: 100%;
                    height: auto;
                    margin-inline: 25px;
                    margin-bottom: 10px;
                    max-width: calc(100% - 50px);
                  }
                }
              }
            }
          }
        }

        .content-with-simple-image {
          padding: 50px 25px;
          margin: 0;
          gap: 20px;
          display: flex;
          flex-direction: column;

          &:nth-child(odd) {
            .simple-image-container {
              [data-rmiz-wrap="visible"] {
                background: #f4f8fa;
              }
            }
          }

          &:nth-child(even) {
            .simple-image-container {
              [data-rmiz-wrap="visible"] {
                background: #fff;
              }
            }
          }

          .simple-image-container {
            width: 100%;

            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              overflow-x: scroll;
              max-width: 100%;
              display: flex;
              flex-direction: column-reverse;
              gap: 10px;
              border-radius: 10px;

              button {
                display: none;
              }

              .single-card-image-text {
                .single-card-image-alt {
                  display: none;
                }

                .single-card-image-title {
                  letter-spacing: 0;
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 15px;
                  margin-inline: 25px;
                  margin-top: 10px;
                }
              }

              img {
                display: block;
                max-height: 400px;
              }
            }
          }

          .content-container {
            width: 100%;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                margin: 0;
                font-size: 1.2rem;
                line-height: 1.4;
                letter-spacing: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  font-weight: normal;
                  color: #003565;
                  margin: 0;
                }
              }
            }
          }

          & + .content-with-image {
            & + .content-with-simple-image {
              .simple-image-container {
                [data-rmiz-wrap="visible"] {
                  overflow-x: hidden;
                  background: transparent;

                  img {
                    width: 100%;
                    height: auto;
                  }
                }
              }
            }
          }
        }

        .single-card-outer-container {
          .single-card-container {
            .single-card-inner-container {
              gap: 20px;

              [data-rmiz-wrap="visible"] {
                gap: 10px;
                flex-direction: column-reverse;
                overflow-x: scroll;
                max-width: 100%;

                img {
                  display: block;
                  margin: 0;
                  border-radius: 10px;
                  max-height: 400px;
                  height: unset;
                  width: unset;
                }

                button {
                  display: none;
                }

                .single-card-image-text {
                  .single-card-image-alt {
                    display: none;
                  }

                  .single-card-image-title {
                    font-size: 18px;
                    line-height: 15px;
                    letter-spacing: 0;
                    font-weight: bold;
                  }
                }
              }

              .single-card-text-container {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .single-card-title {
                  font-size: 1.2rem;
                  letter-spacing: 0;
                  line-height: 1.4;
                  color: #0063be;
                  text-transform: unset;
                }

                .single-card-description {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                    letter-spacing: 0;
                    margin: 0;
                  }
                }
              }
            }
          }
        }

        .simple-section-with-multiple-body {
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 40px;
          margin: 0;

          .simple-section {
            display: flex;
            flex-direction: column;
            gap: 20px;

            [data-rmiz-wrap="visible"] {
              display: none;
              order: 1;
              //align-items: unset;
              max-width: 100%;
              overflow-x: scroll;
              flex-direction: column-reverse;
              gap: 10px;

              img {
                max-height: 400px;
                display: block;
              }

              button {
                display: none;
              }

              .single-card-image-text {
                .single-card-image-alt {
                  display: none;
                }

                .single-card-image-title {
                  font-size: 12px;
                  line-height: 15px;
                  letter-spacing: 0;
                  font-weight: bold;
                }
              }
            }

            .simple-section-title {
              order: 2;
              margin: 0;
              font-size: 1.2rem;
              line-height: 1.4;
              letter-spacing: 0;
            }

            .description-container {
              order: 3;
              display: flex;
              flex-direction: column;
              gap: 10px;

              p,
              span {
                margin: 0;
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
              }

              ul {
                padding: 0;

                li {
                  margin: 0;
                  font-size: 20px;
                  line-height: 24px;
                  letter-spacing: 0;
                  font-weight: bold;
                }
              }
            }

            &:nth-child(1) {
              [data-rmiz-wrap="visible"] {
                display: flex;
              }
            }
          }
        }

        #references {
          //background: transparent;
        }
      }
    }
  }

  &.dynamic-the-microbiome-in-IBD-page {
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          margin: 0;
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              img {
                display: block;
                height: auto;
                width: 100%;
              }

              button {
                display: none;
              }
            }
          }

          .content-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                font-size: 1.2rem;
                letter-spacing: 0;
                line-height: 1.4;
                margin: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  font-size: 1.2rem;
                  letter-spacing: 0;
                  line-height: 1.5;
                  color: #003565;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  /*&.dynamic-idacio-page {
    .lower-section {
      &.main-page {
        .carousel-container {
          .cards-wrapper {
            .card-wrapper {
              background: #f4f8fa;
            }
          }
        }

        h5 {
          color: rgba(24, 44, 76, 1);
        }

        h5,
        h4 {
          text-align: left;
        }
      }
    }
  }*/

  &.dynamic-my-idacio-page,
  &.dynamic-my-tyenne-page,
  &.dynamic-my-otulfi-page,  
  &.dynamic-mein-medikament-page,
  &.dynamic-mijn-idacio-page,
  &.dynamic-mi-idacio-page,
  &.dynamic-mi-tyenne-page,
  &.dynamic-moj-tyenne-page,
  &.dynamic-moj-idacio-page,
  &.dynamic-min-tyenne-page,
  &.dynamic-min-idacio-page{
    .lower-section {
      &.main-page {
        .hero-section-text {
          & + .simple-section {
            margin: 0;
            padding: 50px 25px 25px;

            h4 {
              margin: 0;
              display: none;
            }

            .description-container {
              p {
                line-height: 30px;
                font-size: 18px;
              }
            }
          }
        }

        .carousel-container {
          background: #fff;
          margin: 0;

          .cards-wrapper {
            .card-wrapper {
              background: #f4f8fa;

              .card {
                a {
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  .content {
                    background: transparent;
                    margin: 0;
                    border-radius: unset;
                  }

                  .read-more {
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-hcp-access-page,
  &.dynamic-hcp-zugang-page,
  &.dynamic-acceso-para-profesionales-de-la-salud-page,
  &.dynamic-sjukvardspersonal-page{
    .lower-section {
      &.main-page {
        .carousel-container {
          margin: 0;

          .cards-wrapper {
            .card-wrapper {
              .card {
                a {
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  .content {
                    margin: 0;
                    border-radius: unset;

                    .content-description {
                      display: flex;
                      flex-direction: column;
                      gap: 25px;
                      margin: 0;

                      p {
                        margin: 0;
                      }
                    }
                  }

                  .read-more {
                    padding: 0;
                  }
                }
              }
            }
          }
        }

        .section-article-container {
          margin: 0;
          padding: 50px 25px 25px;

          .section-article-inner-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .section-article-title {
              margin: 0;

              .section-article-string-1 {
                font-size: 1.2rem;
                line-height: 1.4;
                letter-spacing: 0;
                color: #0063be;
                font-weight: bold;
                text-align: left;
                text-transform: unset;
              }
            }

            .section-article-description {
              display: flex;
              flex-direction: column;
              gap: 25px;

              p {
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
                color: #003565;
                font-weight: normal;
              }

              ul {
                padding: 0;

                li {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  color: #003565;
                  font-weight: normal;
                }
              }

              p,
              li {
                text-align: left;
              }
            }
          }
        }

        & > .simple-section {
          padding: 50px 25px;
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 15px;

          .simple-section-title {
            margin: 0;
          }

          .description-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width:100%;

            p,
            span,
            li {
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
              margin: 0;
            }

            ul {
              padding: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;
            }
          }
        }
      }
    }
  }

  &.dynamic-kabicare-page {
    .lower-section {
      &.main-page {
        .hero-section-text {
          & ~ .simple-section {
            margin: 0;
            padding: 50px 25px 25px;
            display: flex;
            flex-direction: column;
            gap: 15px;

            h4 {
              margin: 0;

              .simple-section-subtitle {
                font-size: 1.2rem;
                line-height: 1.4;
                letter-spacing: 0;
                font-weight: normal;
              }
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 25px;

              p {
                line-height: 1.5;
                font-size: 1.2rem;
                margin: 0;
                letter-spacing: 0;
                font-weight: normal;
              }

              ul {
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 15px;

                li {
                  line-height: 1.5;
                  font-size: 1.2rem;
                  margin: 0;
                  letter-spacing: 0;
                  font-weight: normal;
                }
              }
            }
          }
        }

        .carousel-container {
          background: transparent;
          margin: 0;

          & ~ .simple-section {
            padding-bottom: 50px;
            background: #fff;
          }

          .cards-wrapper {
            .card-wrapper {
              background: #fff;

              .card {
                a {
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  picture {
                    img {
                    }
                  }

                  .content {
                    background: transparent;
                    margin: 0;
                    border-radius: unset;
                  }

                  .read-more {
                    padding: 0;
                  }
                }
              }
            }
          }
        }

        .content-with-image {
          padding: 50px 25px;
          background: #fff;

          .content-with-image-inner-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .text-column {
              .text-container {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .simple-section-title {
                  color: #0063be;
                  font-weight: bold;
                  font-size: 1.2rem;
                  line-height: 1.4;
                  letter-spacing: 0;
                  text-align: left;
                  margin: 0;
                }

                .description-container {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  p,
                  span {
                    font-size: 1.2rem;
                    line-height: 1.5;
                    letter-spacing: 0;
                    color: #003565;
                    font-weight: normal;
                    margin: 0;
                  }
                }

                [data-rmiz-wrap="visible"] {
                  display: none;
                }
              }
            }

            .image-container {
              .simple-section-title,
              .simple-section-subtitle {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-travel-page,
  &.dynamic-travelling-with-autoimmune-condition-page,
  &.dynamic-travel-with-RA-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          & + .simple-section {
            & + .content-with-image {
              & + .simple-section {
                background: #fff;
                padding-top: 0;

                .description-container {
                  max-width: 1400px;
                }
              }
            }
          }
        }

        .content-with-image {
          &.tablet-image {
            background: transparent;
          }

          &.notebook-image {
            background: #fff;
            padding-bottom: 30px;

            .content-with-image-inner-container {
              .text-column {
                gap: 30px;
              }
            }

            & + .simple-section {
              padding-top: 0;
              gap: 15px;

              .simple-section-title {
                display: block;
                font-size: 1.2rem;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 1.4;
                text-align: left;
                margin: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  font-weight: normal;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                }
              }
            }
          }
        }

        .full-width-content-with-cta {
          .full-width-content-with-cta-inner {
            .simple-section {
              .simple-section-title {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-crohn\'s-disease-page,
  &.dynamic-enfermedad-de-crohn-page,
  &.dynamic-morbus-crohn-page,
  &.dynamic-ziekte-van-chron-page,  
  &.dynamic-ulcerative-colitis-page {
    .lower-section {
      &.content-page {
        .single-card-gallery-outer-container {
          .single-card-gallery-container {
            gap: 20px;

            & > section {
              .cards-wrapper {
                .card-wrapper {
                  padding-inline: 25px;

                  div {
                    &:not(.card) {
                      h4 {
                        margin-bottom: 10px;
                      }
                    }
                  }

                  &:last-of-type {
                    div {
                      &:not(.card) {
                        h4 {
                          margin-bottom: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-covid-19-page {
    .lower-section {
      .content-with-image {

        #covid-19-bulletpoints {
          display: block;

          p {
            padding: 5px 0;
            margin-left: 1rem;
          }

          ul {
            display: block;
            margin-left: 0;
            
            ul{
              padding-top:0px;
            }
          }

          ::before {
            position: absolute;
            top: 6px;
          }

          li {
            position: relative;
            display: block;
          }
        }

        &.tablet-image {

          &.image-on-left {
            &.split-title {
              .content-with-image-inner-container {
                .styled-section-image-inner {
                  .image-wrapper {
                    .item-image {
                      width: 800px;
                    }
                  }
                }
              }
            }
          }

          &.image-on-right {
            &.split-title {
              .content-with-image-inner-container {
                .styled-section-image-inner {
                  .image-wrapper {
                    .item-image {
                      width: 300px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-giant_cell_arteritis-page,
  &.dynamic-covid-19-page {
    .lower-section {

      .text-left-symptom-carousel-right {
        .left-side-container {
          .simple-section {
            .cta-small-container {
              a {
                padding: 11px 30px 10px;
                width: fit-content;
                background-color: $orange-1;

                border-radius: 20px;

                div {
                  display: none;
                }

                p {
                  font-size: 1.2rem;
                  color: white;
                  text-transform: uppercase;
                }
              }
            }
          }
        }

        section {
          display: none;
        }
      }
      

      .content-with-image {
        &.tablet-image {
          &.image-on-left {
            &.split-title {
              .content-with-image-inner-container {
                .styled-section-image-inner {
                  .image-wrapper {
                    .item-image {
                      width: 750px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-covid-19-page {
    .lower-section {
      div:nth-child(3), div:nth-child(4), div:nth-child(6) {
        @include hide-tablet-image-frame();
      }
    }
  }

  &.dynamic-giant_cell_arteritis-page {
    .lower-section {
      div:nth-child(2), div:nth-child(4), div:nth-child(7), {
        @include hide-tablet-image-frame();
      }
    }
  }

  &.dynamic-nutrition-page,
  &.dyanmic-nutrición-page,
  &.dyanmic-ernährung-page,
  &.dyanmic-voeding-page{
    .lower-section {
      &.main-page {
        .hero-section-text {
          & + .carousel-container {
            padding-top: 6rem;
          }
        }
      }
    }
  }

  &.dynamic-mindfulness-and-stress-page,
  &.dynamic-usredotočena-svjesnost-i-stres-page{
    .lower-section {
      &.content-page {
        .text-left-symptom-carousel-right {
          .text-left-symptom-carousel-right-inner {
            gap: 30px;

            section {
              .symptom-gallery-container {
                .symptom-gallery-item {
                  a {
                    .item-title {
                      color: #003565;
                      font-size: 14px;
                      line-height: 1.5;
                    }
                  }

                  .no-link-image {
                    .item-title {
                      color: #003565;
                      font-size: 14px;
                      line-height: 1.5;
                    }
                  }
                }
              }
            }
          }
        }

        .text-top-symptom-carousel-bottom {
          .text-top-symptom-carousel-bottom-inner {
            & > section {
              .symptom-gallery {
                .symptom-gallery-item {
                  &:nth-child(3n + 1) {
                    grid-column-start: 1;
                    grid-column-end: 2;
                  }

                  &:nth-child(3n + 2) {
                    grid-column-start: 2;
                    grid-column-end: 3;
                  }

                  &:nth-child(3n + 3) {
                    grid-column-start: 3;
                    grid-column-end: 4;
                  }

                  .no-link-image,
                  a {
                    gap: 20px;
                    flex-direction: column;
                    align-items: flex-start;
                    flex-wrap: nowrap;

                    img {
                      width: 95px;
                      height: 95px;
                    }

                    .item-title {
                      margin: 0;
                      max-width: unset;
                      font-size: 1.2rem;
                      line-height: 1.4;
                      letter-spacing: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-relationships-page,
  &.dynamic-intimacy-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          & + .simple-section {
            .simple-section-title {
              font-size: 1.2rem;
              letter-spacing: 0;
            }

            .description-container {
              p {
                line-height: 1.5;
              }
            }
          }
        }

        .full-width-content-with-cta {
          .full-width-content-with-cta-inner {
            .simple-section {
              .simple-section-title {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-intimacy-page {
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          margin: 0;
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 50px;

          .simple-image-container {
            border-radius: 10px;
            overflow: hidden;
            display: flex;

            h4 {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              max-width: 100%;

              img {
                max-width: 100%;
                display: block;
                width: 100%;
                height: auto;
              }
            }
          }

          .content-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                font-size: 1.2rem;
                letter-spacing: 0;
                margin: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 30px;

                p {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  font-weight: normal;
                  color: #003565;
                }

                ul {
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    margin: 0;
                  }

                  ul {
                    display: block;
                  }
                }

                p,
                ul {
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }

          & + .content-with-image {
            .content-with-image-inner-container {
              gap: 0;

              .image-container {
                visibility: hidden;
              }

              .text-column {
                .text-container {
                  .description-container {
                    p {
                      font-size: 1.2rem;
                      line-height: 1.5;
                      letter-spacing: 0;
                      font-weight: normal;
                      color: #003565;
                    }

                    ul {
                      display: flex;
                      flex-direction: column;
                      gap: 15px;

                      li {
                        margin: 0;
                      }
                    }

                    p,
                    ul {
                      margin: 0;
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-facing-challenges-page {
    .lower-section {
      &.content-page {
        .simple-section {
          gap: 15px;

          .simple-section-title {
            font-size: 1.2rem;
            line-height: 1.4;
            letter-spacing: 0;
          }

          .description-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p {
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
              color: #003565;
            }
          }
        }

        .full-width-content-with-cta {
          .full-width-content-with-cta-inner {
            & > .simple-section {
              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-support-for-parents-and-carers-page {
    .lower-section {
      &.content-page {
        .simple-section {
          gap: 15px;

          .simple-section-title {
            font-size: 1.2rem;
            line-height: 1.4;
            letter-spacing: 0;
          }

          .description-container {
            gap: 20px;
            display: flex;
            flex-direction: column;

            p {
              font-size: 1.2rem;
              line-height: 1.5;
            }
          }
        }

        .text-left-symptom-carousel-right {
          .text-left-symptom-carousel-right-inner {
            gap: 25px;

            .left-side-container {
              .simple-section {
                .description-container {
                  ul {
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                    gap: 15px;

                    li {
                      margin: 0;
                      line-height: 20px;

                      a {
                        color: $orange-1;
                        font-size: 1.2rem;
                        line-height: 20px;
                        font-weight: normal;
                        letter-spacing: 0;
                      }
                    }
                  }
                }
              }
            }

            section {
              .symptom-gallery-container {
                gap: 20px;

                .symptom-gallery-item {
                  .no-link-image {
                    align-items: flex-start;

                    .item-title {
                      font-size: 14px;
                      line-height: 1.5;
                      color: #003565;
                      font-weight: normal;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-support-from-the-community-page {
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          padding: 50px 25px;

          .simple-image-container {
            display: none;
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 25px;

              .simple-section-title {
                margin: 0;
                font-size: 1.2rem;
                line-height: 1.4;
                letter-spacing: 0;
              }

              .description-container {
                p,
                span,
                a {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  color: #003565;
                  word-break: break-word;
                }

                a {
                  color: $orange-1;

                  span {
                    color: $orange-1;
                  }
                }

                ul {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-supporting-others-page {
    .lower-section {
      &.content-page {
        .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .video-container {
              max-width: 100%;
            }

            span,
            a {
              font-size: 1.2rem;
              line-height: 1.5;
            }

            a {
              color: $orange-1;
            }

            ul {
              padding: 0;
              display: flex;
              gap: 15px;
              flex-direction: row;
              flex-wrap: wrap;

              li {
                margin: 0;

                flex-direction: column;

                & > span {
                  margin-top: -20px;
                  margin-left: 35px;
                }

                ul {
                  padding-left: 35px;
                  margin-top: 15px;

                  li {
                    span {
                      word-break: break-word;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-talking-to-your-health-team-page,
  &.dynamic-hablar-con-su-equipo-asistencial-page,
  &.dynamic-overleg-met-uw-gezondheidsteam-page,
  &.dynamic-gespräch-mit-ihrem-gesundheitsteam-page,
  &.dynamic-razgovor-s-vašim-medicinskim-timom-page{
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          padding: 50px 25px;
          margin: 0;
          gap: 20px;
          display: flex;
          flex-direction:column;
          
          &.image-on-right {
            flex-direction: column-reverse;
          }

          .simple-image-container {
            
            .simple-image{
              width:auto;
              margin-left:0px;
            }
            
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              max-height: 83px;
              max-width: 83px;
              min-width: 83px;
              width: 100%;

              img {
                max-height: 83px;
                max-width: 83px;
                width: 100%;
                height: auto;
                display: block;
              }
            }
          }

          .content-container {
            .simple-section {
              .description-container {
                gap: 20px;
                display: flex;
                flex-direction: column;

                p {
                  margin: 0;
                  font-size: 14px;
                  line-height: 1.5;
                  letter-spacing: 0;
                  color: #003565;
                }

                ul {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    margin: 0;

                    * {
                      margin: 0;
                      font-size: 14px;
                      line-height: 1.5;
                      letter-spacing: 0;
                      color: #003565;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-goal-setting-page {
    .lower-section {
      &.content-page {
        .simple-section {
          .description-container {
            gap: 25px;
            display: flex;
            flex-direction: column;

            ul {
              display: flex;
              flex-direction: column;
              gap: 15px;
              padding: 0;

              li {
                margin: 0;

                * {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  font-weight: normal;
                  color: #003565;

                  a {
                    color: $orange-1;
                  }
                }
              }
            }
          }
        }

        .full-width-content-with-cta {
          .full-width-content-with-cta-inner {
            .simple-section {
              .simple-section-title {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-coaching-tool-page,
  &.dynamic-coaching-instrumente-page,
  &.dynamic-coaching-tools-page,
  &.dynamic-herramientas-de-coaching-page,
  &.dynamic-coaching-verktyg-page{
    .lower-section {
      background: #f4f8fa;

      & > * {
        &:nth-child(odd) {
          background: #fff;
        }

        &:nth-child(even) {
          background: transparent;
        }
      }

      .coaching-tool-hero-section {
        padding: 25px 25px 50px;
        margin: 0;

        .simple-section-title {
          color: #00c2df;
          font-size: 30px;
          line-height: 36px;
          letter-spacing: 0;
          font-weight: bold;
          margin: 0;
        }
      }

      .section-article-container {
        margin: 0;
        padding: 50px 25px;

        .title {
          color: #00c2df;
          font-size: 18px;
          line-height: 30px;
          text-align: left;
        }

        .subtitle {
          font-size: 24px;
          line-height: 29px;
          color: #0063be;
          font-weight: bold;
          text-align: left;
        }

        .section-article-inner-container {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .section-article-title {
            margin: 0;
            gap: 6px;

            .section-article-string-1 {
              font-size: 12px;
              line-height: 15px;
              letter-spacing: 0;
              color: #003565;
              text-transform: uppercase;
            }

            .section-article-string-2 {
              color: #0063be;
              font-size: 2rem;
              letter-spacing: 0;
              line-height: 1.3;
            }
          }

          .section-article-description {
            p {
              color: #003565;
              font-weight: normal;
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
            }
          }
        }
      }

      .fullwidth-transparent {
        padding: 50px 25px 30px;

        .single-card-gallery-container {
          padding: 0;
          border-radius: unset;
          background: transparent;
          display: flex;
          flex-direction: column;
          gap: 30px;

          .single-card-text-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .single-card-title {
              margin: 0;
              color: #0063be;
              font-size: 1.2rem;
              line-height: 1.4;
              letter-spacing: 0;
              text-transform: unset;
            }

            .single-card-description {
              font-size: 1.2rem;
              line-height: 1.5;
              color: #003565;
              letter-spacing: 0;
              margin: 0;
            }
          }

          & > section {
            .cards-wrapper {
              margin: 0;

              .card-wrapper {
                display: flex;
                flex-direction: row;
                border-radius: 10px;
                background: #f4f8fa;
                width:100%;
                max-width:100%;

                .card {
                  justify-content: unset;
                  border-radius: unset;
                  width:20%;
                  flex-shrink:0;

                  .link {
                    height:100%;
                    picture {
                      height:100%;
                      border-radius: 10px 0 0 10px;
                      overflow: hidden;

                      img {
                        max-width: 100%;
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                      }
                    }

                    .read-more {
                      display: none;
                    }
                  }
                }

                h4 {
                  margin: 0;
                  padding: 2rem 15px 2rem;
                  font-size: 1rem;
                  letter-spacing: 0;
                  line-height: 1.4;
                  font-weight: normal;
                  color: #003565;
                  font-style: italic;
                  position: relative;
                  text-align: left;

                  &::before {
                    content: "\201C";
                    font-family: "Arial Black";
                    font-size: 40px;
                    position: absolute;
                    line-height: 56px;
                    top: 0;
                    left: 15px;
                    display:none;
                  }

                  &::after {
                    content: "";
                    top: 0;
                    left: -20px;
                    bottom: 0;
                    position: absolute;
                    width: 20px;
                    background: linear-gradient(90deg, transparent, #f4f8fa);
                  }
                }
              }
            }
          }
        }

        & + .simple-section {
          background: #fff;
          padding: 0 25px 50px;
          margin: 0;

          .description-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p {
              margin: 0;
              letter-spacing: 0;
              font-size: 1.2rem;
              line-height: 1.5;
            }
          }

          & + .section-article-container {
            padding-bottom: 30px;

            & + .section-article-container {
              padding: 0 25px;

              & > section {
                .title {
                  margin: 0;
                  letter-spacing: 0;
                }

                .subtitle {
                  margin: 20px 0 0;
                  font-size: 1.2rem;
                  letter-spacing: 0;
                  line-height: 1.4;
                }

                .body-18 {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  p {
                    font-size: 1.2rem;
                    letter-spacing: 0;
                    line-height: 1.5;
                  }
                }
              }

              .column-container {
                padding: 30px 0 50px;

                &::after {
                  left: 0;
                  right: 0;
                }
              }
            }
          }
        }
      }

      .column-container {
        margin: 0;
        padding: 30px 25px 50px;
        background: #f4f8fa;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;

        & > div {
          & > .MuiBox-root {
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .text-container {
              margin: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;
              height: unset;

              .title {
                margin: 0;
                font-size: 1.2rem;
                letter-spacing: 0;
                line-height: 30px;
                color: #00c2df;
                text-align: left;
              }

              .subtitle {
                color: #0063be;
                margin: 0;
                font-size: 1.2rem;
                letter-spacing: 0;
                line-height: 1.4;
                text-align: left;
                font-weight: bold;
              }

              .body-18 {
                display: flex;
                flex-direction: column;
                gap: 15px;

                p {
                  margin: 0;
                  font-size: 1.2rem;
                  letter-spacing: 0;
                  line-height: 1.5;
                }
              }
            }

            img {
              display: block;
            }
          }
        }
      }

      #references {
        background: transparent;
      }
    }
  }

  &.dynamic-understanding-treatment-page,
  &.dynamic-understanding-otulfi-page,
  &.dynamic-understanding-tyenne-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          & + .simple-section {
            gap: unset;

            .simple-section-title {
              order: 2;
              margin-top: 6px;
            }

            .simple-section-subtitle {
              order: 1;
              font-size: 12px;
              line-height: 15px;
              color: #003565;
              text-align: left;
              text-transform: uppercase;
              font-weight: bold;
              width: 100%;
            }

            .description-container {
              order: 3;
              margin-top: 15px;
              display: flex;
              flex-direction: column;
              gap: 20px;
            }

            .cta-small-container {
              order: 4;
              margin-top: 25px;
              padding: 0;

              a {
                .cta-small {
                  margin-right: 10px;
                  text-transform: uppercase;
                }
              }
            }
          }
        }

        .content-with-simple-image {
          padding: 50px 25px;
          margin: 0;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 10px;

              .single-card-image-text {
                .single-card-image-title {
                  letter-spacing: 0;
                  font-size: 14px;
                  line-height: 18px;
                  font-weight: bold;

                  a {
                    sup,sub {
                      font-size: 10px;
                      line-height: 14px;
                      color: #003565;
                    }
                  }
                }
              }

              img {
                display: block;
                width: 100%;
                height: auto;
              }

              button {
                display: none;
              }
            }
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                margin: 0;
                line-height: 1.4;
                font-size: 1.2rem;
                letter-spacing: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  color: #003565;
                  line-height: 1.5;
                  font-size: 1.2rem;
                  letter-spacing: 0;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-eating-well-page,
  &.dynamic-eating-well-with-an-autoimmune-page,
  &.dynamic-eating-well-with-RA-page,
  &.dynamic-comer-bien-con-ar-page,
  &.dynamic-zdrava-prehrana-kod-reumatoidnog-artritisa-page{
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 30px;
          margin: 0;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              display: flex;
              flex-direction: column-reverse;
              gap: 10px;

              button {
                display: none;
              }

              img {
                display: block;
                max-width: 100%;
              }
            }

            .single-card-image-text {
              .single-card-image-title {
                letter-spacing: 0;
                font-size: 14px;
                line-height: 18px;
                font-weight: bold;
              }

              .single-card-image-alt {
                display: none;
              }
            }
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                margin: 0;
                font-size: 1.2rem;
                letter-spacing: 0;
                line-height: 1.4;
                font-weight: bold;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  color: #003565;
                  letter-spacing: 0;
                  line-height: 1.5;
                  font-size: 1.2rem;
                  margin: 0;
                }
              }
            }
          }
        }

        .text-left-symptom-carousel-right {
          .text-left-symptom-carousel-right-inner {
            & > section {
              .symptom-gallery-container {
                .symptom-gallery-item {
                  .no-link-image {
                    img {
                    }

                    .item-title {
                      font-size: 14px;
                      line-height: 18px;

                      a {
                        display: inline-flex;
                        position: relative;
                        width: 12px;
                        height: 14px;

                        sup {
                          position: absolute;
                          margin-top: -3px;
                          top: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .section-article-container {
          .section-article-inner-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .section-article-title {
              margin: 0;

              .section-article-string-1,
              .section-article-string-2 {
                letter-spacing: 0;
              }
            }

            .section-article-description {
              p,
              li,
              strong,
              span,
              a {
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
                margin: 0;
              }

              ul {
                padding: 0;
              }
            }
          }
        }

        .full-width-content-with-cta {
          & + .section-article-container {
            .section-article-inner-container {
              .section-article-description {
                div {
                  ul {
                    li {
                      font-weight: bold;

                      span {
                        font-size: 18px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-eating-well-with-ibd-page,
  &.dynamic-eating-well-with-IBD-page {
    .lower-section {
      &.content-page {
        .content-with-image {
          .content-with-image-inner-container {
            .text-column {
              .text-container {
                .cta-small-container {
                  display: none;
                  cursor: pointer;
                }

                &:nth-child(1) {
                  .cta-small-container {
                    display: flex;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-eating-well-with-IBD-page {
    .lower-section {
      div:nth-child(3) {
        @include hide-tablet-image-frame();
      }

      .section-article-description {
        strong {
          color: $blue-1;
          font-size: 30px!important;
        }
      }
    }
  }

  &.dynamic-staying-active-page,
  &.dynamic-staying-active-with-autoimmune-condition-page {
    .lower-section {
      &.content-page {
        .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 25px;
          }

          [data-rmiz-wrap="visible"] {
            overflow-x: scroll;

            button {
              display: none;
            }
          }
        }

        .text-left-symptom-carousel-right {
          padding-bottom: 30px;

          .text-left-symptom-carousel-right-inner {
            gap: 25px;

            .left-side-container {
              .simple-section {
                .description-container {
                  ul {
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    li {
                      font-size: 1.2rem;
                      line-height: 1.5;
                      letter-spacing: 0;
                      color: #003565;
                      margin: 0;
                    }
                  }
                }
              }
            }

            section {
              .symptom-gallery-container {
                padding: 0;
                background: transparent !important;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;
                justify-content: flex-start;

                .symptom-gallery-item {
                  max-width: calc(50% - 5px);

                  .no-link-image {
                    .item-title {
                      font-size: 14px;
                      line-height: 1.5;
                    }

                    .item-alt {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          & ~ .text-left-symptom-carousel-right {
            background: transparent;
            padding-top: 0;
            padding-bottom: 50px;

            .text-left-symptom-carousel-right-inner {
              gap: 25px;
            }
          }
        }

        .content-with-image {
          &.notebook-image {
            background: #fff;

            & + .simple-section {
              background: transparent;
            }
          }
        }

        #references {
          background: #fff;
        }
      }
    }
  }

  &.dynamic-welcome-to-kabicare-page,
  &.dynamic-Welcome-to-kabicare-page {
    .lower-section {
      &.content-page {
        .content-with-image {
          &.mobile-image {
            .content-with-image-inner-container {
              .text-column {
                .text-container {
                  .description-container {
                    ul {
                      li {
                        flex-wrap: wrap;

                        span {
                          width: calc(100% - 35px);
                        }

                        ul {
                          padding-left: 35px;
                          margin-top: 10px;

                          li {
                            &::before {
                              background: url("../../../resources/images/common/list_item_plus.png");
                              background-repeat: no-repeat;
                              width: 13px;
                              height: 13px;
                              min-width: 13px;
                              min-height: 13px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.tablet-image {
            & + .simple-section {
              background: #fff;
              padding-top: 50px;

              .simple-section-title {
                display: none;
              }

              .simple-section-subtitle {
                font-size: 18px;
                line-height: 30px;
                width: 100%;
              }
            }

            & + .notebook-image {
              background: #f4f8fa;
            }
          }
        }
      }
    }
  }

  &.dynamic-injecting-idacio-page{
    .lower-section {
      &.content-page {
        .simple-section {
          .description-container {
            p {
              font-size: 18px;
              line-height: 30px;
            }
          }

          [data-rmiz-wrap="visible"] {
            overflow-x: scroll;

            button {
              display: none;
            }
          }
        }

        .content-with-simple-image {
          margin: 0;
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 30px;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            img {
              display: block;
            }
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                margin: 0;
                font-size: 1.2rem;
                line-height: 1.4;
                letter-spacing: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 15px;

                p {
                  margin: 0;
                  color: #003565;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-self-admin-guide-page {
    .lower-section {
      &.content-page {
        & > .hero-section-text {
          & + .simple-section {
            .description-container {
              gap: 20px;
            }
          }
        }

        .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .video-container {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  &.dynamic-help-and-supports-page,
  &.dynamic-help-and-support-page,
  &.dynamic-helps-and-supports-page{
    .lower-section {
      &.content-page {
        .hero-section-text {
          & ~ .simple-section {
            .simple-section-title {
              font-size: 1.2rem;
              line-height: 1.4;
              letter-spacing: 0;
            }

            .description-container {
              display: flex;
              gap: 25px;
              flex-direction: column;

              ul {
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 20px;

                li {
                  margin: 0;
                  flex-wrap: wrap;

                  & > * {
                    margin-top: 15px;

                    &:first-child {
                      margin: 0;
                    }
                  }

                  & > p {
                    width: 100%;
                    max-width: 100%;
                    padding-left: 35px;
                  }

                  & > strong,
                  & > span {
                    max-width: calc(100% - 35px);
                    width: 100%;
                  }

                  p,
                  span,
                  strong {
                    font-size: 1.2rem;
                    line-height: 1.5;
                    letter-spacing: 0;
                    color: #003565;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-vitamins-and-minerals-page,
  &.dynamic-vitaminas-y-minerales-page
  {
    .content-with-simple-image {

      &.image-on-right {

        .content-container {
          margin: 0 25px;
        }

        .simple-image-container {
          display: flex;
          flex-direction: column;
          justify-content: center;

          div {
            margin: 0 !important;
            display: flex;
            justify-content: center;
          }
        }

      }

      &.image-on-left {
        width: 100vw;
        display: flex;
        justify-content: center;

        .content-container {
          width: fit-content !important;
        }


        .simple-section {
          &.simple-image-container {

            div {
              display: flex;
              flex-direction: column-reverse;
              align-items: center;

              .single-card-image-title {
                display: block;
                font-size: 20px;
                font-weight: 600;
                color: $blue-1;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-eating-well-with-RA-page,
  &.dynamic-comer-bien-con-ar-page,
  &.dynamic-zdrava-prehrana-kod-reumatoidnog-artritisa-page{
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          &.image-on-left {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .content-container {
              max-width: 100%;
            }

            .simple-image-container {
              max-width: 175px;
              min-width: 175px;

              img {
                height: 125px;
                width: 125px;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-IBD-diets-page {
    .lower-section {
      &.content-page {

        .text-top-symptom-carousel-bottom {
          width: 100vw;
          padding: 50px;

          .text-top-symptom-carousel-bottom-inner {
            width: 100%;

            section {
              text-align: -webkit-center;

              .symptom-gallery-container {
                display: flex;
                flex-direction: column;
                width: 50%;

                .symptom-gallery-item {
                  width: fit-content;
                  min-height: 300px;

                  .no-link-image {
                    display: flex;
                    flex-direction: column;

                    img {
                      transform: scale(2);
                      margin: 30px 0;
                    }

                    .item-title {
                      text-align: center;
                      width: fit-content;
                      min-width: 300px;
                      height: auto;
                      font-size: 1.2rem;
                      font-weight: 400;
                      color: black;

                      margin-top: 15px;
                      padding: 10px 5px;

                      background: $grey-1;

                      strong::after {
                        content: "\a";
                        white-space: pre;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        & > .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .video-container {
              margin-top: 10px;
              margin-bottom: 10px;
            }

            p {
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
              margin: 0;
            }

            ul {
              padding: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;

              li {
                margin: 0;

                span,
                strong {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  margin: 0;
                }
              }
            }
          }
        }

        .content-with-multiple-images {
          .simple-section {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .simple-section-title {
              font-size: 1.2rem;
              letter-spacing: 0;
              margin: 0;
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 20px;

              p {
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
                margin: 0;
              }

              ul {
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 15px;

                li {
                  margin: 0;

                  span,
                  strong {
                    font-size: 1.2rem;
                    line-height: 1.5;
                    letter-spacing: 0;
                    margin: 0;
                  }
                }
              }
            }
          }

          .multi-image-container {
            .simple-section {
              .simple-section-title,
              .description-container {
                display: none;
              }

              [data-rmiz-wrap="visible"] {
                button {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-idacio-page,
  &.dynamic-tyenne-page,
  &.dynamic-otulfi-page{
    .lower-section {
      &.main-page {
        .hero-section-text {
          & + .simple-section {
            padding: 50px 25px;
            margin: 0;
            display: flex;
            flex-direction: column;

            .simple-section-title {
              margin: 0;
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 20px;

              p {
                margin: 0;
              }
            }
          }
        }

        .content-with-simple-image {
          background: #fff;
          margin: 0;
          padding: 50px 25px;
          display: flex;
          flex-direction: column-reverse;
          gap: 30px;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              img {
                display: block;
                width: 100%;
                height: auto;
              }
            }
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                margin: 0;
                font-size: 1.2rem;
                line-height: 1.4;
                letter-spacing: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                }
              }
            }
          }
        }

        #references {
          background: #fff;
        }

        .text-left-symptom-carousel-right {
          margin: 0 auto;
          padding: 50px 25px;
          max-width: 100%;
          background: #fff;

          .text-left-symptom-carousel-right-inner {
            max-width: 100%;
            display: flex;
            width: 100%;
            padding: 0;
            margin: 0 auto;
            gap: 50px;
            flex-direction: column;
            align-items: center;

            & > section {
              .symptom-gallery-container {
                .symptom-gallery-item {
                  .no-link-image {
                    img {
                    }

                    .item-title {
                      font-size: 14px;
                      line-height: 18px;

                      a {
                        display: inline-flex;
                        position: relative;
                        width: 12px;
                        height: 14px;

                        sup {
                          position: absolute;
                          margin-top: -3px;
                          top: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .text-left-symptom-carousel-right {
          .text-left-symptom-carousel-right-inner {
            .left-side-container {
              display: flex;
              flex-direction: column;
              gap: 50px;
              width: 100%;

              .cta-small-container {
                padding-top: 5px;

                a {
                  color: $orange-1;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  font-weight: normal;
                  text-transform: uppercase;

                  p {
                    padding-right: 10px;
                    line-height: 1.5;
                    text-transform: uppercase;
                  }
                }
              }

              .section-article-container {
                padding: 0;

                .section-article-description {
                  & > div {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                  }

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }

              & > section {
                display: flex;
                flex-direction: column;
                gap: 20px;

                h4 {
                  font-size: 1.2rem;
                  line-height: 1.4;
                  letter-spacing: 0;
                  font-weight: bold;
                  text-align: left;
                  margin: 0;
                }

                & > div {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  p {
                    margin: 0;
                    padding: 0;
                    font-size: 1.2rem;
                    line-height: 1.5;
                    letter-spacing: 0;
                    font-weight: normal;
                  }
                }
              }
            }

            & > section {
              width: 100%;

              &:last-child {
                & > div {
                  border-radius: 10px;
                }

                .symptom-gallery-container {
                  padding: 25px;
                  gap: 15px;
                  display: flex;

                  .symptom-gallery-item {
                    width: 100%;

                    img {
                      max-width: 63px;
                      max-height: 63px;
                    }

                    .item-title {
                      font-size: 1.2rem;
                      letter-spacing: 0;
                      line-height: 1.5;
                      font-weight: bold;
                      margin-left: 20px;
                    }
                  }
                }
              }

              /*.symptom-gallery {
                .symptom-gallery-item {
                  .no-link-image {
                    align-items: flex-start;

                    img {
                      display: none;
                    }

                    .item-title {

                    }

                    .item-alt {

                    }
                  }
                }
              }*/
            }
          }

          &:nth-child(2n) {
            .text-left-symptom-carousel-right-inner {
              & > section {
                &:last-child {
                  & > div {
                    background: #fff;
                  }
                }
              }
            }
          }

          &:nth-child(2n + 1) {
            .text-left-symptom-carousel-right-inner {
              & > section {
                &:last-child {
                  & > div {
                    background: #f4f8fa;
                  }
                }
              }
            }
          }

          &.accordion-carousel {
            .text-left-symptom-carousel-right-inner {
              & > section {
                .symptom-gallery-container {
                  background: transparent !important;
                  padding: 0;

                  .symptom-gallery-item {
                    border-bottom: 2px solid rgba(0, 99, 190, 0.5);

                    .no-link-image {
                      flex-wrap: wrap;
                      align-items: flex-start;
                      justify-content: flex-start;
                      padding-bottom: 15px;
                      position: relative;
                      width: 100%;

                      .item-alt {
                        font-size: 12px;
                        line-height: 15px;
                        max-width: 100%;
                        opacity: 0;
                        margin-top: -300px;
                        margin-left: 0;
                        z-index: -1;
                        padding-top: 15px;

                        a {
                          display: inline-block;
                        }

                        ul {
                          display: flex;
                          flex-direction: column;
                          gap: 15px;

                          li {
                            margin: 0;
                            text-align: left;
                          }
                        }
                      }

                      .item-title {
                        margin-left: 0;
                        max-width: 100%;
                        width: 100%;
                        cursor: pointer;
                        text-align: left;
                        position:relative;

                        &::after {
                          content: "";
                          position: absolute;
                          right: 0;
                          top:6px;
                          width: 12px;
                          height: 12px;
                          margin-top: 2px;
                          border-top: 2px solid $orange-1;
                          border-left: 2px solid $orange-1;
                          transform: rotate(135deg);
                        }

                        &.open {
                          & ~ .item-alt {
                            opacity: 1 !important;
                            transition: opacity 0.3s linear 0.3s, margin-top 0.3s linear, z-index 0.1s linear;
                            margin-top: 0;
                            z-index: 1;
                          }

                          &::after {
                            transform: rotate(225deg);
                            transition-duration: 0.3s;
                          }

                          &.closing {
                            & ~ .item-alt {
                              opacity: 0 !important;
                              transition: opacity 0.25s linear, margin-top 0.3s linear 0.25s, z-index 0.1s linear;
                              margin-top: -300px;
                              z-index: 0;
                            }

                            &::after {
                              transform: rotate(135deg);
                              transition-duration: 0.3s;
                            }
                          }
                        }
                      }

                      img {
                        background: #fff;
                        padding: 5px;
                        max-width: 73px;
                        max-height: 73px;
                        border-radius: 20px;

                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .carousel-container {
          margin: 0;
          background: transparent;

          .cards-wrapper {
            .card-wrapper {
              background: #fff;

              .card {
                .link {
                  .content {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-biologics-and-biosimilars-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          & + .simple-section {
            padding-bottom: 0;
          }
        }

        .content-with-simple-image {
          padding: 20px 25px;
          margin: 0;
          background: transparent;
          display: flex;
          flex-direction: column-reverse;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              img {
                display: block;
                width: 100%;
                height: auto;
              }
            }
          }

          .content-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  color: #003565;
                }
              }
            }
          }

          & + .simple-section {
            background: #fff;

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 20px;

              p,
              span {
                margin: 0;
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
                color: #003565;
              }

              ul {
                display: flex;
                flex-direction: column;
                gap: 15px;
                padding: 0;

                li {
                  margin: 0;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  color: #003565;
                }
              }
            }
          }
        }

        .single-card-gallery-outer-container {
          margin: 0;
          padding-top: 0;
          padding-inline: 25px;
          max-width: unset;
          border-radius: unset;

          .single-card-gallery-container {
            flex-direction: column-reverse;
            gap: 20px;

            & > section {
              .cards-wrapper {
                .card-wrapper {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                  & > div {
                    &:not(.card) {
                      p {
                        margin: 0;
                        font-size: 1.2rem;
                        line-height: 1.5;
                      }
                    }
                  }
                }
              }
            }

            .single-card-text-container {
              padding: 0;

              .single-card-description {
                margin: 0;
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  line-height: 1.5;
                }
              }
            }
          }
        }

        & > .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p,
            span,
            li {
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
              margin: 0;
            }

            ul {
              padding: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;
            }
          }
        }

        #references {
          background: transparent;
        }
      }
    }
  }

  &.dynamic-phase-1-studies-page,
  &.dynamic-phase-3-studies-page,
  &.dynamic-phase_1_SC-page,
  &.dynamic-phase_1_IV-page,
  &.dynamic-phase_1_AI_vs_PFS-page {
    .simple-section {
      .simple-section-title {
        sup:nth-child(2) {
          color: black;
        }
      }
    }
  }

  &.dynamic-caring-for-your-tyenne-page {
    p {
      font-size: 18px!important;
      line-height: 30px!important;
    }
  }

  &.dynamic-the-data-page,
  &.dynamic-clinical-data-page,
  &.dynamic-the-clinical-data-page,
  &.dynamic-phase-1-studies-page,
  &.dynamic-phase-3-studies-page,
  &.dynamic-phase_1_SC-page,
  &.dynamic-phase_1_IV-page,
  &.dynamic-phase_1_AI_vs_PFS-page,
  &.dynamic-die-klinischen-daten-page,
  &.dynamic-phase-1-studien-page,
  &.dynamic-phase-3-studien-page,
  &.dynamic-datos-clínicos-tyenne-page,
  &.dynamic-datos-clínicos-idacio-page{
    .lower-section {
      &.content-page {
        & > .simple-section {
          .simple-section-subtitle {
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0;
            text-align: left;
            color: #0063be;
            font-weight: bold;
            width: 100%;
          }

          .description-container {
            gap: 20px;
            display: flex;
            flex-direction: column;

            p,
            span {
              margin: 0;
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
            }

            ul {
              padding: 0;
              gap: 15px;
              display: flex;
              flex-direction: column;

              li {
                margin: 0;
              }
            }

            li:has(strong) {
              display: block;

              strong {
                font-weight: 600;
              }
            }
          }

          [data-rmiz-wrap="visible"] {
            max-height: 370px;
            overflow-x: scroll;

            img {
              display: block;
              max-height: 370px;
            }

            button {
              display: none;
            }
          }

          &:nth-of-type(4) {
            padding-bottom: 25px;
          }
        }

        .content-with-simple-image {
          padding: 0 25px 50px;
          display: flex;
          flex-direction: column-reverse;
          gap: 30px;
          background: #fff;
          margin: 0;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              display: flex;

              img {
                margin: 0;
                width: 100%;
                height: auto;
                display: block;
              }
            }
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0;
                margin: 0;
              }

              .description-container {
                gap: 20px;
                display: flex;
                flex-direction: column;

                p,
                span {
                  margin: 0;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                }

                ul {
                  padding: 0;
                  gap: 15px;
                  display: flex;
                  flex-direction: column;

                  li {
                    margin: 0;
                  }
                }
              }
            }
          }

          & ~ .simple-section {
            background: transparent;
            padding-top: 25px;
            padding-bottom: 0;

            [data-rmiz-wrap="visible"] {
              img {
                height: unset;
                width: unset;
                max-width: unset;
                max-height: 223px;
              }

              button {
                display: none;
              }
            }

            &:nth-of-type(6) {
              padding-top: 50px;
            }

            &:nth-of-type(10) {
              padding-bottom: 50px;
            }
          }
        }

        .content-with-multiple-images {
          background: #fff;

          .multi-image-container {
            background: transparent;
            padding: 0;

            .simple-section {
              .simple-section-title {
                display: none;
              }

              .simple-section-subtitle {
                display: none;
              }

              .description-container {
                display: none;
              }

              [data-rmiz-wrap="visible"] {
                overflow-x: scroll;

                img {
                  height: unset;
                  width: unset;
                  max-width: unset;
                  max-height: 350px;
                }

                button {
                  display: none;
                }
              }
            }
          }

          & > .simple-section {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .simple-section-title {
              margin: 0;
              font-size: 1.2rem;
              line-height: 1.4;
              letter-spacing: 0;
            }

            .simple-section-subtitle {
              margin: 0;
              font-size: 18px;
              line-height: 21px;
              letter-spacing: 0;
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 20px;

              p {
                margin: 0;
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
              }
            }
          }

          & + .simple-section {
            padding: 50px 25px;
          }
        }

        #references {
          background: #fff;
        }
      }
    }
  }

  &.dynamic-administration-option-page,
  &.dynamic-administration-options-page,  
  &.dynamic-administration-presentations-page,
  &.dynamic-applikationssysteme-page,
  &.dynamic-applikationssystemes-page,
  &.dynamic-presentaciones-de-administración-page,
  &.dynamic-presentaciones-de-administración-tyenne-page,
  &.dynamic-guía-de-autoadministración-page,
  &.dynamic-darreichungsformen-page,
  &.dynamic-darreichungsformen-tyenne-page,   
  &.dynamic-darreichungsformen-otulfi-page,   
  &.dynamic-toedieningsopties-page,
  &.dynamic-administreringsformer-tyenne-page,
  &.dynamic-administreringsformer-idacio-page,
  &.dynamic-administreringsformer-otulfi-page
    {
    .lower-section {
      &.content-page {
        .hero-section-text {
          & ~ * {
            
          }

          & + .simple-section {
            padding-bottom: 0;
          }
        }

        .carousel-container {
          margin: 0;
          padding: 30px 25px;

          .cards-wrapper {
            padding: 0;

            .card-wrapper {
              background: #fff;

              .card {
                .link {
                  .content {
                    background: transparent;
                  }
                }
              }
            }
          }

          & + .simple-section {
            padding-top: 0;
          }
        }

        #references {
          background: #fff;
        }
      }
    }
  }

  &.dynamic-supporting-patients-page {
    .lower-section {
      &.content-page {
        .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 25px;

            p,
            span,
            strong,
            li {
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
            }

            ul {
              display: flex;
              flex-direction: column;
              gap: 15px;
              padding: 0;

              li {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-enrolling-patients-page {
    .lower-section {
      &.content-page {
        .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 25px;

            p,
            span,
            strong,
            li {
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0;
            }

            ul {
              display: flex;
              flex-direction: column;
              gap: 15px;
              padding: 0;

              li {
                margin: 0;
                flex-wrap: wrap;
                justify-content: end;

                & > * {
                  max-width: calc(100% - 35px);
                  width: 100%;
                  margin-top: 20px;

                  &:first-child {
                    margin-top: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-caring-for-your-medication-page,
  &.dynamic-caring-for-your-otulfi-page{
    .lower-section {
      &.content-page {
        .gallery-items-outer-container {
          & + .gallery-items-outer-container {
            padding-bottom: 25px;

            & + .simple-section {
              padding-top: 0;
              background: transparent;

              a {
                color: $orange-1;
              }

              & + .gallery-items-outer-container {
                background: #fff;
              }
            }
          }

          .gallery-items-inner-container {
            .symptom-gallery {
              .symptom-gallery-item {
                .item-title {
                  a {
                    display: inline;

                    sup,sub {
                      font-size: 10px;
                      line-height: 14px;
                      color: #003565;
                    }
                  }
                }
              }
            }
          }
        }

        #references {
          background: transparent;
        }
      }
    }
  }

  &.dynamic-kabicare-programme-page {
    .lower-section {
      &.content-page {
        #references {
          order: 10;
          background: #fff;

          & + .attachment-section {
            background: #f4f8fa;
            border-bottom: 2px solid rgba(0, 99, 190, 0.5);
          }
        }

        .content-with-image {
          & ~ .simple-section {
            .description-container {
              display: flex;
              flex-direction: column;
              gap: 20px;
            }
          }
        }

        .attachment-section {
          .attachment-section-inner-container {
            .attachment-body {
              & > div {
                ul {
                  li {
                    flex-wrap: wrap;

                    * {
                      max-width: calc(100% - 35px);
                      width: 100%;
                      margin-left: auto;
                      margin-top: 15px;

                      &:first-child {
                        margin-top: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-non-infectious-uveitis-page {
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          margin: 0;
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }
          }

          .content-container {
            .simple-section {
              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-truths-vs-myths-page,
  &.dynamic-feiten-en-fabels-page,
  &.dynamic-wahrheit-oder-mythos-page,
  &.dynamic-istina-i-mitovi-page,
  &.dynamic-sant-eller-falskt-page{
    .lower-section {
      &.content-page {
        & > .simple-section {
          .simple-section-subtitle {
            width: 100%;
            text-align: left;
            font-size: 18px;
            line-height: 24px;
            color: #00c2df;
            font-weight: bold;
            letter-spacing: 0;
          }
        }

        .content-with-simple-image {
          margin: 0;
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              button {
                display: none;
              }

              img {
                display: block;
                width: 100%;
                height: auto;
                border-radius: 10px;
              }
            }
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 5px;

              .simple-section-title {
                margin: 0;
                order: 2;
                font-size: 1.2rem;
                letter-spacing: 0;
              }

              .cta-small-container {
                order: 1;
                padding: 0;

                a {
                  pointer-events: none;

                  p {
                    color: #003565;
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0;
                    text-transform: uppercase;
                  }

                  .arrow-container {
                    display: none;
                  }
                }
              }

              .description-container {
                order: 2;
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: 10px;

                p {
                  margin: 0;
                  font-size: 1.2rem;
                  letter-spacing: 0;
                  line-height: 1.5;
                }
              }
            }
          }
        }

        .single-card-outer-container {
          .single-card-container {
            .single-card-inner-container {
              gap: 20px;

              .single-card-text-container {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .single-card-title {
                  color: #0063be;
                  font-size: 1.2rem;
                  letter-spacing: 0;
                  line-height: 1.4;
                  text-transform: unset;
                }

                .single-card-description {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  p {
                    font-size: 1.2rem;
                    letter-spacing: 0;
                    line-height: 1.5;
                    color: #003565;
                  }
                }
              }

              [data-rmiz-wrap="visible"] {
                .single-card-image-text,
                button {
                  display: none;
                }

                img {
                  margin: 0;
                  display: block;
                  border-radius: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-contact-us-page,
  &.dynamic-cookie-statement-page,
  &.dynamic-terms-and-conditions-page,
  &.dynamic-adverse-events-page,
  &.dynamic-privacy-policy-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          padding: 3rem 2rem;

          .hero-section-text-inner-container {
            padding: 0;
          }
        }

        .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p,
            span,
            strong,
            a,
            li {
              font-size: 1.2rem;
              margin: 0;
              line-height: 1.5;
              letter-spacing: 0;
              word-break: break-word;
            }

            a {
              color: $orange-1;
            }

            h4 {
              margin: 0;
              text-align: left;
              font-size: 1.2rem;
              line-height: 1.4;
              font-weight: bold;
              letter-spacing: 0;
            }

            h2 {
              margin: 0;
              text-align: left;
              font-size: 26px;
              line-height: 32px;
              font-weight: bold;
              letter-spacing: 0;
            }

            ul,
            ol {
              display: flex;
              flex-direction: column;
              gap: 15px;
              padding: 0;
              margin: 0;

              li {
                margin: 0;
                display: list-item;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 640px) {
  .dynamic-page {
    .lower-section {
      &.no-max-width {
        .content-with-image {
          &.notebook-image {
            .content-with-image-inner-container {
              .image-container {
                max-width: 450px;

                .styled-section-image {
                  .styled-section-image-inner {
                    .image-wrapper {
                      &::before {
                        top: 8px;
                        bottom: 0;
                        left: -33px;
                        right: -33px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 641px) and (max-width: 768px) {
  .dynamic-page {
    .lower-section {
      &.no-max-width {
        .content-with-image {
          &.notebook-image {
            .content-with-image-inner-container {
              .image-container {
                max-width: 592px;

                .styled-section-image {
                  .styled-section-image-inner {
                    .image-wrapper {
                      &::before {
                        top: 4px;
                        bottom: -8px;
                        left: -53px;
                        right: -53px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1365px) {
  .dynamic-page {
    .lower-section {
      &.no-max-width {
        .content-with-image {
          &.notebook-image {
            .content-with-image-inner-container {
              .image-container {
                max-width: 719px;

                .styled-section-image {
                  .styled-section-image-inner {
                    max-width: 600px;
                    margin-inline: auto;
                    .image-wrapper {
                      &::before {
                        top: 4px;
                        bottom: -8px;
                        left: -53px;
                        right: -53px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
