.page-container {
  .content-wrap {
    main {
      &.forgot-password,
      &.change-password {
        background: #F4F8FA;
        padding: 50px 25px;

        & > * {
          letter-spacing: 0;
        }

        .page-outer-container {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin: 0;

          .page-inner-container {
            h1 {
              font-size: 30px;
              line-height: 36px;
              color: #00C2DF;
              font-weight: bold;
              margin: 0;
            }
          }

          .subtitle {
            p {
              margin: 0;
              font-size: 16px;
              line-height: 22px;
              color: #003565;
              font-weight: normal;
            }
          }

          .content-container {
            form {

              .MuiFormHelperText-root{
                padding-left: 15px;
              }

              .MuiGrid-item {
                .input-container {
                  border-radius: 10px;

                  label {
                    color: #D3D3D3;
                    padding-left: 25px;
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: 0;
                    line-height: 19px;
                  }
                }
              }

              button {
                padding: 11px 25px 10px;
                width: 100%;
                filter: none;
                border-radius: 20px;
                height: auto;
                margin: 20px 0 0;

                span {
                  font-size: 16px;
                  line-height: 19px;
                  letter-spacing: 0;
                  text-transform: uppercase;
                  font-weight: normal;
                  color: #fff;
                }
              }

              label {
                color: #d3d3d3;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0;
                line-height: 19px;
              }

              .button-container {
                p {
                  &.Mui-error {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      &.change-password {
        .page-outer-container {
          .content-container {
            form {
              display: flex;
              flex-direction: column;
              gap: 30px;

              .button-container {
                button {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
