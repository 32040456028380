.cookie {
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.85;
  backdrop-filter: blur(23px);
  margin: 35px 15px 15px 15px;

  div {
    margin-bottom: 20px;

    p,
    a {
      font-weight: 300;
      font-size: 18px;
      line-height: 32px;
      margin: 0;
      text-decoration: none;
      color: $black-1;
    }
  }

  .button-container {
    button {
      width: 325px;

      &:last-of-type {
        margin-top: 17px;
      }
    }
  }
}
