@import '../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
    .flex-container {
        justify-content: flex-start;
    }

    .ellipse-main {
        display: block;
        top: calc(680px + 17%)
    }

    .align {
        text-align: start;
    }

    .text-align-center {
        text-align: center;
    }

    .breadcrumbs {
        display: block;
    }

    .align-right-desktop {
        text-align: right;
    }

    .item-image {
        margin: auto;
        width: auto;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}
