.page-container {
  @mixin plus-sign {
    content:"";
    position: absolute;
    background-color: #003565;
    border-radius: 9999px;
  }

  .content-wrap {
    .styled-section-image {
      position: relative;
      z-index: 1;

      .styled-section-image-title {
        font-weight: bold;
        color: $blue-1;
        font-size: 20px;
      }

      .styled-section-image-inner {
        border: 3px solid #00C2DF;
        padding: 17px;
        border-radius: 10px;
        margin: 17px;
        position: relative;
        box-shadow: 0px 0px 80px rgba(0,0,0,0.07);

        .image-wrapper {
          display: flex;
          justify-content: center;
        }
      }

      img {
        z-index: 3;

        &.item-image {
          width: 100%;
          height: auto;
          display: block;
          border-radius: 20px; //FIXME have to implement the device container around it
        }
      }

      .plus-sign {
        width: 16px;
        aspect-ratio: 1/1;
        position: absolute;
        z-index: 2;

        &::before {
          @include plus-sign;
          top: 0;
          left: 30%;
          width: 0.3em;
          height: 100%;
        }

        &::after {
          @include plus-sign;
          top:30%;
          left: 0;
          width: 100%;
          height: 0.3em;
        }

        &.left-plus-sign {
          top: -0.5em;
          left: -0.5em;
        }

        &.right-plus-sign {
          bottom: -0.5em;
          right: -0.5em;
        }
      }

      .color-rectangle {
        max-width: 5em;
        aspect-ratio: 1 / 1;
        width: 100%;
        border-radius: 10px;
        background: $orange-1;
        position: absolute;
        z-index: -1;

        &.left-rectangle {
          left: 8px;
          bottom: -9px;
        }

        &.right-rectangle {
          right: 8px;
          top: -9px;
        }
      }
    }
  }
}
