@import '../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .footer {
    padding: $section-padding Max(40px, unquote("(100vw - 1450px) / 2"));
    position: relative;
    width: 100%;

    &::before {
      background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0 0 no-repeat padding-box;
      content: "";
      height: 40px;
      position: absolute;
      right: 0;
      top: -40px;
      width: 100%;
      display:none;
    }

    .footer-inner-container {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
    }

    .disclaimer {
      margin-bottom: 2rem;
      opacity: 1;
      display:flex;
      flex-direction:column;
      justify-content:right;
      width:100%;
      align-items:flex-end;
      
      p,
      a {
        font-size: 0.8rem;
        line-height: 1.4;
        width:50%;
        text-align:right;
        margin: 0;
        color:rgba(0,0,0,0.8);
      }
    }

    .bottom {
      flex-direction: row;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      border: none;

      .logo {
        height: auto;
        margin: 0;
        max-width: 240px;
        padding: 0;
        width: 100%;
        display:block;

        img {
          height: auto;
          width: 100%;
          min-width: unset;
          max-width: unset;
        }
      }

      .content {
        align-items: flex-end;
        padding: 0;
        gap:2rem;

        .links {
          margin: 0;
          padding: 0;

          a {
            color: #0063BE;
            font-family: 'interstate',arial,sans-serif;
            font-size: 0.8rem;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 1.4;
            padding: 0;
          }

          div {
            margin: 0 1rem;
            width: unset;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .preparation-container {
          flex-direction:row;
          
          .date {
            p {
              color: rgba(0,0,0,0.8);
              font-size: 0.8rem;
              font-weight: normal;
              line-height: 1.4;
              letter-spacing: 0;
              
            }
          }

          .legal-number {
            color: rgba(0,0,0,0.8);
            letter-spacing: 0;
            font-size: 0.8rem;
            line-height: 1.4;
            font-weight: normal;

            &:empty {
              margin: 0;
            }
          }
        }
      }
    }
  }
    
  table.footer-stats{
    width: 66%;
    
  }  
  
}
