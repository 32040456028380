@import '../../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .page-container {
    .content-wrap {
      .reminder {
        padding: 100px Max(40px, unquote("(100vw - 1400px) / 2"));
        max-width: 100%;

        .page-outer-container {
          gap: 30px;
          max-width: 935px;
          margin-inline: auto;

          .page-inner-container {
            .main-title {
              font-size: 36px;
              line-height: 44px;
            }
          }

          .subtitle {
            p {
              font-size: 18px;
              line-height: 30px;
            }
          }
          
          .content-container {
            margin-top: 20px;

            .reminder-container {
              gap: 25px;

              .switch-container {
                .reminder-switch-label {
                  font-size: 26px;
                  line-height: 30px;
                }
              }

              .reminders-box {
                gap: 20px;

                .starting-day-picker-wrapper {
                  flex-direction: row;
                  justify-content: space-between;

                  .select {
                    align-self: flex-end;
                    width: 100%;
                    max-width: 465px;
                  }
                }

                .button-wrapper {
                  margin-top: 30px;
                }

                .button-container {
                  flex-direction: row;
                }
              }
            }
          }
        }
      }
    }
  }

}
