@import "../_util/_color.scss";

.MuiPickersBasePicker-container {
    .MuiPickersToolbar-toolbar {
        button {
            border-radius: 0;
            width: auto;
            height: auto;
            box-shadow: none;

            .MuiPickersToolbarText-toolbarTxt {
                color: white;
            }
        }
    }

    .MuiPickersCalendarHeader-transitionContainer {
        height: 30px;
    }

    .MuiPickersYear-root {
        color: $black-1;
    }

    .MuiPickersYear-yearDisabled {
        color: $black-2;
    }

    .MuiPickersYear-yearSelected {
        color: $orange-1;
    }
}
