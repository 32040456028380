body {
  color: black;
  background-color: white;
}

a {
  text-decoration: none;
}

p {
  display: block;
  margin-block-start: 14px;
  margin-block-end: 14px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 15px;
}

li {
  display: flex;
  list-style: none;
  margin: 12px 0;
  width: 100%;
}

li::before {
  background: url("resources/images/common/list_item_butterfly.png");
  content: "";
  display: inline-block;
  height: 20px;
  margin-right: 15px;
  margin-top: 4px;
  min-height: 20px;
  min-width: 20px;
  width: 20px;
}

li ul li::before {
  background: url("resources/images/common/list_item_plus.png");
  height: 13px;
  min-height: 13px;
  min-width: 13px;
  width: 13px;
  margin-bottom: 3px;
  margin-top: 6px;
}

.MuiMenu-list .MuiListItem-root {
  display: block;
}

.MuiMenu-list .MuiListItem-root::before {
  display: none;
}

.checkboxLink {
  text-decoration: underline;
  color: black
}
