@media (min-width: 768px) {
    .flex-container {
        & > div {
            flex: 40%;
        }
    }

    .ellipse-main {
        display: block;
        top: calc(280px + 47vw)
    }
}
