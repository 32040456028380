@keyframes move-out {
  0% {
    transform: translate3d(0, -30px, -50px);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@keyframes fade-out-arrow {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes move-out-arrow {
  0% {
    transform: translate3d(0, 30px, 50px);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-right {
  0% {
    transform: translate3d(-15px, 0, 0);
  }

  100% {
    transform: translate3d(175px, 0, 0);
  }
}

@keyframes slide-left-full {
  0% {
    transform: translate3d(100%, 0, 0);
  }

  100% {
    transform: translate3d(0%, 0, 0);
  }
}

@keyframes slide-left-full-out {
  0% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slide-right-full {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0%, 0, 0);
  }
}

@keyframes slide-right-full-out {
  0% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes arc-down { 
  0% {
    transform: translate3d(0%,-40px,0)
  }
  
  10% {
    transform: translate3d(calc(10% + 4px),-40px,0)
  }
  
  20% {
    transform: translate3d(calc(20% + 8px),-40px,0)
  }
  
  30% {
    transform: translate3d(calc(30% + 12px),-36px,0)
  }
  
  40% {
    transform: translate3d(calc(40% + 16px),-32px,0)
  }
  
  50% {
    transform: translate3d(calc(50% + 20px),-24px,0)
  }
  
  60% {
    transform: translate3d(calc(60% + 24px),-14px,0)
  }
  
  70% {
    transform: translate3d(calc(70% + 28px),-2px,0)
  }
  
  80% {
    transform: translate3d(calc(80% + 32px), 16px,0)
  }
  
  90% {
    transform: translate3d(calc(90% + 36px), 32px,0)
  }
  
  100% {
    transform: translate3d(calc(100% + 40px), 40px,0)
  }
}

@keyframes arc-up { 
  0% {
    transform: translate3d(calc(-100% - 40px), 40px, 0)
  }
  
  10% {
    transform: translate3d(calc(-90% - 36px), 28px,0)
  }
  
  20% {
    transform: translate3d(calc(-80% - 32px), 18px,0)
  }
  
  30% {
    transform: translate3d(calc(-70% - 28px), 0px,0)
  }
  
  40% {
    transform: translate3d(calc(-60% - 24px),-12px,0)
  }
  
  50% {
    transform: translate3d(calc(-50% - 20px),-22px,0)
  }
  
  60% {
    transform: translate3d(calc(-40% - 16px),-30px,0)
  }
  
  70% {
    transform: translate3d(calc(-30% - 12px),-36px,0)
  }
  
  80% {
    transform: translate3d(calc(-20% - 8px),-40px,0)
  }
  
  90% {
    transform: translate3d(calc(-10% - 4px),-40px,0)
  }
  
  100% {
    transform: translate3d(0%,-40px,0)
  }
}

@keyframes arc-up-left { 
  100% {
    transform: translate3d(0%,-40px,0)
  }
  
  90% {
    transform: translate3d(calc(10% + 4px),-40px,0)
  }
  
  80% {
    transform: translate3d(calc(20% + 8px),-40px,0)
  }
  
  70% {
    transform: translate3d(calc(30% + 12px),-36px,0)
  }
  
  60% {
    transform: translate3d(calc(40% + 16px),-32px,0)
  }
  
  50% {
    transform: translate3d(calc(50% + 20px),-24px,0)
  }
  
  40% {
    transform: translate3d(calc(60% + 24px),-14px,0)
  }
  
  30% {
    transform: translate3d(calc(70% + 28px),-2px,0)
  }
  
  20% {
    transform: translate3d(calc(80% + 32px), 16px,0)
  }
  
  10% {
    transform: translate3d(calc(90% + 36px), 32px,0)
  }
  
  0% {
    transform: translate3d(calc(100% + 40px), 40px,0)
  }
}

@keyframes arc-down-left { 
  100% {
    transform: translate3d(calc(-100% - 40px), 40px, 0)
  }
  
  90% {
    transform: translate3d(calc(-90% - 36px), 28px,0)
  }
  
  80% {
    transform: translate3d(calc(-80% - 32px), 18px,0)
  }
  
  70% {
    transform: translate3d(calc(-70% - 28px), 0px,0)
  }
  
  60% {
    transform: translate3d(calc(-60% - 24px),-12px,0)
  }
  
  50% {
    transform: translate3d(calc(-50% - 20px),-22px,0)
  }
  
  40% {
    transform: translate3d(calc(-40% - 16px),-30px,0)
  }
  
  30% {
    transform: translate3d(calc(-30% - 12px),-36px,0)
  }
  
  20% {
    transform: translate3d(calc(-20% - 8px),-40px,0)
  }
  
  10% {
    transform: translate3d(calc(-10% - 4px),-40px,0)
  }
  
  0% {
    transform: translate3d(0%,-40px,0)
  }
}

@keyframes slide-out-right {
  0% {
    transform: translate3d(calc(100% + 40px), 40px, 0);
  }

  100% {
    transform: translate3d(200%, 40px, 0);
  }
}

@keyframes slide-in-right {
  100% {
    transform: translate3d(calc(100% + 40px), 40px, 0);
  }

  0% {
    transform: translate3d(200%, 40px, 0);
  }
}

@keyframes slide-out-left{
  0% {
    transform: translate3d(calc(-100% - 40px), 40px, 0);
  }

  100% {
    transform: translate3d(-200%, 40px, 0);
  }
}

@keyframes slide-in-left{
  100% {
    transform: translate3d(calc(-100% - 40px), 40px, 0);
  }

  0% {
    transform: translate3d(-200%, 40px, 0);
  }
}

@keyframes homepage-mouse-button-scroll-down-animation {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 30px, 0);
  }
}

// TODO RR noice
@keyframes get-rekt {
  0% {
    max-height: 0;
    height: 0;
    visibility: hidden;
  }

  100% {
    max-height: 100%;
    height: 168px;
    visibility: visible;
  }
}

// TODO RR noice
@keyframes more-rekt-this-time {
  0% {
    max-height: 100%;
    height: 168px;
  }

  100% {
    max-height: 0;
    height: 0;
  }
}

// TODO RR noice
@keyframes faszom {
  0% {
    opacity: 0;
  }

  1% {
    display: block;
  }

  100% {
    opacity: 1;
  }
}
