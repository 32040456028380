$card-wrapper-gap: 30px;
$border-padding-on-side: Max(40px, unquote("(100vw - 1200px) / 2"));

@import '../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .dynamic-page {
    display: flex;

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////// Lower section default
    
    .lower-section {
      width: 100%;
      max-width: 1200px;

      /////////////////////////////////////////////////////////
      ///////////// Hero Area    
      
      .hero-section-text {
        .hero-section-text-inner-container {
          max-width: 40%;
          padding: 8rem 0px;
          left:Max(40px, unquote("(100vw - 1200px) / 2"));

          h1 {
          }

          .subtitle {
          }
        }

        .hero-section-image-container {
          &::before {
            background: transparent linear-gradient(91deg, #182c4c 5%, rgba(24,44,76,0.7) 100%) 0% 0% no-repeat padding-box;
          }

          &::after {

          }

          .hero-section-image {
            max-width: 100%;
            object-position: right;
            object-fit:cover;
            min-height: unset; //TODO remove above
            height: 100%;
            width:100%;
          }
        }
      }


    /////////////////////////////////////////////////////////
    ///////////// Carousel Container and cards    
    
        .carousel-container {
            margin-top: 6rem;
            margin-bottom: 6rem;
            padding-inline:6rem Max(40px, unquote("(100vw - 1200px) / 2"));
          
          .cards-wrapper {
            .card-wrapper {
              padding: 2rem;
            }
          }
        }    





      .logo-container {
        height: 388px;
        width: 388px;
      }




      &.no-max-width {
        margin-bottom:0;
        
        .below-hero {
          padding: $section-padding $border-padding-on-side;

          &-inner-container {
            max-width: 935px;

            h4 {
              font-size: 36px;
              line-height: 44px;
            }

            .description-container {
              p {
                font-size: 1.2rem;
                line-height: 1.5;
              }
            }
          }
        }

        .condition-select {
          padding: 0 0 50px;
          flex-direction: column-reverse;
          gap: 50px;

          min-height: 550px;
          max-height: unset;
          height: unset;

          .condition-select-inner-container {
            max-width: 1200px;
            margin-top: 0;

            h1 {
              margin-bottom: 20px;
              line-height: 50px;
              font-size: 42px;
            }

            .select {
              max-width: 500px;
              min-width: 500px;
              margin-inline: auto;
            }

            .subtitle {
              display: flex;
              flex-direction: column;
              gap: 20px;
              justify-content: center;
              align-items: flex-start;

              .condition-select-body {
                p {
                  margin: 0;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  color: #003565;
                  font-weight: normal;
                }
              }
            }
          }

          .hero-section-image-container {
            display: block;
            width: 100%;
            object-position: unset;
            max-width: 100%;
            object-fit: unset;
            min-height: unset;
            position: absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;
            max-height: 100%;
            overflow: hidden;


            &::before {
              content: "";
              background: transparent linear-gradient(
                              91deg, #FFFFFF 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
              top: 0;
              left: 0;
              bottom: 0;
              width: calc(1356 / 1920 * 100%);
              position: absolute;
            }

            &::after {
              content: "";
              bottom: 0;
              left: 0;
              right: 0;
              height: 25px;
              width: 100%;
              position: absolute;
              background: linear-gradient(
                              180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 67%, rgba(255, 255, 255, 1) 100%);
            }

            .hero-section-image {
              max-width: 100%;
              object-position: unset;
              aspect-ratio: 1920 / 551;
              //min-height: 550px;
              min-height: unset; //TODO remove above
              height: unset;
              max-height: unset;
            }
          }
        }

        .section-article-container {
          padding: $section-padding $border-padding-on-side;

          .section-article-inner-container {
            width:100%;

            .section-article-title,
            .section-article-description {
            }

            .section-article-title {
              gap: 10px;

              .section-article-string-1 {
                font-size: 1.2rem;
                line-height: 1.5;
              }

              .section-article-string-2 {
                font-size: 36px;
                line-height: 44px;
              }
            }

            .section-article-description {
              & > div {
                &:not(.cta-small-container) {
                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }

              .cta-small-container {
                padding-top: 30px;
              }
            }
          }
        }

        .text-left-symptom-carousel-right {
          padding: $section-padding $border-padding-on-side;

          .text-left-symptom-carousel-right-inner {
            max-width: 1200px;
            gap: 90px;
            flex-direction: row;
            align-items: center;
            
            &.text-left-symptom-carousel-right-inner-col{
              flex-direction:column;
              
            }
            
            &.flex-row-reverse {
              flex-direction: row-reverse;
            }

            .left-side-container {
              display: flex;
              flex-direction: column;
              gap: 2rem;
              width: 100%;

              .cta-small-container {
                padding-top: 10px;

                a {
                  color: $orange-1;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  font-weight: normal;
                  text-transform: uppercase;

                  p {
                    padding-right: 0px;
                    line-height: 1.4;
                  }
                }
              }

              .section-article-container {
                padding: 0;

                .section-article-description {
                  & > div {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                  }

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }

              & > section {
                display: flex;
                flex-direction: column;
                gap: 20px;

                h4 {
                  font-size: 36px;
                  line-height: 44px;
                }

                & > div {
                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }

            & > section {
              width: 100%;

              &:last-child {
                & > div {
                  border-radius: 20px;
                }

                .symptom-gallery-container {
                  padding: 40px;
                  gap: 2rem;

                  .symptom-gallery-item {
                    width: 100%;

                    img {
                      max-width: 120px;
                      max-height: 120px;
                    }

                    .item-title {
                      font-size: 1.2rem;
                      line-height: 1.4;
                      margin-left: 45px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(2n) {
            .text-left-symptom-carousel-right-inner {
              & > section {
                &:last-child {
                  & > div {
                    background: #FFF;
                  }
                }
              }
            }
          }

          &:nth-child(2n + 1) {
            .text-left-symptom-carousel-right-inner {
              & > section {
                &:last-child {
                  & > div {
                    background: #F4F8FA;
                  }
                }
              }
            }
          }

          &.accordion-carousel {
            .text-left-symptom-carousel-right-inner {
              & > section {
                .symptom-gallery-container {
                  padding: 0;

                  .symptom-gallery-item {
                    .no-link-image {
                      padding-bottom: 2rem;

                      .item-alt {
                        font-size: 1.2rem;
                        line-height: 1.5;
                        max-width: calc(100% - 120px);
                        margin-top: -400px;
                        margin-left: 10px;

                        a {
                          width: unset;
                        }
                      }

                      .item-title {
                        margin-left: 15px;
                        max-width: calc(100% - 120px);
                        font-size: 28px;
                        line-height: 32px;
                        position:relative;

                        &.open {
                          &.closing {
                            & ~ .item-alt {
                              margin-top: -400px;
                            }
                          }
                        }
                      }

                      img {
                        padding: 10px;
                        max-width: 95px;
                        max-height: 95px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .text-top-symptom-carousel-bottom {
          padding: $section-padding $border-padding-on-side;
          margin: 0;

          .text-top-symptom-carousel-bottom-inner {
            display: flex;
            flex-direction: column;
            gap: 60px;

            .top-side-container {
              display: grid;
              //grid-template-areas: "left right";
              grid-template-columns: repeat(2, 1fr);
              grid-template-rows: 44px 20px 1fr;
              gap: 50px;

              &.two-column-body {
                row-gap: 0;

                .simple-section {
                  display: none;
                  gap: 20px;

                  &:nth-child(1),
                  &:nth-child(2) {
                    display: flex;
                  }

                  &:nth-child(1) {
                    grid-area: 1 / 1 / 4 / 2;
                  }

                  &:nth-child(2) {
                    grid-area: 3 / 2 / 4 / 3;

                    .simple-section-title {
                      display: none;
                    }
                  }

                  .simple-section-title {
                    text-align: left;
                  }
                }
              }

              &.one-column-body {
                display: flex;

                .simple-section {
                  display: none;
                  align-items: center;

                  &:nth-child(1) {
                    display: flex;
                  }

                  .simple-section-title {
                    max-width: 730px;
                  }
                }
              }

              .simple-section {
                gap: 60px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                  text-align: center;
                }

                .description-container {
                  gap: 30px;

                  p,
                  li,
                  span,
                  a,
                  strong {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }

                  ul {
                    gap: 25px;
                  }
                }
              }
            }

            & > section {
              .symptom-gallery-container {
                grid-template-columns: repeat(3, 1fr);
                row-gap: 60px;
                column-gap: 50px;

                .symptom-gallery-item {
                  height: 100%;

                  .no-link-image {
                    flex-wrap: wrap;
                    gap: 20px;
                    align-items: flex-start;

                    img {
                      max-width: 95px;
                      max-height: 95px;
                    }

                    .item-title {
                      max-width: 100%;
                      width: 100%;
                      font-size: 28px;
                      line-height: 32px;
                      margin: 0;
                      height: 100%;
                    }

                    .item-alt {
                      font-size: 1.2rem;
                      line-height: 1.5;
                      height: 100%;

                      a {
                        width: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .simple-section-with-multiple-title-and-body {
          padding: $section-padding $border-padding-on-side;

          .simple-section {
            flex-direction: row;
            column-gap: 90px;
            flex-wrap: wrap;
            row-gap: 20px;

            .simple-section-title,
            .simple-section-subtitle {
              max-width: calc(100% / 2 - 90px / 2);
              width: 100%;
              font-size: 36px;
              line-height: 44px;
              letter-spacing: 0;
            }

            .description-container {
              flex-direction: row;
              gap: 90px;

              p,
              span {
                font-size: 1.2rem;
                line-height: 1.5;
              }

              .manual-body-1,
              .manual-body-2 {
                gap: 30px;
                max-width: calc(100% / 2 - 90px / 2);
              }
            }
          }
        }

        .content-top-multi-boxes-bottom-outer {
          padding: $section-padding $border-padding-on-side;

          .content-top-multi-boxes-bottom-inner {
            & > .simple-section {
              gap: 20px;

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                gap: 30px;

                p {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }
              }
            }

            .box-wrapper {
              flex-direction: row;
              gap: 25px;

              & > .simple-section {
                gap: 70px;
                width: 100%;

                .simple-section-title {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }

                .description-container {
                  padding: 30px;

                  height: 100%;

                  p,
                  span {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }
        }

        .content-with-pros-and-cons-boxes-outer {
          padding: $section-padding $border-padding-on-side;

          .content-with-pros-and-cons-boxes-inner {
            gap: 60px;

            .row-wrapper {
              gap: 40px;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 28px;
                  line-height: 32px;
                }

                .simple-section-subtitle {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }
              }

              .pros-cons-box {
                .header-wrapper {
                  padding: 15px 30px;

                  .simple-section-title {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }

                .description-container {
                  padding: 20px 30px;

                  ul {
                    flex-direction: row;
                    flex-wrap: wrap;
                    row-gap: 15px;
                    column-gap: 20px;

                    li {
                      font-size: 1.2rem;
                      line-height: 1.4;
                      width: 100%;
                      max-width: calc(100% / 3 - 20px * 2 / 3);
                    }
                  }
                }
              }
            }

            .content-wrapper {
              gap: 40px;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .simple-section-subtitle {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }

                &:nth-child(2) {
                  .simple-section-title {
                    font-size: 28px;
                    line-height: 32px;
                  }
                }
              }
            }

            .box-wrapper {
              flex-direction: row;
              gap: 25px;

              .pros-cons-box {
                width: 100%;

                .header-wrapper {
                  padding: 15px 30px;

                  .simple-section-title {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }

                .description-container {
                  padding: 20px 30px;

                  ul {
                    li {
                      font-size: 1.2rem;
                      line-height: 1.4;
                    }
                  }
                }
              }
            }
          }
        }

        .single-card-gallery-outer-container {
          max-width: unset;
          margin: 0;
          padding: $section-padding $border-padding-on-side;
          border-radius: unset;

          .single-card-gallery-container {
            max-width: 1200px;
            gap: 3rem;

            .single-card-text-container {
              padding: 0;
              text-align:center;
              display:flex;
              flex-direction:column;
              gap:1rem;

              .single-card-title {
                margin: 0 !important;
                font-weight: bold;
                font-size: 36px;
                letter-spacing: 0;
                line-height: 44px;
                color: #0063BE;
                text-transform: unset;
              }

              .single-card-description {
                p,
                span {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }
              }
            }

            .cards-wrapper {
              margin: 0;
              gap: 3rem;
              justify-content: space-around;
              
              .card-wrapper {
                max-width:360px;
                
              p{
                text-align:left;
              }
              }
            }
          }
        }
        
        .content-with-simple-image{
           padding: $section-padding $border-padding-on-side;
           gap:8rem;
           flex-direction:row;
           
           .simple-image-container{
             width:50%;
             display:flex;
             align-items:center;               
           }
           
           &.image-on-right-two-thirds, &.image-on-left-two-thirds{
             .simple-image-container{
               width:200%;
               display:flex;
               align-items:center;              
             }
           }
           
           &.image-on-right-half, &.image-on-left-half{
             .simple-image-container{
               width:100%;
               display:flex;
               align-items:center;  
             }
           }
           
           .simple-image-container{
             
             &.simple-image-framed{
               padding:2.5rem;
             }
             
             .simple-image{
               display:flex;
               flex-direction:column;
               
               > div:first-of-type{
                display:flex;
                flex-direction:column; 
                gap: 1rem;
                
                 .single-card-image-title{
                  display:flex;
                  flex-direction:column; 
                  gap: 0rem;
                  font-size:0.9rem;
                  text-align:center;
                 }                
                
               }
             }
             
             div{
              margin-left: auto;
              width: 100%;
              height: auto;
              display: flex; 
               
               img{
                 width:100%;
                 height:auto;
               }
             }
           }
           .content-container{
             width:100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 2rem;
            
            .simple-section{
              display:flex;
              flex-direction:column;
              gap:1.25rem;
              
              h4{
                font-size:36px;
                line-height:44px;
                margin-bottom:0px;
                
              }
            }
             
           }
        }
        
        .content-with-image{
          padding: $section-padding $border-padding-on-side;

          &.split-title {
            .content-with-image-inner-container {
              .text-column {
                .text-container {
                  h4 {
                    &.simple-section-subtitle {
                      font-size: 1.2rem;
                      line-height: 1.5;
                      order: 1;
                      margin-bottom: -10px;
                    }

                    &.simple-section-title {
                      order: 2;
                    }
                  }

                  .description-container {
                    order: 3;
                  }

                  .cta-small-container {
                    order: 4;
                  }
                }
              }
            }
          }

          .content-with-image-inner-container {
            max-width: 1200px;
            gap: 80px;
            align-items: center;

            .text-container {
              gap: 20px;

              & > * {
                display: none;
              }

              h4 {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                flex-direction: column;
                gap: 20px;

                p {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }

                ul {
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    font-weight: normal;
                    font-size: 1.2rem;
                    letter-spacing: 0;
                    line-height: 1.5;
                    margin: 0;

                    strong {
                      font-weight: bold;
                    }
                  }
                }
              }
            }

            .image-container {
              display: flex;
              align-items: center;

              .styled-section-image {
                width: 100%;
              }
            }
          }

          &.image-on-left {
            .content-with-image-inner-container {
              flex-direction: row;
            }
          }

          &.image-on-right {
            .content-with-image-inner-container {
              flex-direction: row-reverse;
            }
          }

          &.tablet-image {
          .content-with-image-inner-container {
            .image-container {
              .styled-section-image {
                .styled-section-image-inner {

                  .image-wrapper {
                    border-radius: 1px;
                  }
                }
              }
            }
          }
          }

          &.notebook-image {
            .content-with-image-inner-container {
              .image-container {
                max-width: unset;
                margin: unset;

                .styled-section-image {
                  .styled-section-image-inner {

                    .plus-sign {
                      width: 33px;
                      max-width: unset;

                      &.left-plus-sign {
                        left: calc(-35 / 695 * 100%);
                      }

                      &.right-plus-sign {
                        right: calc(-35 / 695 * 100%);
                      }

                      &::after {
                        top: calc(50% - 6px);
                        height: 12px;
                      }

                      &::before {
                        left: calc(50% - 6px);
                        width: 12px;
                      }
                    }

                    .image-wrapper {
                      &::before {
                        top: 2px;
                        bottom: -12px;
                        left: -60px;
                        right: -60px;
                      }

                      img {
                        border-radius: 0;
                      }
                    }
                  }
                }
              }
            }
          }

          &.mobile-image {
            .content-with-image-inner-container {
              .image-container {
                .styled-section-image {
                  max-width: 347px;
                  max-height: 591px;
                  min-width: 310px;

                  .styled-section-image-inner {
                    max-width: 347px;
                    max-height: 591px;

                    .image-wrapper {
                      width: 100%;
                      padding: 17px;

                      img {
                        width:100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        & > .simple-section {
          gap: 30px;
          padding: $section-padding $border-padding-on-side;

          & > * {
            max-width: 1200px;
            width:100%;

            &.cta-small-container {
              width: 100%;
            }

            p {
              font-size: 1.2rem;
              line-height: 1.5;
            }
          }

          .video-container {
            width: 100%;
            margin-inline: auto;
          }

          iframe {
            max-width: 1200px;
            margin: 0;
          }
        }

        .full-width-content-with-cta {
          margin: 0;
          padding: $section-padding $border-padding-on-side;
          display: flex;

          & ~ .full-width-content-with-cta {
            margin-top: 0;
          }

          .full-width-content-with-cta-inner {
            max-width: 1200px;
            margin: 0 auto;
            padding: $section-padding 75px;
            background: #0063BE;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 100%;

            & > section {
              display: flex;
              flex-direction: column;
              gap: 30px;

              h4 {
                color: #fff;
                text-align: center;
                font-size: 36px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 44px;
                margin: 0;
              }

              .description-container {
                margin: 0;

                p {
                  color: #fff;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                  font-weight: normal;
                  margin: 0;
                }

                sup {
                  color: $orange-1;
                }
              }
            }

            .call-to-action-container {
              margin: 0;
              padding: 0;
              display: flex;
              gap: 25px;
              flex-direction: row;

              .cta-container {
                margin: 0;
                padding: 0;
                width: 100%;
                background: transparent;

                .cta-button {
                  background: #fff;
                  box-shadow: none;
                  border-radius: 20px;
                  padding: 11px 30px 10px;
                  width: auto;
                  min-width: 225px;
                  margin-inline: auto;
                  cursor: pointer;

                  span {
                    color: $orange-1;
                    font-size: 1.2rem;
                    line-height: 1.5;
                    letter-spacing: 0;
                    font-weight: normal;
                    text-transform: uppercase;
                    display: block;
                  }
                }

                &:last-child {
                  .cta-button {
                    background: $orange-1;

                    span {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
        
      .cta-double-container{
        padding:6rem Max(40px, unquote("(100vw - 1200px) / 2"));
        
        .cta-container{
          color: #fff;
          gap: 0rem;
          border-radius: 20px;
          padding:4rem;
          
          .cta-title{
            color:#ffffff;
          }
        }
      }        

        .content-with-multiple-images {
          padding: $section-padding $border-padding-on-side;
          gap: 25px;
          max-width: 1200px;
          flex-direction: row;
          align-items: center;

          &.image-in-right {
            flex-direction: row-reverse;
          }

          & > * {
            width: 100%;
          }

          .multi-image-container {
            gap: 50px;
            border-radius: 20px;

            .simple-section {
              div {
                &[data-rmiz-wrap="visible"] {
                  flex-direction: row;
                  gap: 80px;

                  img {
                    max-width: 241px;
                    width: 100%;
                  }

                  .single-card-image-text {
                    gap: 20px;
                    width: 100%;

                    .single-card-image-title {
                      font-size: 24px;
                    }

                    .single-card-image-alt {
                      font-size: 1.2rem;
                      line-height: 1.5;
                    }
                  }
                }
              }
            }
          }

          & > .simple-section {
            .description-container {
              max-width: 95%;

              p {
                font-size: 1.2rem;
                line-height: 1.5;
              }
            }
          }
        }

        #references {
          padding: $section-padding $border-padding-on-side;

          & > .MuiAccordion-root {
            max-width: 1200px;
            width: 100%;

            .MuiButtonBase-root {
              align-items: center;

              .MuiIconButton-root {
                display: inline-flex;
                margin-top: 0;
              }
            }

            h5 {
              font-size: 1.2rem;
              line-height: 1.5;
            }

            .MuiIconButton-root {
              margin-top: 10px;
            }

            ul {
              li {
                font-size: 1.2rem;
                line-height: 25px;

                a {
                  color: $orange-1;
                }
              }
            }
          }
        }

        .attachment-section {
          padding: $section-padding $border-padding-on-side;
          margin: 0;

          .attachment-section-inner-container {
            max-width: 1200px;

            & > * {
              max-width: 935px;
              width: 100%;
            }

            .attachment-title {
              h4 {
                font-size: 36px;
                line-height: 44px;
              }
            }

            .attachment-body {
              & > div {
                p,
                span,
                strong,
                li {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }
              }
            }

            .attachment-cards-wrapper {
              margin: 30px auto 0;
              flex-wrap: wrap;
              flex-direction: row;
              justify-content: center;
              max-width: 1200px;

              .card-wrapper {
                max-width: calc(100% / 3 - 2rem * 2 / 3);
                padding: 2rem;
                width: 100%;

                .card {
                  height: 100%;

                  .link {
                    display: flex;
                    flex-direction: column;
                    gap: 25px;
                    max-height: unset;
                    max-width: unset;

                    img {
                      width: 100%;
                      height: auto;
                      border-radius: unset;
                    }

                    .content {
                      margin-top: 10px;

                      .content-title {
                        font-size: 1.4rem;
                        line-height: 1.3;
                      }
                    }

                    .read-more {
                      padding-top: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }


      #references {
        padding: $section-padding $border-padding-on-side $section-padding;
        max-width: unset;

        & > .MuiAccordion-root {
          max-width: 1200px;

          .MuiButtonBase-root {
            align-items: center;

            .MuiIconButton-root {
              display: inline-flex;
              margin-top: 0;
            }
          }

          h5 {
            font-size: 1.2rem;
            line-height: 1.5;
          }

          .MuiIconButton-root {
            margin-top: 10px;
          }

          ul {
            li {
              font-size: 1.2rem;
              line-height: 25px;

              a {
                color: $orange-1;
              }
            }
          }
        }
      }

      &.content-page,
      &.main-page {
        padding-bottom: 0;

        & > * {
          &:last-child {
            .cards-wrapper {
              margin-bottom: 0;
            }
          }
        }

        section {
          &.section-0 {
            margin-top: $section-padding;
          }
        }

      }

      &.main-page {
        margin-bottom: 0;
        max-width: 100%;
        margin-inline: 0;

        .carousel-container {
          padding-inline: $border-padding-on-side;
        }
      }

      .gallery-items-outer-container {
        margin: 0;
        padding: $section-padding $border-padding-on-side;
        width:100%;

        .gallery-items-inner-container {
          margin-inline: auto;
          gap: 20px;

          .section-article-container {

            .section-article-inner-container {
              .section-article-title {
                .section-article-string-2 {
                  font-size: 36px;
                  line-height: 44px;

                }
              }
            }
          }

          .symptom-gallery-container {
            gap: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .symptom-gallery-item {
              .no-link-image {
                gap: 30px;
                flex-direction:row;

                img {
                  display: block;
                  width: 100%;
                  height: auto;
                  max-width: 130px;
                  max-height: 130px;
                  margin: 0 0 auto;
                }

                .item-title {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .single-card-gallery-outer-container {
        a {
          sup,sub {
            font-size: 1.2rem;
            line-height: 1;
          }
        }
      }

      .simple-section {
        .simple-section-title {
          a {
            sup,sub {
              font-size: 1.2rem;
              line-height: 1;
            }
          }
        }

        .description-container {
          gap: 30px;

          ul {
            li {
              line-height: 1.5;
              font-size: 1.2rem;
            }
          }
        }
      }
    }

    &.dynamic-crohn\'s-disease-page,
    &.dynamic-enfermedad-de-crohn-page,
    &.dynamic-morbus-crohn-page,
    &.dynamic-ziekte-van-chron-page{
      .lower-section {
        &.content-page {
          .full-width-content-with-cta {
            .full-width-content-with-cta-inner {
              .call-to-action-container {
                .cta-container {
                  .cta-button {
                    width: auto;
                    margin-inline: auto;
                    min-width: 225px;
                  }
                }
              }
            }
          }

          .single-card-gallery-outer-container {
            .single-card-gallery-container {
              gap: 3rem;

              & > section {
                .cards-wrapper {
                  .card-wrapper {
                    padding-inline: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-nutrition-page,
    &.dyanmic-nutrición-page,
    &.dyanmic-ernährung-page,
    &.dyanmic-voeding-page{
      max-width: 100%;
      width: 100%;

      .lower-section {
        &.main-page {
          max-width: 100%;
          margin: 0;
          width: 100%;

          .carousel-container {
            padding: $section-padding $border-padding-on-side;
            margin: 0;

            .cards-wrapper {
              max-width: 1200px;
              margin-left: auto;
              margin-right: auto;
              flex-wrap: wrap;
            }
          }
        }
      }
    }

    &.dynamic-wellbeing-and-support-page,
    &.dynamic-your-wellbeing-page,
    &.dynamic-uw-welzijn-page,
    &.dynamic-bienestar-y-apoyo-page,
    &.dynamic-su-bienestar-page,
    &.dynamic-valbefinnande-och-support-page{
      .lower-section {
        &.main-page {
          .hero-section-text {
            & ~ .simple-section {
              gap: 20px;
              max-width: 935px;
              padding: $section-padding 0 0;
              margin-right: auto;
              margin-left: auto;

              h4 {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                p,
                span {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }
              }
            }
          }

          .carousel-container {
            background: transparent;
            margin-top: $section-padding;
            margin-bottom: $section-padding;

            .cards-wrapper {
              max-width: 1200px;
              margin: 0 auto;
              flex-wrap: wrap;
              padding-inline: 0px;

              .card-wrapper {
                background: #fff;
                max-width: calc(100% / 3 - 2rem * 2 / 3);

                .card {
                  a {
                    .content {
                      background: transparent;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }


    &.dynamic-autoimmune-diets-page,
    &.dynamic-IBD-diets-page {
      .lower-section {
        &.content-page {

          & > .simple-section {
            padding: $section-padding $border-padding-on-side;
            max-width: unset;

            & > * {
              margin-inline: auto;
              width: 100%;

              &.simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }

              &.description-container {
                display: flex;
                flex-direction: column;
                gap: 30px;

                p,
                span,
                li,
                strong,
                a {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0;
                }

                a {
                  color: $orange-1;
                }

                strong {
                  font-weight: bold;
                }
              }
            }
          }

          .full-width-content-with-cta {
            padding: $section-padding $border-padding-on-side;
            background: #fff !important;

            .full-width-content-with-cta-inner {
              .call-to-action-container {
                .cta-container {
                  .cta-button {
                    width: unset;
                    min-width: 225px;
                  }
                }
              }
            }
          }

          .content-with-simple-image {
            flex-direction: row;
            display: flex;
            gap: 90px;
            align-items: center;
            padding: $section-padding $border-padding-on-side;

            &.image-on-right {
              flex-direction: row-reverse;
            }

            .content-container {
              gap: 60px;

              .simple-section {
                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }

          .simple-section-with-multiple-title-and-body {
            background: #fff;

            & + .full-width-content-with-cta {
              background: transparent;
              padding-top: 0;
              padding-bottom: $section-padding;
            }
          }

          .text-top-symptom-carousel-bottom {
            .text-top-symptom-carousel-bottom-inner {
              & > section {
                .symptom-gallery-container {
                  .symptom-gallery-item {
                    .no-link-image {
                      img {
                        padding: 0;
                      }
                    }
                  }
                }
              }
            }
          }

          .content-with-image {
            .content-with-image-inner-container {
              align-items: center;
            }
          }

          .content-top-multi-boxes-bottom-outer {
            padding-bottom: 40px;

            .content-top-multi-boxes-bottom-inner {
              .box-wrapper {
                & > .simple-section {
                  justify-content: space-between;

                  .description-container {
                    max-height: 300px;
                  }

                  .simple-section-title {
                    position: relative;
                    max-height: 90px;
                    height: 100%;

                    &::after {
                      content: "";
                      height: 16px;
                      width: 16px;
                      background: #0063BE;
                      position: absolute;
                      border-radius: 3px;
                      left: 40px;
                      bottom: -66px;
                    }

                    &::before {
                      content: "";
                      height: auto;
                      width: 4px;
                      background: #0063BE;
                      position: absolute;
                      border-radius: 3px;
                      left: 46px;
                      bottom: -66px;
                      top: 94px;
                    }
                  }

                  &:nth-child(even) {
                    .simple-section-title {
                      &::after {
                        background: #31B7CF;
                      }

                      &::before {
                        background: #31B7CF;
                        top: 30px;
                      }
                    }
                  }
                }
              }
            }

            & + .content-with-image {
              padding-top: 0;
              background: #fff;

              .content-with-image-inner-container {
                .text-column {
                  .text-container {
                    .simple-section-title {
                      display: none;
                    }
                  }
                }
              }

              & + .content-with-image {
                background: transparent;

                & + .content-with-simple-image {
                  background: #fff;

                  & + .content-with-image {
                    background: transparent;

                    & + .content-with-image {
                      background: #fff;
                    }
                  }
                }
              }
            }
          }

          .content-with-pros-and-cons-boxes-outer {
            padding-bottom: 60px;
            background: transparent;

            .content-with-pros-and-cons-boxes-inner {
              .box-wrapper,
              .row-wrapper {
                .pros-cons-box {
                  .description-container {
                    background: #fff;
                  }
                }
              }
            }

            & + .content-with-pros-and-cons-boxes-outer {
              padding-top: 0;
              background: transparent;
              padding-bottom: 60px;

              & + .simple-section {
                padding-top: 0;
                background: transparent;
              }
            }
          }

          #references {
            background: transparent;
          }

          /*.content-with-multiple-images {
            max-width: 100%;
            padding-inline: $border-padding-on-side;
          }*/
        }
      }
    }

    &.dynamic-the-microbiome-page,
    &.dynamic-the-microbiome-in-IBD-page,
    &.dynamic-het-microbioom-page,
    &.dynamic-mikrobiom-kod-upalne-bolesti-crijeva-page,
    &.dynamic-ditt-mikrobiom-med-IBD-page,{
      .lower-section {
        &.content-page {
          & > * {
            &:not(.hero-section-text) {
              padding: $section-padding $border-padding-on-side;
            }
          }

          .content-with-simple-image {
            padding: $section-padding $border-padding-on-side;
            gap: 80px;
            flex-direction: row;
            align-items: center;

            &.image-on-right {
            }

            .simple-image-container {
              [data-rmiz-wrap="visible"] {
                overflow-x: hidden;
                max-width: 100%;
                display: flex;
                flex-direction: column-reverse;
                gap: 10px;
                border-radius: 20px;


                .single-card-image-text {
                  display: flex;
                  width: 100%;
                  padding-inline: 25px;

                  .single-card-image-title {
                    font-size: 1.2rem;
                    line-height: 15px;
                    margin-inline: auto;
                    width: 100%;
                    text-align: center;
                  }
                }

                img {
                  max-height: unset;
                  width: 100%;
                  height: auto;
                }
              }
            }

            .content-container {
              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }

            & + .content-with-image {
              & + .content-with-simple-image {
                .simple-image-container {
                  [data-rmiz-wrap="visible"] {
                    background: transparent;
                  }
                }
              }
            }
          }

          .single-card-outer-container {
            margin: 0;
            padding-bottom: 60px;

            .single-card-container {
              padding: 0;

              .single-card-inner-container {
                gap: 60px;
                flex-direction: column;

                [data-rmiz-wrap="visible"] {
                  gap: 10px;
                  flex-direction: column-reverse;
                  overflow-x: hidden;
                  max-width: 100%;
                  position: relative;
                  padding: 40px;
                  border-radius: 20px;
                  background: #fff;
                  align-items: center;

                  img {
                    border-radius: unset;
                    max-height: unset;
                    height: auto;
                    width: 100%;
                    max-width: unset;
                  }
                }

                .single-card-text-container {
                  column-gap: 40px;
                  row-gap: 20px;
                  max-width: unset;
                  flex-direction: row;
                  flex-wrap: wrap;

                  .single-card-title {
                    font-size: 36px;
                    line-height: 44px;
                    margin: 0 !important;
                    max-width: 100%;
                    padding-right: calc(50% + 20px);
                  }

                  .single-card-description {
                    gap: 30px;
                    flex-direction: column;
                    max-width: calc(50% - 20px);

                    p {
                      font-size: 1.2rem;
                      line-height: 1.5;
                      width: 100%;
                    }

                    &:nth-of-type(1) {
                      margin-right: 10px;
                    }

                    &:last-child {
                      margin-left: 10px;
                    }
                  }
                }
              }
            }

            & + .content-with-simple-image {
              padding-top: 0;
              background: transparent;

              .simple-image-container {
                [data-rmiz-wrap="visible"] {
                  background: #fff;
                  border-radius: 20px;

                  img {
                    margin-inline: 25px;
                    margin-bottom: 10px;
                    max-width: calc(100% - 50px);
                    border-radius: unset;
                  }
                }
              }

              & + .content-with-image {
                background: #fff;

                & + .content-with-simple-image {
                  background: transparent;
                }
              }
            }
          }

          .simple-section-with-multiple-body {
            padding: $section-padding $border-padding-on-side;
            background: #fff;

            .simple-section {
              gap: 40px;

              [data-rmiz-wrap="visible"] {
                overflow-x: hidden;
                max-width: unset;
                width: 100%;
                align-items: center;

                img {
                  max-height: unset;
                  width: 100%;
                  height: auto;
                }
              }

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
                width: 100%;
                margin-inline: auto;
              }

              .description-container {
                gap: 15px;
                width: 100%;
                margin-inline: auto;

                p,
                span {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }

                ul {
                  li {
                    font-size: 28px;
                    line-height: 32px;
                  }
                }
              }

              &:last-child {
                [data-rmiz-wrap="visible"] {
                  display: flex;
                  order: 2;
                }

                .description-container {
                  order: 1;
                }
              }

              &:nth-child(1) {
                [data-rmiz-wrap="visible"] {
                  display: none;
                }
              }
            }
          }

          #references {
            background: transparent;
          }
        }
      }
    }

    &.dynamic-the-microbiome-in-IBD-page {
      .lower-section {
        &.content-page {
          .content-with-simple-image {
            padding: $section-padding $border-padding-on-side;
            display: flex;
            flex-direction: row;
            gap: 80px;

            &.image-on-right {
              flex-direction: row-reverse;
            }

            .simple-image-container {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              .simple-section-title {
                display: none;
              }

              [data-rmiz-wrap="visible"] {
                background: #F4F8FA;
                border-radius: 20px;

                img {
                  display: block;
                  height: auto;
                  width: 100%;
                }
              }
            }

            .content-container {
              gap: 50px;
              width: 100%;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }

          .full-width-content-with-cta {
            .full-width-content-with-cta-inner {
              .simple-section {
                .simple-section-title {
                  text-align: center;
                }
              }

              .call-to-action-container {
                .cta-container {
                  .cta-button {
                    width: auto;
                    min-width: 225px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-my-idacio-page,
    &.dynamic-my-tyenne-page,
    &.dynamic-my-otulfi-page,     
    &.dynamic-hcp-access-page,
    &.dynamic-kabicare-page,
    &.dynamic-mein-medikament-page,
    &.dynamic-mijn-idacio-page,
    &.dynamic-mi-idacio-page,
    &.dynamic-mi-tyenne-page,
    &.dynamic-acceso-para-profesionales-de-la-salud-page,
    &.dynamic-toegang-voor-zorgverleners-page,
    &.dynamic-hcp-zugang-page,
    &.dynamic-moj-tyenne-page,
    &.dynamic-moj-idacio-page,
    &.dynamic-min-tyenne-page,
    &.dynamic-min-idacio-page,
    &.dynamic-sjukvardspersonal-page{
      .lower-section {
        &.main-page {
          .hero-section-text {
            & + .simple-section {
              padding: $section-padding 0;
              max-width: 1200px;
              margin-inline: auto;
            }
          }

          .carousel-container {
            .cards-wrapper {
              flex-wrap: wrap;

              .card-wrapper {
                background: #fff;

                .card {
                  a {
                    gap: 35px;

                    .content {
                      gap: 15px;
                      background: transparent;

                      .content-description {
                        margin: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-my-tyenne-page,
    &.dynamic-my-idacio-page,
    &.dynamic-my-otulfi-page, 
    &.dynamic-mein-medikament-page,
    &.dynamic-mijn-idacio-page,
    &.dynamic-mi-idacio-page,
    &.dynamic-mi-tyenne-page,
    &.dynamic-moj-tyenne-page,
    &.dynamic-moj-idacio-page,
    &.dynamic-min-tyenne-page,
    &.dynamic-min-idacio-page{
      .lower-section {
        &.main-page {
          .carousel-container {
            padding-top: $section-padding;
            padding-bottom: $section-padding;

            .cards-wrapper {
              .card-wrapper {
                background: #F4F8FA;
              }
            }
          }
        }
      }
    }

    &.dynamic-idacio-page,
    &.dynamic-tyenne-page,
    &.dynamic-otulfi-page,
    &.dynamic-mein-medikament-page,
    &.dynamic-mijn-idacio-page,
    &.dynamic-mi-idacio-page,
    &.dynamic-mi-tyenne-page{
      .lower-section {
        &.main-page {
          .carousel-container {
            .cards-wrapper {
              .card-wrapper {
                background: #F4F8FA;
              }
            }
          }

          .two-column-body {
            display: flex;

            section {
              &:nth-child(2) {
                margin-top: 64px;
              }
            }
          }

          h5, h4 {
            text-align: left;
          }
        }
      }
    }

    &.dynamic-hcp-access-page,
    &.dynamic-acceso-para-profesionales-de-la-salud-page,
    &.dynamic-toegang-voor-zorgverleners-page,
    &.dynamic-hcp-zugang-page,
    &.dynamic-sjukvardspersonal-page{
      .lower-section {
        &.main-page {
          .section-article-container {
            max-width: 1200px;
            margin-inline: auto;
            padding: $section-padding 0 50px;

            .section-article-inner-container {
              gap: 20px;

              .section-article-title {
                max-width: 100%;

                .section-article-string-1 {
                  font-size: 36px;
                  line-height: 44px;
                }
              }

              .section-article-description {
                gap: 20px;

                p {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }

                ul {
                  li {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }

          & > .simple-section {
            gap: 20px;

            .description-container {
              gap: 30px;

              p,
              span {
                font-size: 1.2rem;
                line-height: 1.5;
              }

              ul {
                gap: 20px;
              }
            }
          }

          .carousel-container {
            margin-top:0px;
            margin-bottom:0px;
            padding-top: $section-padding;
            padding-bottom: $section-padding;

            .cards-wrapper {
              .card-wrapper {
                background: #F4F8FA;
              }
            }
          }
        }
      }
    }

    &.dynamic-kabicare-page {
      .lower-section {
        &.main-page {
          margin-bottom: 0;

          .hero-section-text {
            & + .simple-section {
              h4 {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                gap: 20px;

                p,
                li {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }
              }
            }
          }

          .carousel-container {
            padding-bottom: $section-padding;
            padding-top: $section-padding;

            & + .simple-section {
              background: transparent;
              margin-inline: auto;
              padding: $section-padding 0 0;

              .simple-section-subtitle {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                p {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }
              }
            }
          }

          .content-with-image {
            padding: $section-padding $border-padding-on-side;
            margin: 0;
            max-width: 100%;

            .content-with-image-inner-container {
              flex-direction: row;
              gap: 80px;
              max-width: 1200px;
              margin-inline: auto;
              align-items: center;

              .text-column {
                width: 100%;

                .text-container {
                  gap: 20px;

                  .simple-section-title {
                    font-size: 36px;
                    line-height: 44px;
                  }

                  .description-container {
                    gap: 30px;

                    p,
                    span {
                      font-size: 1.2rem;
                      line-height: 1.5;
                    }
                  }
                }
              }

              .image-container {
                width: 100%;
              }
            }
          }
        }
      }
    }

    &.dynamic-healthy-living-page {
      .lower-section {
        &.main-page {
          max-width: 100%;

          .carousel-container {
            .cards-wrapper {
              max-width: 1200px;
              margin-inline: auto;

              .card-wrapper {
                width: 100%;
                max-width: calc(100% / 3 - 2rem * 2 / 3);
              }
            }
          }
        }
      }
    }

    &.dynamic-mindfulness-and-stress-page,
    &.dynamic-usredotočena-svjesnost-i-stres-page{
      .lower-section {
        &.content-page {
          .hero-section-text {
            & + .simple-section {
              max-width: 935px;
              margin-inline: auto;
              gap: 20px;

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }
            }
          }

          .text-left-symptom-carousel-right {
            .text-left-symptom-carousel-right-inner {
              section {
                .symptom-gallery-container {
                  .symptom-gallery-item {
                    a {
                      .item-title {
                        font-size: 1.2rem;
                        line-height: 1.5;
                      }
                    }

                    .no-link-image {
                      .item-title {
                        font-size: 1.2rem;
                        line-height: 1.5;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-relationships-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            & + .simple-section {
              max-width: 935px;
              margin-inline: auto;

              .simple-section-title {
                line-height: 44px;
                font-size: 36px;
              }

              .description-container {
                gap: 30px;
                display: flex;
                flex-direction: column;
              }
            }
          }

          .full-width-content-with-cta {
            .full-width-content-with-cta-inner {
              .call-to-action-container {
                .cta-container {
                  .cta-button {
                    max-width: 445px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-intimacy-page {
      .lower-section {
        &.content-page {
          flex-direction: row;
          flex-wrap: wrap;

          .hero-section-text {
            width: 100%;

            & + .simple-section {
              padding-inline: 0;
              max-width: 935px;
              margin-inline: auto;
              gap: 20px;

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                p {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  color: #003565;
                }
              }
            }
          }

          .content-with-simple-image {
            flex-direction: row-reverse;
            padding: $section-padding $border-padding-on-side;
            gap: 80px;
            width: 100%;

            & > * {
              max-width: 660px;
              width: 100%;
            }

            .simple-image-container {
              margin-right: auto;

              [data-rmiz-wrap="visible"] {
                margin: 0 !important;
                pointer-events: none;
                width: 100%;

                img {
                  border-radius: 20px;
                }
              }
            }

            .content-container {
              margin-left: auto;
              gap: 50px;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }

            & + .content-with-image {
              background: #fff;
              padding: $section-padding 40px 50px;
              width: 100%;

              .content-with-image-inner-container {
                gap: 80px;

                .image-container {
                  max-width: 660px;
                  width: 100%;
                }

                .text-column {
                  max-width: 660px;
                  width: 100%;

                  .text-container {
                    .description-container {
                      gap: 30px;

                      p {
                        font-size: 1.2rem;
                        line-height: 1.5;
                      }

                      ul {
                        gap: 15px;

                      }
                    }
                  }
                }
              }
            }
          }

          .full-width-content-with-cta {
            width: 50%;
            background: #fff;
            padding: 0 calc(25px / 2) 50px 40px;


            & + .full-width-content-with-cta {
              padding-left: calc(25px / 2);
              padding-right: 40px;

              .full-width-content-with-cta-inner {
                margin-left: 0;
                margin-right: auto;
              }
            }

            .full-width-content-with-cta-inner {
              max-width: 695px;
              padding: $section-padding 60px;
              justify-content: space-between;
              margin-left: auto;
              margin-right: 0;

              .simple-section {
                .simple-section-title {
                  font-size: 24px;
                  line-height: 29px;
                }
              }

              .call-to-action-container {
                .cta-container {
                  width: unset;
                  margin-inline: auto;
                  min-width: 215px;
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-facing-challenges-page {
      .lower-section {
        &.content-page {
          & > .simple-section {
            width: 100%;
            padding-inline: 0;
            gaP: 20px;

            & > * {
              max-width: 935px;
              margin-inline: auto;
            }

            .simple-section-title {
              font-size: 36px;
              line-height: 44px;
            }

            .description-container {
              gap: 30px;

              p {
                font-size: 1.2rem;
                line-height: 1.5;
              }
            }
          }

          .full-width-content-with-cta {
            padding-inline: 40px;

            .full-width-content-with-cta-inner {
              .simple-section {
                h4 {
                  text-align: center;
                  width: 100%;
                }
              }

              .call-to-action-container {
                .cta-container {
                  min-width: 215px;
                  width: unset;
                  margin-inline: auto;
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-support-for-parents-and-carers-page {
      .lower-section {
        &.content-page {
          & > .simple-section {
            gap: 20px;
            padding-inline: 0;
            margin-inline: auto;

            .simple-section-title {
              font-size: 36px;
              line-height: 44px;
            }

            .description-container {
              gap: 30px;

              p {
                font-size: 1.2rem;
                line-height: 1.5;
              }
            }
          }

          .text-left-symptom-carousel-right {
            .text-left-symptom-carousel-right-inner {
              gap: 160px;

              .left-side-container {
                max-width: 305px;
                width: 100%;

                .simple-section {
                  .description-container {
                    ul {
                      li {
                        line-height: 1.5;

                        a {
                          font-size: 1.2rem;
                          line-height: 1.5;
                        }
                      }
                    }
                  }
                }
              }

              section {
                max-width: 935px;
                width: 100%;

                .symptom-gallery-container {
                  gap: 30px;

                  .symptom-gallery-item {
                    .no-link-image {
                      .item-title {
                        font-size: 1.2rem;
                        line-height: 1.5;
                      }
                    }
                  }
                }
              }
            }
          }

          .full-width-content-with-cta {
            .full-width-content-with-cta-inner {
              .simple-section {
                .simple-section-title {
                  text-align: center;
                  font-size: 36px;
                  line-height: 44px;
                }
              }

              .call-to-action-container {
                .cta-container {
                  width: unset;
                  min-width: 215px;
                  margin-inline: auto;
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-support-from-the-community-page {
      .lower-section {
        &.content-page {
          .content-with-simple-image {
            padding: $section-padding $border-padding-on-side;
            margin: 0;
            width: 100%;
            display: flex;
            gap: 80px;

            &.image-on-right {
              flex-direction: row-reverse;

              .simple-image-container {
                margin-left: 0;
                margin-right: auto;
              }

              .content-container {
                margin-left: auto;
                margin-right: 0;
              }
            }

            .simple-image-container {
              margin-left: auto;
              display: block;
              max-width: 660px;
              width: 100%;

              .simple-section-title {
                display: none;
              }
            }

            .content-container {
              max-width: 660px;
              width: 100%;
              margin-right: auto;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  p,
                  span,
                  a {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-supporting-others-page {
      .lower-section {
        &.content-page {
          .simple-section {
            max-width: 100%;
            width: 100%;

            .simple-section-title {
              max-width: 935px;
            }

            .description-container {
              gap: 50px;

              & > * {
                max-width: 935px;
                margin-inline: auto;
                width: 100%;
              }

              .video-container {
                max-width: 1200px;
                width: 100%;
              }

              span,
              a {
                font-size: 1.2rem;
                line-height: 1.5;
              }
            }
          }
        }
      }
    }

    &.dynamic-talking-to-your-health-team-page,
    &.dynamic-hablar-con-su-equipo-asistencial-page,
    &.dynamic-overleg-met-uw-gezondheidsteam-page,
    &.dynamic-gespräch-mit-ihrem-gesundheitsteam-page,
    &.dynamic-razgovor-s-vašim-medicinskim-timom-page{
      .lower-section {
        &.content-page {
          .content-with-simple-image {
            padding: $section-padding $border-padding-on-side;
            gap: 8rem;
            align-items: center;
            flex-direction:row;

            &.image-on-right {
              flex-direction: row-reverse;

              .simple-image-container {
                margin-left: 0;
                margin-right: auto;
              }

              .content-container {
                margin-left: 0;
                margin-right: 0;
              }
            }

            .simple-image-container {
              margin-left: auto;
              margin-right: 0;
              width:40%;
              
              .simple-image{
                margin-left:auto;  
              }

              .simple-section-title {
                
              }

              [data-rmiz-wrap="visible"] {
                max-height: 175px;
                max-width: 175px;
                min-width: 175px;
                width: 100%;

                img {
                  max-height: 175px;
                  max-width: 175px;
                  width: 100%;
                  height: auto;
                  display: block;
                }
              }
            }

            .content-container {
              margin-left: 0;
              margin-right: 0;
              max-width: 935px;
              width: 100%;

              .simple-section {
                .simple-section-title {
                  display: none;
                }

                .description-container {
                  max-width: unset;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }

                  ul {
                    li {
                      * {
                        font-size: 1.2rem;
                        line-height: 1.5;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-goal-setting-page {
      .lower-section {
        &.content-page {
          flex-direction: row;
          flex-wrap: wrap;

          .hero-section-text {
            width: 100%;
          }

          .simple-section {
            width: 100%;
            padding-bottom: $section-padding;
            max-width: 1200px;

            .simple-section-title {
              max-width: 935px;
              margin-inline: auto;
            }

            .description-container {
              gap: 20px;
              max-width: 935px;
              margin-inline: auto;

              ul {
                li {
                  * {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }

          .full-width-content-with-cta {
            background: transparent;
            width: 100%;
            padding: 0 12.5px 50px $border-padding-on-side;

            .full-width-content-with-cta-inner {
              padding: $section-padding 60px;
              margin: 0;

              .simple-section {
                padding: 0;

                .simple-section-title {
                  font-size: 24px;
                  line-height: 29px;
                }
              }

              .call-to-action-container {
                .cta-container {
                  .cta-button {
                    width: unset;
                    min-width: 215px;
                  }
                }
              }
            }

            & + .full-width-content-with-cta {
              padding: 0 $border-padding-on-side 50px 12.5px;

              .full-width-content-with-cta-inner {
                margin-left: 0;
                margin-right: auto;
              }
            }
          }
        }
      }
    }

    &.dynamic-coaching-tool-page,
    &.dynamic-coaching-instrumente-page,
    &.dynamic-coaching-tools-page,
    &.dynamic-herramientas-de-coaching-page,
    &.dynamic-coaching-verktyg-page{
      .lower-section {
        background: #F4F8FA;
        max-width: 100%;
        width: 100%;

        & > * {
          max-width: unset;
          width: 100%;

          &:nth-child(odd) {
            background: #fff;
          }

          &:nth-child(even) {
            background: transparent;
          }
        }

        .coaching-tool-hero-section {
          padding: 25px 25px 50px;
          margin: 0;

          .simple-section-title {
            color: #00C2DF;
            font-size: 30px;
            line-height: 36px;
            letter-spacing: 0;
            font-weight: bold;
            margin: 0;
          }
        }

        .section-article-container {
          padding: $section-padding $border-padding-on-side;

          h4 {
            text-align: left;
          }

          .section-article-inner-container {
            gap: 20px;
            margin-inline: auto;

            .section-article-title {
              gap: 10px;
              max-width: unset;

              .section-article-string-1 {
                font-size: 1.2rem;
                line-height: 1.5;
              }

              .section-article-string-2 {
                font-size: 36px;
                line-height: 44px;
              }
            }

            .section-article-description {
              p {
                font-size: 1.2rem;
                line-height: 1.5;
              }
            }
          }
        }

        .fullwidth-transparent {
          padding: $section-padding $border-padding-on-side;
          margin: 0;

          .single-card-gallery-container {
            gap: 50px;
            max-width: 1200px;
            margin-inline: auto;

            .single-card-text-container {
              gap: 20px;

              .single-card-title {
                margin: 0 !important;
                font-size: 36px;
                line-height: 44px;
              }

              .single-card-description {
                font-size: 1.2rem;
                line-height: 1.5;
              }
            }

            & > section {
              .cards-wrapper {
                margin: 0;
                gap: 25px;

                .card-wrapper {
                  border-radius: 20px;

                  .card {
                    flex: 1 0 150px;

                    .link {
                      picture {
                        border-radius: 20px 0 0 20px;
                      }
                    }
                  }

                  h4 {
                    padding: 75px 27px 25px 15px;
                    font-size: 1rem;
                    line-height: 1.4;
                    font-weight: normal;
                    color: #003565;
                    font-style: italic;
                    position: relative;
                    text-align: left;

                    &::before {
                      font-size: 3rem;
                      line-height: 0;
                      top:3rem;
                      display:block;
                    }

                    &::after {
                      left: -30px;
                      width: 30px;
                    }
                  }
                }
              }
            }
          }

          & + .simple-section {
            padding: 0 $border-padding-on-side 50px;

            .description-container {
              max-width: 935px;
              margin-inline: auto;
              gap: 30px;

              p {
                font-size: 1.2rem;
                line-height: 1.5;
              }
            }

            & + .section-article-container {
              padding-bottom: $section-padding;

              & + .section-article-container {
                padding-bottom: 0;
                padding-inline: $border-padding-on-side;

                .column-container {
                  padding-bottom: $section-padding;

                  &::after {
                    left: 0;
                    right: 0;
                  }
                }
              }
            }
          }
        }

        .column-container {
          padding: $section-padding 40px;
          flex-direction: row;
          gap: 25px;
          background: #F4F8FA;

          &::after {
            left: Max(40px, unquote("calc((100vw - 1200px) / 2)"));
            right: Max(40px, unquote("calc((100vw - 1200px) / 2)"));
          }

          & > div {
            width: 100%;
            max-width: calc(1200px / 3 - 25px * 2 / 3);

            &:first-child {
              margin-left: auto;
            }

            &:last-child {
              margin-right: auto;
            }

            & > .MuiBox-root {
              flex-direction: column-reverse;
              gap: 25px;

              .text-container {
                .title {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }

                .subtitle {
                  font-size: 24px;
                  line-height: 29px;
                }

                .body-18 {
                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }

              img {
                display: block;
              }
            }
          }
        }
      }
    }

    &.dynamic-understanding-treatment-page,
    &.dynamic-understanding-otulfi-page,
    &.dynamic-understanding-tyenne-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            & + .simple-section {
              .simple-section-title {
                margin-top: 10px;
              }

              .simple-section-subtitle {
                font-size: 1.2rem;
                line-height: 1.5;
              }

              .cta-small-container {
                margin-top: 30px;

                a {
                  .cta-small {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }

              .description-container {
                gap: 30px;
                margin-top: 20px;
              }
            }
          }

          .content-with-simple-image {
            display: flex;
            padding: $section-padding $border-padding-on-side;
            flex-direction: row;
            gap: 130px;
            align-items: center;

            &.image-on-right {
            }

            .simple-image-container {
              margin-left: auto;
              margin-right: 0;
              max-width: 635px;
              width: 100%;

              [data-rmiz-wrap="visible"] {
                img {
                  display: block;
                  width: 100%;
                  height: auto;
                }

                .single-card-image-text {
                  width: 100%;

                  .single-card-image-title {
                    font-size: 1.2rem;
                    line-height: 20px;
                    text-align: center;
                  }
                }
              }
            }

            .content-container {
              margin-left: 0;
              margin-right: auto;
              max-width: 635px;
              width: 100%;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-eating-well-with-an-autoimmune-page {
      .lower-section {
        .content-top-multi-boxes-bottom-outer {
          .content-top-multi-boxes-bottom-inner {
            .box-wrapper {
              grid-gap: 100px;
              gap: 100px;
            }
          }
        }
      }
    }

    &.dynamic-eating-well-with-an-autoimmune-page,
    &.dynamic-vitamins-and-minerals-page,
    &.dynamic-vitaminas-y-minerales-page{
      .content-with-simple-image {
        display: flex;
      }
    }

    &.dynamic-vitamins-and-minerals-page,
    &.dynamic-vitaminas-y-minerales-page{
      .lower-section {
        .content-with-simple-image, &.image-on-left {

        }
      }

      .content-with-simple-image {
        display: flex;

        .single-card-image-text {
          position: relative;
          z-index: 1;
          .single-card-image-title {
            font-size: 36px!important;
            line-height:1.2em !important;
            sup,sub {
              font-size: 1.2rem;
              line-height: 1;
            }
          }
        }
      }
    }

    &.dynamic-eating-well-page,
    &.dynamic-eating-well-with-an-autoimmune-page,
    &.dynamic-eating-well-with-IBD-page,
    &.dynamic-eating-well-with-RA-page,
    &.dynamic-vitamins-and-minerals-page,
    &.dynamic-comer-bien-con-ar-page,
    &.dynamic-vitaminas-y-minerales-page,
    &.dynamic-zdrava-prehrana-kod-reumatoidnog-artritisa-page{
      .lower-section {
        &.content-page {
          .content-with-simple-image {
            padding: $section-padding $border-padding-on-side;
            gap: 5rem;

            &.image-on-right {
            }

            .simple-image-container {
              width: 100%;

              .single-card-image-text {
                width: 100%;

                .single-card-image-title {
                  font-size: 1.2rem;
                  line-height: 20px;
                  text-align: center;
                }
              }
            }

            .content-container {
              width: 100%;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    line-height: 1.5;
                    font-size: 1.2rem;
                  }
                }
              }
            }
          }

          .section-article-container {
            padding: $section-padding $border-padding-on-side;

            .section-article-inner-container {
              gap: 20px;
              max-width: 100%;

              .section-article-description {
                max-width: 100%;

                p,
                li,
                strong,
                span,
                a {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }

              }

              .section-article-title {
                max-width: 100%;
              }
            }
          }

          .full-width-content-with-cta {
            .full-width-content-with-cta-inner {
              .simple-section {
                .simple-section-title {
                  text-align: center;
                }
              }
            }

            & + .section-article-container {
              .section-article-inner-container {
                margin-inline: auto;
                width: 100%;

                .section-article-description {
                  & > div {
                    ul {
                      margin-top: 20px;

                      li {
                        span {
                          font-size: 28px;
                          line-height: 32px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .text-top-symptom-carousel-bottom {
            .text-top-symptom-carousel-bottom-inner {
              & > section {
                .symptom-gallery-container {
                  .symptom-gallery-item {
                    .no-link-image {
                      img {
                        padding: 0;
                      }

                      .item-title {
                        height: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-eating-well-with-IBD-page {
      .lower-section {
        div:nth-child(3) {
          @include hide-tablet-image-frame();
        }
      }
    }

    &.dynamic-staying-active-page,
    &.dynamic-staying-active-with-autoimmune-condition-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            & + .simple-section {
              max-width: 1200px;
              margin-inline: $border-padding-on-side;

              .simple-section-title,
              .cta-small-container {
                max-width: 935px;
                margin-inline: auto;
                width: 100%;
              }

              .description-container {
                & > p {
                  max-width: 935px;
                  margin-inline: auto;
                  width: 100%;
                }

                .video-container {
                  width: calc(100vw - (2 * #{$border-padding-on-side}));
                }
              }

              .cta-small-container {
                a {
                  .body-18 {
                    margin-right: 10px;
                  }

                  .arrow-container {
                    color: $orange-1;
                    transition: margin-left .5s ease;
                    letter-spacing: 0;
                  }

                  &:hover {
                    .arrow-container {
                      margin-left: .5em;
                    }
                  }
                }
              }
            }
          }

          .simple-section {
            .description-container {
              display: flex;
              flex-direction: column;
              gap: 25px;
            }

            [data-rmiz-wrap="visible"] {
              overflow-x: hidden;
            }
          }

          .text-left-symptom-carousel-right {
            padding-bottom: 20px;
            padding-inline: $border-padding-on-side;

            .text-left-symptom-carousel-right-inner {
              gap: 30px;

              .left-side-container {
                gap: 0;
                width: 100%;
                max-width: calc(455 / 1400 * 100%);
                padding-bottom: $section-padding;
                border-bottom: 2px solid rgba(0, 99, 190, .5);

                .simple-section {
                  .simple-section-title {
                    width: 100%;
                  }

                  &:nth-child(2) {
                    border-top: 2px solid rgba(0, 99, 190, .5);
                    padding-top: $section-padding;
                    margin-top: $section-padding;
                  }

                  .description-container {
                    ul {
                      gap: 20px;

                      li {
                        font-size: 1.2rem;
                        line-height: 1.5;
                      }
                    }
                  }
                }
              }

              section {
                display: flex;
                align-items: center;

                .symptom-gallery-container {
                  background: transparent !important;
                  gap: 50px;
                  justify-content: unset;
                  padding: 0 0 20px;

                  .symptom-gallery-item {
                    max-width: calc(25% - 50px * 3 / 4);

                    .no-link-image {
                      flex-direction: column;
                      gap: 20px;

                      .item-title {
                        font-size: 19px;
                        line-height: 1.5;
                        margin-inline: auto;
                        width: 100%;
                        text-align: center;
                      }

                      img {
                        margin: auto;
                        display: block;
                        max-width: 150px;
                        max-height: 100%;
                        width: 100%;
                        height: auto;
                      }
                    }
                  }
                }
              }
            }

            & ~ .text-left-symptom-carousel-right {
              .text-left-symptom-carousel-right-inner {
                gap: 30px;

                .left-side-container {
                  padding-top: 30px;
                  padding-bottom: 0;
                  border: unset;
                }

                & > section {
                  align-items: flex-start;

                  .symptom-gallery-container {
                    align-items: flex-start;
                    gap: 50px;
                    padding: 0;
                  }
                }
              }
            }
          }

          .content-with-image {
            &.notebook-image {
              & + .simple-section {
                padding-inline: $border-padding-on-side;
                gap: 0;
                background: #fff;
                position: relative;

                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: $border-padding-on-side;
                  right: $border-padding-on-side;
                  border-top: 2px solid rgba(0, 99, 190, .5);
                }

                .simple-section-title {
                  max-width: 935px;
                  margin-inline: auto;
                  width: 100%;
                  font-size: 36px;
                  line-height: 44px;
                  letter-spacing: 0;
                  margin-bottom: 20px;
                }

                .description-container {
                  max-width: 935px;
                  margin-inline: auto;
                  width: 100%;
                  margin-bottom: $section-padding;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                    letter-spacing: 0;
                  }
                }

                [data-rmiz-wrap="visible"] {
                  img {
                    max-width: 100%;
                    height: auto;
                    width: 100%;
                  }
                }
              }
            }
          }

          #references {
            padding: $section-padding $border-padding-on-side;
            background: transparent;
          }
        }
      }
    }

    &.dynamic-travel-page,
    &.dynamic-travelling-with-autoimmune-condition-page,
    &.dynamic-travel-with-RA-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            & + .simple-section {
              & + .content-with-image {
                .content-with-image-inner-container {
                  .image-container {
                    align-items: center;
                  }
                }
              }
            }
          }

          .content-with-image {
            padding-inline: $border-padding-on-side;

            &.mobile-image {
              .content-with-image-inner-container {
                .image-container {
                  display: flex;
                  align-items: center;

                  .styled-section-image {
                    max-width: 347px;

                    .styled-section-image-inner {
                      .image-wrapper {
                        height: unset;

                        & > img {
                          width: 456px;
                          height: auto;
                          margin-left: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }

            &.notebook-image {
              padding-bottom: $section-padding;

              .content-with-image-inner-container {
                .text-column {
                  gap: 50px;
                }

                .image-container {
                  display: flex;
                  align-items: flex-end;
                }
              }

              & + .simple-section {
                gap: 20px;
                padding-inline: $border-padding-on-side;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                  max-width: calc(50% - 40px);
                  width: 100%;
                }

                .description-container {
                  max-width: calc(50% - 40px);
                  width: 100%;
                  margin-left: 0;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }

            & + .simple-section {
              padding: $section-padding $border-padding-on-side;
            }
          }

          .full-width-content-with-cta {
            background: #fff;

            .full-width-content-with-cta-inner {
              .simple-section {
                .simple-section-title {
                  text-align: center;
                  max-width: 935px;
                  margin-inline: auto;
                }
              }

              .call-to-action-container {
                .cta-container {
                  .cta-button {
                    padding-inline: 46px;
                    width: unset;
                  }
                }
              }
            }
          }
        }
      }
    }


    &.dynamic-injecting-idacio-page{
      .lower-section {
        &.content-page {
          .content-with-simple-image {
            padding: $section-padding $border-padding-on-side;
            gap: 80px;
            justify-content: center;

            &.image-on-right {
              flex-direction: row-reverse;
            }

            &.image-on-left {
              flex-direction: row;
            }

            .simple-image-container {
              width: 100%;
              display: flex;

              [data-rmiz-wrap="visible"] {
                margin-inline: auto;
              }
            }

            .content-container {
              width: 100%;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }

          .content-with-image {
            padding-inline: $border-padding-on-side;
          }

          & > .simple-section {
            & > * {
              max-width: 935px;
              padding-inline: 0;
              margin-inline: auto;
              width: 100%;
            }

            [data-rmiz-wrap="visible"] {
              overflow-x: hidden;

              img {
                max-width: 935px;
              }
            }
          }
        }
      }
    }

    &.dynamic-self-admin-guide-page {
      .lower-section {
        &.content-page {
          & > .hero-section-text {
            & + .simple-section {
              .description-container {
                gap: 20px;
              }

              & ~ .simple-section {
                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                  max-width: 935px;
                  width: 100%;
                }

                .simple-section-subtitle {
                  font-size: 1.2rem;
                  line-height: 1.5;
                  max-width: 935px;
                  width: 100%;
                }

                .description-container {
                  align-items: center;

                  & > * {
                    &:not(.video-container) {
                      max-width: 935px;
                      width: 100%;
                      font-size: 1.2rem;
                      line-height: 1.5;
                    }
                  }
                }
              }
            }
          }

          .simple-section {
            .description-container {
              gap: 50px;

              .video-container {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }

    &.dynamic-help-and-supports-page,
    &.dynamic-help-and-support-page,
    &.dynamic-helps-and-supports-page{
      .lower-section {
        &.content-page {
          .hero-section-text {
            & ~ .simple-section {
              & > * {
                width: 100%;
                margin-inline: auto;
              }

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                gap: 20px;

                ul {
                  li {
                    p,
                    span,
                    strong {
                      font-size: 1.2rem;
                      line-height: 1.5;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-IBD-diets-page {
      .lower-section {
        &.content-page {
          flex-direction: row;
          flex-wrap: wrap;

          .hero-section-text {
            width: 100%;

            & + .simple-section {
              max-width: 100%;
              padding-inline: $border-padding-on-side;

              .simple-section-title,
              .simple-section-subtitle {
                margin-inline: auto;
              }

              .simple-section-subtitle {
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
                font-weight: normal;
                color: #003565;
              }

              .description-container {
                & > * {
                  &:not(.video-container) {
                    margin-inline: auto;
                  }
                }
              }
            }
          }

          .simple-section {
            .simple-section-title {
              font-size: 36px;
              line-height: 44px;
            }

            .description-container {
              p,
              ul,
              li,
              span,
              strong {
                font-size: 1.2rem;
                line-height: 1.5;
              }
            }
          }

          .content-with-multiple-images {
            padding: $section-padding $border-padding-on-side;
            max-width: 100%;

            row-gap: 40px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, auto);

            & > .simple-section {
              &:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;
              }

              &:nth-child(2) {
                grid-area: 2 / 1 / 3 / 2;
              }
            }

            .multi-image-container {
              grid-area: 1 / 2 / 3 / 3;
            }
          }

          .full-width-content-with-cta {
            width: 100vw;

            .full-width-content-with-cta-inner {
              .simple-section {
                .simple-section-title {
                  text-align: center;
                }
              }

              .call-to-action-container {
                .cta-button {
                  min-width: 215px;
                  width: unset;
                }
              }
            }

            & + .full-width-content-with-cta {
              padding: $section-padding $border-padding-on-side 50px 12.5px;
              background: #fff;
            }
          }

          #references {
            background: #F4F8FA;
          }

          .text-top-symptom-carousel-bottom {
            width: 100vw;
            padding: $section-padding;

            .text-top-symptom-carousel-bottom-inner {
              width: 100%;

              section {
                .symptom-gallery-container {
                  display: flex;
                  flex-direction: row;

                  .symptom-gallery-item {
                    width: fit-content;
                    min-height: 300px;



                    .no-link-image {
                      display: block;
                      max-width: fit-content;
                      text-align: center;

                      img {
                        transform: scale(1.5);
                        margin: 15px 0;
                      }

                      .item-title {
                        text-align: center;
                        width: 250px;
                        height: auto;
                        font-size: 1.2rem;
                        font-weight: 400;
                        color: black;

                        margin-top: 15px;
                        padding: 10px 5px;

                        background: $grey-1;

                        strong::after {
                          content: "\a";
                          white-space: pre;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-idacio-page,
    &.dynamic-tyenne-page,
    &.dynamic-otulfi-page{
      .lower-section {
        &.main-page {
          margin-bottom: 0;

          .hero-section-text {
            & + .simple-section {
              padding: $section-padding $border-padding-on-side;
              max-width: 100%;

              .simple-section-title {
                margin-inline: auto;
                width: 100%;
              }

              .description-container {
                gap: 30px;
                margin-inline: auto;
                width: 100%;
              }
            }
          }

          .content-with-simple-image {
            background: #fff;
            padding: $section-padding $border-padding-on-side;
            flex-direction: row-reverse;
            gap: 180px;
            max-width: 100%;

            .simple-image-container {
              width: 100%;
            }

            .content-container {
              width: 100%;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }

          .carousel-container {
            padding: $section-padding $border-padding-on-side;

            .cards-wrapper {
              .card-wrapper {
                background: #fff;
              }
            }
          }

          .text-top-symptom-carousel-bottom {
            .text-top-symptom-carousel-bottom-inner {
              & > section {
                .symptom-gallery-container {
                  .symptom-gallery-item {
                    .no-link-image {
                      img {
                        padding: 0;
                      }

                      .item-title {
                        height: unset;
                      }
                    }
                  }
                }
              }
            }
          }

          .text-left-symptom-carousel-right {
            padding: $section-padding 0;

            .text-left-symptom-carousel-right-inner {
              max-width: 100%;
              display: flex;
              width: 100%;
              padding: 0;
              margin: 0 auto;
              gap: 50px;
              flex-direction: row;
              align-items: center;
              max-width: 935px;

              & > section {
                .symptom-gallery-container {
                  .symptom-gallery-item {
                    .no-link-image {
                      img {
                      }

                      .item-title {
                        font-size: 14px;
                        line-height: 18px;

                        a {
                          display: inline-flex;
                          position: relative;
                          width: 12px;
                          height: 14px;

                          sup {
                            position: absolute;
                            margin-top: -3px;
                            top: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .text-left-symptom-carousel-right {
            .text-left-symptom-carousel-right-inner {
              .left-side-container {
                display: flex;
                flex-direction: column;
                gap: 50px;
                width: 100%;

                .cta-small-container {
                  padding-top: 5px;

                  a {
                    color: $orange-1;
                    font-size: 1.2rem;
                    line-height: 1.5;
                    letter-spacing: 0;
                    font-weight: normal;
                    text-transform: uppercase;

                    p {
                      padding-right: 0px;
                      line-height: 1.5;
                    }
                  }
                }

                .section-article-container {
                  padding: 0;

                  .section-article-description {
                    & > div {
                      display: flex;
                      flex-direction: column;
                      gap: 20px;
                    }

                    p {
                      font-size: 1.2rem;
                      line-height: 1.5;
                    }
                  }
                }

                & > section {
                  gap: 20px;
                  padding:0px;

                  h4 {
                    font-size: 36px;
                    line-height: 44px;
                  }

                  & > div {
                    gap: 30px;

                    p {
                      font-size: 1.2rem;
                      line-height: 1.5;
                    }
                  }
                }
              }

              & > section {
                width: 100%;

                &:last-child {
                  & > div {
                    border-radius: 10px;
                  }

                  .symptom-gallery-container {
                    padding: 25px;
                    gap: 15px;
                    display: flex;

                    .symptom-gallery-item {
                      width: 100%;

                      img {
                        max-width: 63px;
                        max-height: 63px;
                      }

                      .item-title {
                        font-size: 1.2rem;
                        letter-spacing: 0;
                        line-height: 1.4;
                        font-weight: bold;
                        margin-left: 20px;
                      }
                    }
                  }
                }
              }
            }

            &:nth-child(2n) {
              .text-left-symptom-carousel-right-inner {
                & > section {
                  &:last-child {
                    & > div {
                      background: #fff;
                    }
                  }
                }
              }
            }

            &:nth-child(2n + 1) {
              .text-left-symptom-carousel-right-inner {
                & > section {
                  &:last-child {
                    & > div {
                      background: #f4f8fa;
                    }
                  }
                }
              }
            }

            &.accordion-carousel {
              .text-left-symptom-carousel-right-inner {
                &.flex-row-reverse {
                  flex-direction: row-reverse;
                }

                & > section {
                  .symptom-gallery-container {
                    background: transparent !important;
                    padding: 0;

                    .symptom-gallery-item {
                      border-bottom: 2px solid rgba(0, 99, 190, 0.5);

                      .no-link-image {
                        flex-wrap: wrap;
                        padding-bottom: 15px;
                        position: relative;

                        .item-alt {
                          font-size: 14px;
                          line-height: 18px;
                          max-width: 100%;
                          opacity: 0;
                          margin-top: -300px;
                          z-index: -1;

                          a {
                            display: inline-block;
                          }
                        }

                        .item-title {
                          max-width: 100%;
                          width: 100%;
                          cursor: pointer;
                          font-size: 1.2rem;
                          line-height: 1.4;

                          &::after {
                            content: "";
                            position: absolute;
                            right: 0;
                            width: 12px;
                            height: 12px;
                            margin-top: 2px;
                            border-top: 2px solid $orange-1;
                            border-left: 2px solid $orange-1;
                            transform: rotate(135deg);
                          }

                          &.open {
                            & ~ .item-alt {
                              opacity: 1 !important;
                              transition: opacity 1s linear 0.75s, margin-top 1s linear, z-index 0.1s linear;
                              margin-top: 0;
                              z-index: 1;
                            }

                            &::after {
                              transform: rotate(225deg);
                              transition-duration: 1s;
                            }

                            &.closing {
                              & ~ .item-alt {
                                opacity: 0 !important;
                                transition: opacity 0.25s linear, margin-top 1s linear 0.25s, z-index 0.1s linear;
                                margin-top: -300px;
                                z-index: 0;
                              }

                              &::after {
                                transform: rotate(135deg);
                                transition-duration: 1s;
                              }
                            }
                          }
                        }

                        img {
                          background: #fff;
                          padding: 5px;
                          max-width: 73px;
                          max-height: 73px;
                          border-radius: 20px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-systemic_juvenile_idiopathic_arthritis-page {
      .single-card-title {
        display: flex;
        justify-content: center;
      }

      .single-card-description {
        padding-top: 15px;
      }
    }

    &.dynamic-tyenne-page {
      .simple-section {
        padding:6rem Max(40px, unquote("(100vw - 1200px) / 2")) 0px;
      }

      .lower-section {
        .simple-section {
          p {
            font-size: 1.2rem!important;
          }
        }
        .text-left-symptom-carousel-right {
          &.accordion-carousel {
            .text-left-symptom-carousel-right-inner {
              section {
                .symptom-gallery-container {
                  &.symptom-gallery {
                    .symptom-gallery-item {
                      border: none;

                      .item-title {
                        display: none;
                      }

                      .item-alt {
                        font-size: 1.2rem!important;
                        opacity: 1!important;
                        margin-top: 0!important;
                        z-index: 1!important;
                        width:100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-biologics-and-biosimilars-page {
      .lower-section {
        &.content-page {
          .content-with-simple-image {
            padding: 30px $border-padding-on-side;
            margin: 0;
            background: transparent;
            display: flex;
            flex-direction: column-reverse;
            gap: 0;

            .simple-image-container {
              max-width: 935px;
              width: 100%;
              margin-inline: auto;
              display: flex;

              [data-rmiz-wrap="visible"] {
                margin: auto;
              }
            }

            .content-container {
              max-width: 935px;
              width: 100%;
              margin-inline: auto;
              gap: 25px;

              .simple-section {
                gap: 20px;

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }

            & + .simple-section {
              padding: $section-padding $border-padding-on-side;

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
                max-width: 935px;
                width: 100%;
                margin-inline: auto;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 30px;
                max-width: 935px;
                width: 100%;
                margin-inline: auto;

                p,
                span {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }

                ul {
                  gap: 20px;

                  li {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }

          & > .simple-section {
            .description-container {
              gap: 30px;

              p,
              span,
              li {
                font-size: 1.2rem;
                line-height: 1.5;
                color: #003565;
              }

              ul {
                gap: 20px;
              }
            }
          }

          .single-card-gallery-outer-container {
            padding-inline: $border-padding-on-side;
            max-width: unset;

            .single-card-gallery-container {
              max-width: 935px;

              .single-card-text-container {
                .single-card-description {
                  gap: 30px;

                  p {
                    line-height: 1.5;
                    font-size: 1.2rem;
                    letter-spacing: 0;
                    color: #003565;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-clinical-data-page {
    }

    &.dynamic-the-data-page,
    &.dynamic-clinical-data-page,
    &.dynamic-the-clinical-data-page,
    &.dynamic-phase-1-studies-page,
    &.dynamic-phase-3-studies-page,
    &.dynamic-phase_1_SC-page,
    &.dynamic-phase_1_IV-page,
    &.dynamic-phase_1_AI_vs_PFS-page,
    &.dynamic-die-klinischen-daten-page,
    &.dynamic-phase-1-studien-page,
    &.dynamic-phase-3-studien-page,    
    &.dynamic-datos-clínicos-tyenne-page,
    &.dynamic-datos-clínicos-idacio-page,
    &.dynamic-estudios-en-fase-I-page{
      .lower-section {
        &.content-page {
          & > .simple-section {
            padding-inline: $border-padding-on-side;

            .simple-section-title {
              font-size: 36px;
              line-height: 44px;
              width: 100%;
              margin-inline: auto;
            }

            .simple-section-subtitle {
              font-size: 24px;
              line-height: 29px;
              width: 100%;
              margin-inline: auto;
            }

            .description-container {
              gap: 30px;
              width: 100%;
              margin-inline: auto;

              p,
              span {
                font-size: 1.2rem;
                line-height: 1.5;
              }

              li:has(strong) {
                display: block;

                strong {
                  font-weight: 600;
                }
              }

              ul {
                gap: 20px;
              }
            }

            [data-rmiz-wrap="visible"] {
              max-height: unset;
              overflow-x: unset;

              img {
                max-height: unset;
              }
            }

            &:nth-of-type(4) {
              padding-bottom: $section-padding;
            }
          }

          .hero-section-text {
            & + .simple-section {
              padding-inline: 0;
            }
          }

          .content-with-simple-image {
            padding: 0 $border-padding-on-side 50px;
            flex-direction: row-reverse;
            gap: 56px;
            justify-content: center;

            .simple-image-container {
              width: 100%;
              max-width: calc((935px / 2) - (56px / 2));
            }

            .content-container {
              width: 100%;
              max-width: calc((935px / 2) - (56px / 2));

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 24px;
                  line-height: 29px;
                }

                .description-container {
                  gap: 30px;

                  p,
                  span {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }

                  ul {
                    gap: 20px;
                  }
                }
              }
            }

            & ~ .simple-section {
              padding-top: $section-padding;

              [data-rmiz-wrap="visible"] {
                img {
                  max-height: unset;
                  width: 100%;
                  height: auto;
                }
              }

              &:nth-of-type(6) {
                padding: $section-padding $border-padding-on-side;
              }

              &:nth-of-type(10) {
                padding-bottom: $section-padding;
              }
            }
          }

          .content-with-multiple-images {
            flex-direction: column;
            padding: $section-padding $border-padding-on-side;
            max-width: 100%;

            .multi-image-container {
              margin-inline: auto;
              width: 100%;

              .simple-section {
                [data-rmiz-wrap="visible"] {
                  overflow-x: unset;

                  img {
                    height: auto;
                    width: 100%;
                    max-height: unset;
                  }
                }
              }
            }

            & > .simple-section {
              gap: 20px;
              margin-inline: auto;
              width: 100%;

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }

              .simple-section-subtitle {
                font-size: 24px;
                line-height: 29px;
              }

              .description-container {
                gap: 30px;

                p {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }
              }
            }

            & + .simple-section {
              padding: $section-padding $border-padding-on-side;
              gap: 20px;

              .description-container {
                gap: 20px;

                ul {
                  gap: 15px;
                }
              }
            }
          }
        }
        
        .carousel-container{
          padding-inline:0;
          padding:0 $border-padding-on-side;
        }
        
      }
    }
    
    &.dynamic-estudios-en-fase-I-page{
      .lower-section {
        .carousel-container{
          padding-inline:0;
          padding:6rem $border-padding-on-side;
          margin-top:0px;
          margin-bottom:0px;
        }        
      }
    }

    &.dynamic-administration-option-page,
    &.dynamic-administration-options-page,    
    &.dynamic-administration-presentations-page,
    &.dynamic-applikationssysteme-page,
    &.dynamic-applikationssystemes-page,
    &.dynamic-presentaciones-de-administración-page,
    &.dynamic-guía-de-autoadministración-page,
    &.dynamic-toedieningsopties-page,
    &.dynamic-darreichungsformen-page,
    &.dynamic-darreichungsformen-tyenne-page,
    &.dynamic-darreichungsformen-otulfi-page,    
    &.dynamic-presentaciones-de-administración-tyenne-page,
    &.dynamic-administreringsformer-tyenne-page,
    &.dynamic-administreringsformer-idacio-page,
    &.dynamic-administreringsformer-otulfi-page
    {
      .lower-section {
        &.content-page {
          .carousel-container {
            padding: $section-padding $border-padding-on-side;
            background: transparent;
            margin-top:0px;
            margin-bottom:0px;

            .cards-wrapper {
              .card-wrapper {
                background: #fff;

                .card {
                  .link {
                    .content {
                      background: transparent;
                    }
                  }
                }
              }
            }
          }

          .simple-section {
            padding-inline: 0;

            & > * {
              width: 100%;
              margin-inline: auto;
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 30px;

              p {
                font-size: 1.2rem;
                line-height: 1.5;
                margin: 0;
              }
            }
          }
        }
      }
    }

    &.dynamic-supporting-patients-page {
      .lower-section {
        &.content-page {
          .simple-section {
            .description-container {
              gap: 30px;

              p,
              span,
              strong,
              li {
                font-size: 1.2rem;
                line-height: 1.5;
                color: #003565;
              }

              ul {
                gap: 20px;
              }
            }
          }
        }
      }
    }

    &.dynamic-enrolling-patients-page {
      .lower-section {
        &.content-page {
          .simple-section {
            .description-container {
              gap: 30px;

              p,
              span,
              strong,
              li,
              a {
                font-size: 1.2rem;
                line-height: 1.5;
              }

              a {
                color: $orange-1;
              }

              ul {
                gap: 20px;
              }
            }
          }
        }
      }
    }

    &.dynamic-caring-for-your-medication-page,
    &.dynamic-caring-for-your-otulfi-page{
      .lower-section {
        &.content-page {
          .gallery-items-outer-container {
            & + .gallery-items-outer-container {
              padding-bottom: 30px;

              & + .simple-section {
                .description-container {
                  margin-inline: auto;

                  p,
                  span,
                  a {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-kabicare-programme-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            & + .simple-section {
              .description-container {
                gap: 30px;
                display: flex;
                flex-direction: column;

                p {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }
              }
            }
          }

          .content-with-image {
            & ~ .simple-section {
              padding: $section-padding $border-padding-on-side;

              & > * {
                margin-inline: auto;
              }

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                gap: 30px;

                p {
                  font-size: 1.2rem;
                  line-height: 1.5;
                }
              }
            }
          }

          #references {
            & + .attachment-section {
              border: unset;
              padding-bottom: 0;

              .attachment-section-inner-container {
                border-bottom: 2px solid rgba(0, 99, 190, 0.5);
                padding-bottom: $section-padding;
              }

              & + .attachment-section {
                padding-top: $section-padding;
              }
            }
          }
        }
      }
    }

    &.dynamic-non-infectious-uveitis-page {
      .lower-section {
        &.content-page {
          .section-article-container {
            padding: $section-padding $border-padding-on-side;
          }

          .content-with-simple-image {
            padding: $section-padding $border-padding-on-side;
            flex-direction: row;
            gap: 80px;

            .simple-image-container {
              width: 100%;
            }

            .content-container {
              width: 100%;

              .simple-section {
                .description-container {
                  gap: 30px;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }

          .content-with-image {
            .content-with-image-inner-container {
              .text-column {
                & > .simple-section {
                  &:nth-child(1) {
                    .cta-small-container {
                      display: none;
                    }
                  }
                }
              }

              .image-container {
                align-items: center;
                justify-content: center;

                .styled-section-image {
                  width: auto;

                  .color-rectangle {
                    display: none;
                  }

                  .styled-section-image-inner {
                    margin: 0;
                    padding: 0;
                    border: unset;
                    box-shadow: unset;
                    border-radius: unset;

                    .image-wrapper {
                      &::before {
                        display: none;
                      }
                    }

                    .plus-sign {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-truths-vs-myths-page,
    &.dynamic-feiten-en-fabels-page,
    &.dynamic-wahrheit-oder-mythos-page,
    &.dynamic-istina-i-mitovi-page,
    &.dynamic-sant-eller-falskt-page{
      .lower-section {
        &.content-page {
          & > .simple-section {
            max-width: 100%;
            padding: $section-padding $border-padding-on-side;

            & > * {
              max-width: 935px;
              margin-inline: auto;
              width: 100%;
            }

            .simple-section-title {
              font-size: 36px;
              line-height: 44px;
            }

            .simple-section-subtitle {
              font-size: 24px;
              line-height: 32px;
            }

            .description-container {
              p {
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: 0;
              }
            }
          }

          .content-with-simple-image {
            padding: $section-padding $border-padding-on-side;
            flex-direction: row;
            gap: 80px;
            align-items: center;

            .simple-image-container {
              width: 100%;

              [data-rmiz-wrap="visible"] {
                display: block;
                border-radius: 20px;

                img {
                  max-width: unset;
                  border-radius: 20px;
                }
              }
            }

            .content-container {
              width: 100%;

              .simple-section {
                gap: 10px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .cta-small-container {
                  a {
                    p {
                      font-size: 1.2rem;
                      line-height: 1.5;
                    }
                  }
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                  }
                }
              }
            }

            &:nth-child(even) {
              .simple-image-container {
                [data-rmiz-wrap="visible"] {
                  background: #fff;
                }
              }
            }

            &:nth-child(odd) {
              .simple-image-container {
                [data-rmiz-wrap="visible"] {
                  background: #F4F8FA;
                }
              }
            }
          }

          .single-card-outer-container {
            margin: 0;
            padding: $section-padding $border-padding-on-side;

            .single-card-container {
              padding: 0;
              min-height: unset;
              border-radius: unset;

              .single-card-inner-container {
                gap: 60px;
                flex-direction: column;

                .single-card-text-container {
                  gap: 20px;
                  max-width: unset;

                  .single-card-title {
                    font-size: 36px;
                    line-height: 44px;
                    margin: 0 !important;
                    max-width: calc(50% - 30px);
                  }

                  .single-card-description {
                    gap: 50px;
                    width: 100%;
                    flex-direction: row;

                    p {
                      font-size: 1.2rem;
                      line-height: 1.5;
                      width: 100%;
                    }
                  }
                }

                [data-rmiz-wrap="visible"] {
                  position: relative;
                  padding: 0;
                  bottom: unset;
                  top: unset;
                  right: unset;
                  z-index: unset;
                  width: 100%;
                  border-radius: 20px;

                  img {
                    border-radius: 20px;
                    max-width: unset;
                    width: 100%;
                  }
                }
              }
            }
          }

          .content-with-image {
            .content-with-image-inner-container {
              align-items: center;
            }
          }
        }
      }
    }

    &.dynamic-contact-us-page,
    &.dynamic-cookie-statement-page,
    &.dynamic-terms-and-conditions-page,
    &.dynamic-adverse-events-page,
    &.dynamic-privacy-policy-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            padding: $section-padding $border-padding-on-side;
            min-height: unset;

            .hero-section-text-inner-container {
              padding: 0;
              position: relative;
              max-width: 100%;
              top: unset;
              left: unset;
              bottom: unset;
            }
          }

          .simple-section {
            .description-container {
              gap: 30px;

              p,
              span,
              strong,
              a,
              li {
                font-size: 1.2rem;
                line-height: 1.5;
              }

              h4 {
                font-size: 30px;
                line-height: 36px;
              }

              h2 {
                font-size: 36px;
                line-height: 44px;
              }

              ul,
              ol {
                gap: 20px;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-make-a-request-page,
  &.dynamic-realizar-una-solicitud-page,
  &.dynamic-gör-en-beställning-page{
    .lower-section {
      &.main-page {
        margin: 0;

        .carousel-container {
          background: #fff;
          padding: $section-padding $border-padding-on-side;
          margin-top:0px;
          margin-bottom:0px;

          .cards-wrapper {
            justify-content: center;
          }
        }
      }
    }
  }

  .MuiPaper-root {
    ul {
      li {
        color: #003565;
        font-size: 1.2rem;
        line-height: 1.5;
        letter-spacing: 0;
        font-weight: normal;
      }
    }
  }
}

.description-container,
.body-18,
.section-article-description,
.single-card-description,
.item-alt {
  sup,sub {
    font-size: 10px !important;
    line-height: 14px !important;
    color: #0063be;
  }
}

@media (min-width: 2799px) {
  .dynamic-page {
    &.dynamic-goal-setting-page {
      .lower-section {
        &.content-page {
          max-width: 1200px;

          .full-width-content-with-cta {
            padding-left: 0;

            & + .full-width-content-with-cta {
              padding-left: 15px;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}
