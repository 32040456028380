.page-container {
    .content-wrap {
        .edit-form.toolbox.medications {
            flex-direction: row;
            flex-wrap: wrap;

            & > p {
                width: 100%;
            }

            div {
                max-width: 635px;
            }
        }

        .Mui-disabled {
            opacity: 0.4;
        }
    }
}
