@import '../_util/mixin.scss';

.header {
  display: flex;
  justify-content: space-between;
  height: 64px;
  width: 100%;
  z-index: 12;
  box-shadow: 4px 4px 10px rgba(24, 44, 76, 0.0993225);
  background-color: white;
  position:sticky;
  top:0;

  &.home {
    position: sticky;
    top: 0;
  }

  .side-container {
    display: flex;

    .left-side {
      margin: auto 0 auto 2rem;

      img {
        width: 110px;
        height: auto;
      }
    }
  }

  .hamburger-menu {
    fill: $blue-1;
    margin: auto 2rem auto 0;

    &-icon {
      width: 20px;
      height: 16px;
      position: relative;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer;
      z-index: 15;
    }

    &-icon span {
      border-radius: 999px;
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #0063be;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
    }

    &-icon span:nth-child(1) {
      top: 0;
      transform-origin: left center;
    }

    &-icon span:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
      transform-origin: left center;
    }

    &-icon span:nth-child(3) {
      bottom: 0;
      transform-origin: left center;
    }
  }

  .menu {
    background-color: white;
    min-height: 100vh;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 12;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      box-shadow: none;
      position:fixed;
      
      
      .side-container a img {
        opacity: 0;
        animation: fade-out 0.5s ease .5s forwards;
      }
    }

    &.slide-left {
      animation: slide-left-full 0.5s ease 1 normal;
    }

    &-links {
      padding-top: 5rem;
      cursor: auto !important;

      &.my-treatment {
        padding-top: 0;
      }

      .menu-item {
        padding: 0 $mobile-menu;

        p, a {
          font-size: 16px;
          margin: 0;
        }

        a {
          display: inline-block;
          margin: 12px 0;
        }
      }
      
      .MuiAccordionSummary-root {
        padding-right: calc(12px + #{$mobile-menu});
      }
    }

    .collapsible-menu {
      &-details {
        p, a  {
          color: #003565;
          margin: 8px 0;
          font-weight:400;
        }

        .lvl1 {
          padding: 10px 0;
        }
      }

      .MuiAccordionSummary-expandIcon {
        color: #0063be;
      }

      .MuiAccordionSummary-expandIcon.Mui-expanded {
        transform: rotate(180deg) !important;
      }
    }

    .auth-button {
      padding: 2rem 2rem 5rem;
      display: flex;
      flex-direction: column;
      gap: 15px;

      button {
        width: 100%;
        font-size: 16px;
        height: 40px;

        span {
          font-size: inherit;
        }
      }
    }
  }

  &.menu-open {
    padding: 0;
    position: sticky;
    top: 0;

    & ~ .content-wrap {
      z-index: -1;
    }

    .hamburger-menu-icon {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 0;
          left: 1px;
        }

        &:nth-child(2) {
          width: 0;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 14px;
          left: 1px;
          background: #00C2DF;
        }
      }
    }
  }

  // control nav component a tag font size from here
  .left-side,
  .middle,
  .menu {
    div,
    a {
      color: $blue-1;
      cursor: pointer;
      line-height: 1.3;
      font-size: $navbar-font-size;
      font-weight: 500;
      text-align: start;
      text-decoration: none;

      &:hover {
        color: $orange-1;
      }

      &.active {
        color: $orange-1;
      }
    }
  }

  .menu div {
    cursor: default;
  }

  .left-side {
    display: flex;
    padding: 0;

    & > a {
      margin: auto;

      picture {
        img {
          height: auto;
          width: 100%;
        }
      }
    }

    .home-logo {
      display: flex;
    }

    .mobile-logo {
      display: flex;
      height: 71px;
      margin: 26px 0 18px 5.8vw;
      width: 71px;

      img {
        max-width: 100%;
      }
    }
  }
}


.backdrop-filter {
  backdrop-filter: blur(10px);
  background: $linear-blue-1;
}


