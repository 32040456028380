.section-article {
  &-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding:50px 25px;
  }

  &-title {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    & > div {
      font-weight: bold;
      letter-spacing: 0.4px;
    }
  }

  &-string {
    &-1 {
      color: $blue-7;
      font-size: 1.2rem;
      line-height: 32px;
      text-transform: uppercase;
    }

    &-2 {
      color: $blue-1;
      font-size: 36px;
      line-height: 44px;
    }
  }

  &-description {
    color: $blue-7;
    font-size: 1.2rem;
    line-height: 32px;
    letter-spacing: 0.4px;

    p {
      margin: 0;
    }
  }
}
