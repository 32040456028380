$white-1: #fff;
$white-2: rgba(251, 254, 255, 0.8);

$grey-1: #F4F8FA;
$grey-2: #E9F1F4;
$grey-3: #E0ECF2;

$blue-1: #0063be;
$blue-2: #00c2df;
$blue-3: #006ab4;
$blue-4: rgba(0, 194, 223, 0.8);
$blue-5: rgba(24, 44, 76, 1);
$blue-6: #f4f8fa;
$blue-7: #182C4C;
$blue-8: #07B6D0;
$blue-9: #01ABD8;
$blue-10: #0063BE;

$orange-1: #f7a900;

$tangerine: #E37E08;

$black-1: #000;
$black-2: rgba(0, 0, 0, 0.25);

$linear-blue-1: linear-gradient(180deg, rgba(0, 194, 223, 0.8) -59.98%, rgba(0, 106, 180, 0.8) 100%);
$linear-blue-2: linear-gradient(180deg, rgba(0, 194, 223, 1) -59.98%, rgba(0, 106, 180, 1) 100%);