$border-color: #D3D3D3;
$menu-item-offset: 20px;


.MuiFormControl-root {
  &.Select {
    max-width: unset;
    height: unset;

    .MuiFormLabel-root.Mui-focused {
      color: inherit;
    }

    .MuiInputLabel-outlined {
      position: relative;
      transform: unset;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .MuiOutlinedInput-root {
      .MuiSelect-select {
        font-size: 16px;
        font-weight: 500;
        padding: 12px 20px;
        padding-right: 32px;

        &[aria-expanded="true"] {
          ~fieldset {
            border-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          ~.Select__Icon {
            transform: rotate(180deg);
          }
        }

        &:focus {
          background-color: transparent;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: $border-color;
        border-radius: 10px;
      }

      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $border-color;
        }
      }
    }
  }

  .Select__Icon {
    position: absolute;
    right: 0;
    margin-right: 10px;
    transition: 0.3s ease transform;
    pointer-events: none;
  }
}


.Select__Popover {
  .MuiPaper-root {
    box-shadow: none;
    border: 1px solid $border-color;
    border-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0 20px;
    margin-top: -2px;
    width: 0;

    ul {
      all: unset;
      display: block;
      position: relative;
      padding: 10px 0;

      li {
        margin: 0;
        padding: 10px 0;
        line-height: 1;
        cursor: pointer;
        position: relative;
        z-index: 1;
        font-weight: 500;
        font-size: 16px;

        &::before {
          display: none;
        }

        &:hover {
          &::before {
            display: block;
            background: $border-color;
            position: absolute;
            z-index: -1;
            width: calc(100% + #{$menu-item-offset});
            height: 100%;
            top: 0;
            left: calc(calc(#{$menu-item-offset} / 2) * -1);
            margin: 0;
            opacity: 0.5;
            border-radius: 5px;
          }
        }
      }

      &::before {
        content: "";
        width: 100%;
        border-bottom: 1px solid $border-color;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .MuiMenu-paper {
    max-width: unset;
  }
}
