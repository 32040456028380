$card-wrapper-gap: 30px;

@media (min-width: 768px) {
  .dynamic-page {
    .MuiBreadcrumbs-root {
      display: block;
      margin-bottom: 64px;
      margin-top: 34px;
    }

    .upper-section {
      min-height: 415px;

      .hero {
        &-container {
          padding-bottom: 75px;

          .logo-container {
            display: block;
            height: 565px;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 63px;
            width: 565px;
            z-index: 1;
    
            img {
              height: auto;
              width: 100%;
            }
          }

          > .MuiFormControl-root {
            width: 350px;
          }
        }

        &-title {
          font-size: 55px;
          line-height: 50px;
        }
      }

      &::after {
        bottom: -71%;
        height: 90%;
      }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////// Lower section default
    .lower-section {
      
      /////////////////////////////////////////////////////////
      ///////////// Hero Area      
      
      .hero-section-text {

        .hero-section-text-inner-container {
            width: 60%;
            padding:8rem 25px;
            left: 0;
        }
  
        .hero-section-image-container {
  
          .hero-section-image {

          }
          
          &::before {
            background: transparent linear-gradient(91deg, #182c4c 5%, rgba(24,44,76,0.7) 100%) 0% 0% no-repeat padding-box;
          }             
  
          &::after {
          }
        }
  
        & ~ .simple-section {
        }
        
      }
      
    /////////////////////////////////////////////////////////
    ///////////// Carousel Container and cards    
    
      .carousel-container {

        .MuiBox-root {
          padding: 0;
          margin: 0 auto 50px;

          h4 {
            font-size: 36px;
            line-height: 44px;
            margin: 0 0 20px;
          }

          .body-18,
          p {
            font-size: 18px;
            line-height: 30px;
          }
        }

        .cards-wrapper {
          gap: 2rem;
          padding: 0;

          &.reduced-card-wrapper {
            justify-content: center;

            .card-wrapper {
              max-width: 450px;
            }
          }

          .card-wrapper {
            padding: 1rem;
            border-radius: 10px;
            background: #fff;
            max-width: calc(100% / 3 - 2rem * 2 / 3);

            .card {
              height: 100%;
              width:100%;

              .link {
                max-width: unset;
                max-height: unset;
                height: 100%;

                picture {
                  max-width: 100%;
                  max-height: 311px;
                  width: 100%;
                  height: auto;

                  img {
                    object-fit: cover;
                    display: block;
                    width: 100%;
                    height: auto;
                    max-width: 100%;
                    max-height: 311px;
                    border-radius: unset;
                  }
                }

                .content {
                  position: relative;
                  margin: 2rem 0 0;
                  padding: 0;
                  gap:1rem;
                  height:100%;

                  .content-title {
                    color: #0063BE;
                    font-size: 1.4rem;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 1.3;
                    text-align: left;
                    margin: 0;
                  }

                  .content-description {
                    margin: 0px 0 0;

                    color: #003565;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 1.4;
                    font-weight: normal;

                    p,
                    span {
                      color: inherit;
                      font-size: inherit;
                      letter-spacing: inherit;
                      line-height: inherit;
                      font-weight: inherit;
                    }
                  }
                }

                .read-more {
                  padding-top: 0;
                  margin-top: auto;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  .text {
                    color: $orange-1;
                    font-size: 1rem;
                    font-weight: normal;
                    letter-spacing: 0;
                    line-height: 1.4;
                    text-transform: uppercase;
                    margin-right: 5px;
                  }

                  .arrow-container {
                    color: $orange-1;
                    transition: margin-left .5s ease;
                    letter-spacing: 0;

                    img {
                      max-height: 11px;
                      max-width: 13px;
                      width: 100%;
                      height: 100%;
                      display: block;
                    }
                  }

                  &:hover {
                    .arrow-container {
                      margin-left: .5em;
                    }
                  }
                }
              }
            }
          }
        }

        &:nth-child(2n + 1) { //even
          background: #fff;

          .cards-wrapper {
            .card-wrapper {
              background: #F4F8FA;

              .card {
                a {
                  .content {
                    background: #F4F8FA;
                  }
                }
              }
            }
          }
        }

        & ~ .carousel-container {
          margin-top: 0;

          .MuiBox-root {
            padding-top: 0;
          }
        }
      }     
      
      
      &.no-max-width {

        .content-with-simple-image{
           padding: $section-padding $border-padding-on-side;
           flex-direction:row;
           gap:4rem;
           
           .simple-image-container{
             width:100%;
             display:flex;
             align-items:center;               
           }
           
           &.image-on-right-two-thirds, &.image-on-left-two-thirds{
             .simple-image-container{
               width:100%;
               display:flex;
               align-items:center;              
             }
           }
           
           &.image-on-right-half, &.image-on-left-half{
             .simple-image-container{
               width:100%;
               display:flex;
               align-items:center;  
             }
           }           
           
           
           .simple-image-container{
             
             &.simple-image-framed{
               padding:2.5rem;
             }
             
             div{
              margin-left: auto;
              width: 100%;
              height: auto;
              display: flex; 
               
               img{
                 width:100%;
                 height:auto;
               }
             }
             
             
           }
           .content-container{
             width:100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 2rem;
            
            .simple-section{
              display:flex;
              flex-direction:column;
              gap:1.25rem;
              
              h4{
                font-size:36px;
                line-height:44px;
                margin-bottom:0px;
                
              }
            }
             
           }
        }       
       
        
      }  
      
      
      &.content-page {
        text-align: left;
      }

      &.main-page{
        text-align: left;
        display: block;
        padding-bottom: $section-padding;
        
        section {
          &.section-0 {
            margin-top: $section-padding;
          }
        }        
        
      }

      .logo-container {
        display: block;
        height: 242px;
        width: 220px;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: -41px;
        z-index: 1;

        img {
          height: auto;
          width: 100%;
        }
      }

      .MuiBreadcrumbs-root {
        display: block;
        margin-top: 25px;
        font-size: 20px;
      }

      .item-image {
        max-width: 100%;
        max-height: 100%;
        width:100%;
      }

      > div,
      > section {
        max-width: unset;

        h4 {
          margin-block-start: 0;
        }
      }

      .column-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 30px;
        margin-top: 10px;
      }

      .white-card-split-section-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
      }

      .fullwidth-white {
        width: 100%;
        max-width: none;
        display: flex;
        justify-content: center;
        background: white;
        padding-bottom: 0;
      }

      .fullwidth-transparent {
        .single-card-gallery-container {
          padding: 0;
          background: transparent;

          .single-card-text-container {
            .single-card-description {
              font-weight: normal;
            }

            .single-card-title {
              margin-bottom: 9px !important;
              text-transform: unset;
              color: #0063BE;
              line-height: 26px;
              font-size: 20px;
            }
          }

          & > section {
            .cards-wrapper {
              gap: 30px;
              margin-top: 24px;

              .card-wrapper {
                max-width: 100%;
                flex: 1;

                .card {
                  picture {
                    img {
                      max-width: unset;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .cards-wrapper {
        margin-bottom: 30px;
        justify-content: flex-start;
        column-gap: 32px;
        flex-direction: row;
        flex-wrap: wrap;

        .card-wrapper {
          display: flex;
          flex-direction: column;

          .card {
            margin: 0 auto 0 auto;

            .link {
              max-width: 350px;
              max-height: 350px;

              picture {
                max-width: 350px;
                max-height: 350px;

                img {
                  max-width: 350px;
                  max-height: 350px;
                }
              }
            }

            img {
              height: 288px;
              width: 288px;
            }
          }
        }
      }

      .single-card {
        &-gallery-container {
          .cards-wrapper {
            .card {
              display: flex;
              align-items: flex-end;

              img {
                height: auto;
                width: auto;
                max-width: 318px;
                border-radius: 0;
              }
            }
          }
        }
      }

      .section-article {
        &-title {
          margin-bottom: 26px;
        }

        &-string-2 {
          font-size: 36px;
          line-height: 44px;
        }
      }

      .video-container {
        &.loaded {
          margin-bottom: 88px;
        }
      }

      .references {
        display: block;
        font-size: 12px;
        line-height: 19px;
      }
    }
  }
}
