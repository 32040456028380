@import '../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .page-container {
    .content-wrap {
      .order-kit-page {
        .contact-us-form {
          .contact-us-form-items {
            form {
              > div {
                &:nth-child(8) {
                  margin-top: 10px;
                  grid-row: auto;
                  grid-column: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
