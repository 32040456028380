@import url("https://use.typekit.net/cuf1mji.css");

body {
  margin: 0;
  font-family: "interstate", arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background-color: white;
  font-size: 20px;
  line-height: 32px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}