@import '../../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .symptom-gallery-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &.more-items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
