$border-padding-on-side: Max(40px, unquote("(100vw - 1400px) / 2"));

@import '../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .page-container {
    .content-wrap {
      .contact-us-page {
        .contact-us-form {
          gap: 40px;
          max-width: 935px;
          gap: 20px;

          .contact-us-form-header {
            .main-title {
              font-size: 40px;
              line-height: 48px;
            }
          }

          .contact-us-form-items {
            form {
              grid-template-columns: 1fr 1fr;
              gap: 40px;

              > div {
                &:nth-child(8) {
                  grid-row: 3/6;
                  grid-column: 2/3;
                  display: flex;
                  flex-direction: column;
                  height: 100%;

                  .textarea-container {
                    width: 100%;
                    flex-basis: 100%;
                    display: flex;
                  }
                }
              }

              .button-container {
                grid-column: 2/3;
                display: flex;
                align-items: flex-end;
                flex-direction: column;

                button {
                  width: 225px;
                }
              }

              p {
                &#component-helper-text {
                  order: 9;
                  position: absolute;
                  bottom: -60px;
                  left: 0;
                  margin-left: 0;
                  margin-right: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
