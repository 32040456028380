.select,
.select-container,
datepicker,
timepicker {

    ~.MuiFormHelperText-root, .Mui-error {
        margin: 3px 14px 0;
    }

    border-radius: 10px;

    .MuiSelect-root {
        color: #182c4c;
        font-size: 16px;
        font-weight: 300;
        line-height: 140.5%;
        min-width: 0;
    }

    .MuiSelect-select {
        &:focus {
            border-radius: 10px;
        }
    }

    .MuiInputLabel-outlined {
        padding: 15px 16px 15px 0;
        transform: translate(14px, 0) scale(1);

        &.MuiInputLabel-shrink {
            color: #003565;
            transform: translate(23px, -30px) scale(0.75);
        }
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        &:hover {
            border: 1px solid #d3d3d3;
        }
    }

    .MuiOutlinedInput-root {
        border: 1px solid #d3d3d3;

        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid #d3d3d3;
            }
        }
    }

    .MuiInputBase-root {
        background: white;
        border-radius: 10px;
        height: 50px;

        fieldset {
            display: none;
        }

        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid #d3d3d3;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            top: 0;
            border: 1px solid #d3d3d3;

            legend {
                display: none;
            }
        }

        &:hover {
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid #d3d3d3;
            }
        }
    }

    .MuiAutocomplete-inputRoot {
        padding: 0;

        input {
            padding: 0 0 14px 14px;
            color: #182c4c;
            font-size: 16px;
            font-weight: 300;
            line-height: 140.5%;
            min-width: 0;

            &.MuiAutocomplete-input{
                padding: 0 7px;
                position: relative;
                top: -10px;
                height: 50px;
            }
        }

        .Select__Icon {
            position: absolute;
            right: 0;
            margin-right: 5px;
            transition: 0.3s ease transform;
            pointer-events: none;
            fill: #182c4c;
        }

        .MuiSelect-icon {
            top: 0px;
            right: 7px;
        }

        .MuiAutocomplete-popupIndicatorOpen {
            transform: none;
        }

        .MuiAutocomplete-clearIndicator {
            display: none;
        }

        .MuiAutocomplete-popupIndicator {
            width: 30px;
            height: 30px;

            &:hover {
                background-color: transparent;
            }
        }
    }
}

.dynamic-page {
    .select {
        .MuiSelect-iconOutlined {
            right: 0;
            top: 0;
        }
    }
}

datepicker,
timepicker {
    .MuiInputLabel-outlined {
        &.MuiInputLabel-shrink {
            color: #182c4c;
        }
    }
}
