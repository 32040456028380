@import '../_util/mixin.scss';

@media (min-width: $desktop_nav_width_from) {
  .header {
    background: white;
    backdrop-filter: blur(20px);

    height: $navbar-height;
    width: 100%;
    min-height: unset;

    padding: 12px Max(40px, unquote("(100vw - 1450px) / 2"));
    box-shadow: unset;

    display: flex;
    justify-content: center;
    align-items: center;

    .side-container {
      height: $navbar-height;
      width: 100%;
      margin: 0;

      display: flex;
      justify-content: space-between;

      .left-side {
        height: 100%;
        margin: 0;
      }

      .middle {
        margin: auto 0 auto 0;
        padding: 0;
      }
    }

    .hamburger-menu, .menu {
      display: none;
    }

    .middle {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin: 0;

      height: 100%;

      .menu-element-levels {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;

        .level-1 {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin: 0 0.95rem;

          height: 100%;

          .children-indicator {
            @include children-indicator(5px);
          }

          &:hover {
            .level-2 {
              display: block;
            }

            .children-indicator {
              @include hover-children-indicator();
            }
          }
        }

        .level-2 {
          z-index: 1;
          position: absolute;

          top: 75px;
          left: 0;
          background: white;

          padding: 15px 0;

          box-shadow: 0 4px 6px $black-2;
          border-radius: 20px;
          min-width:300px;

          div {
            a {
              margin: 7.5px 30px;
              box-sizing: border-box;
              font-weight:500;
              font-size:0.95rem;
            }
          }

          .has-children {
            .children-indicator-container-2 {
              margin-right: 30px;

              .children-indicator-2 {
                @include children-indicator(5px);

                // override mixin values
                margin: 0;
              }
            }

            &.open {
              .level-3 {
                display: block;
              }

              div {
                .children-indicator-2 {
                  @include hover-children-indicator();
                }
              }
            }
          }

          :hover {
            .children-indicator-2 {
              span {
                background-color: $orange-1!important;
              }
            }
          }
        }

        .level-3 {
          background: $grey-1;

          .has-children {
            .children-indicator-container-3 {
              margin-right: 30px;

              .children-indicator-3 {
                @include children-indicator(5px);

                // override mixin values
                margin: 0;
              }
            }

            &.open {
              .level-4 {
                display: block;
              }

              div {
                .children-indicator-3 {
                  @include hover-children-indicator();
                }
              }
            }
          }

          :hover {
            .children-indicator-3 {
              span {
                background-color: $orange-1!important;
              }
            }
          }
        }

        .level-4 {
          background: $grey-2;
        }

        .level-3, .level-4 {
          div {
            margin: 0;
          }

          .parent-level {
            margin: 0;
            padding: 0;
          }
        }

        .level-2, .level-3, .level-4 {
          display: none;

          .parent-level {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
          }
        }

      }
    }

    .auth-button-container {
      margin: 0;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      gap:0.5rem
    }

    .account-button-container {
      border-radius: 50%;
      overflow: hidden;
      height: 40px;
      width: 40px;
    }

    button {
      &.auth-button {
        border-width: 1px;
        box-shadow: none;
        filter: none;
        height: unset;
        margin: 0;
        padding: 9.5px 19px;
        width: unset;

        &:first-of-type {
          margin-left : 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        span {
          font-size: 0.95rem;
          letter-spacing: 0;
          line-height: 19px;
        }

        &.account-button {
          padding: 0;
          margin: 0;
          min-width: unset;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

@media (max-width: $desktop_nav_width_from - 1px) {
  .header {
    .side-container {
      .auth-button-container {
        display: none;
      }

      .middle {
        .level-1 {
          display: none;
        }
      }
    }
  }
}
