.page-container {
  .content-wrap {
    .coolbag-page {
      padding: 50px 25px;
      background: #f4f8fa;

      .coolbag-form {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .main-title {
          font-size: 30px;
          line-height: 36px;
          font-weight: bold;
          letter-spacing: 0;
          color: #00c2df;
          margin: 0;
        }

        form {
          display: grid;
          grid-template-columns: 1fr;
          row-gap: 20px;
          margin-top: 15px;

          button {
            padding: 11px 20px 10px;
            height: unset;
          }

          div {
            max-width: none;
            margin: 0;
          }
        }

        .MuiButton-label {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 1px;
        }

        .already-ordered {
          margin-top: 15px;

          & > div {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0;
            text-align: left;
          }
        }
      }
    }
  }
}

.MuiAutocomplete-popper {
  & > * {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }

  ul {
    li {
      &::before {
        display: none;
      }
    }
  }
}
