.MuiDialog-root {
    .MuiBackdrop-root {
        background: rgba(0,0,0,0.2); 
    }

    .MuiDialog-paper {
        background: #ffffff;
        box-shadow: none;
        border-radius: 20px;
        min-width: auto;
        margin: 25px;

        .MuiDialogTitle-root {
            padding: 25px 25px 0;

            h2 {
                font-size: 25px;
                line-height: 35px;
                color: #0063be;
            }
        }

        .MuiDialogContent-root {
            padding: 0 25px;

            .MuiDialogContentText-root {
                padding: 5px 0 0 0;
            }

            .MuiTypography-body1 {
                font-weight: 600;
                font-size: 0.8rem;
                line-height: 1.5;
                text-align: center;
                letter-spacing: 0.4px;
                color: $blue-1;
            }
        }

        .MuiDialogActions-root {
            display: flex;
            flex-direction: column-reverse;
            padding: 0 25px 25px;

            .button2 {
                border: 2px solid $orange-1;
                border-radius: 30px;
                background-color: white;

                &:hover{
                    background-color: $orange-1;
                    
                    .MuiButton-label {
                        color: white
                    }
                }

                .MuiButton-label {
                    color: $orange-1
                }
            }

            .MuiButton-root {
                margin: 11px 15px;
                width: 270px;
                box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
            }
        }
    }
}

.restricted-popup, .form {
    &.MuiDialog-root {
        .MuiDialog-paper {
            .MuiDialogContent-root {
                padding: 0 1.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap:1rem;
            }
        }
    }
}

.form {
    &.MuiDialog-root {
        .MuiDialog-paper {
            .MuiDialogContent-root {
                padding: 0 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .MuiDialog-paper {
                .MuiDialogActions-root {
                    padding: 0 25px 25px;
                }
            }
            }
    }
}

.ConfirmDialog {
    .MuiBackdrop-root {
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(10px);
    }
}