$border-padding-on-side: Max(40px, unquote("(100vw - 1400px) / 2"));

@import '../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .page-container {
    .content-wrap {
      display: flex;
      flex-direction: column;

      .coolbag-page {
        flex-grow: 1;
        max-width: 100%;
        padding: 100px $border-padding-on-side;

        .coolbag-form {
          margin-inline: auto;
          max-width: 935px;

          .main-title {
            font-size: 40px;
            line-height: 48px;
          }

          form {
            grid-template-columns: 1fr 1fr;
            gap: 30px;

            .button-container {
              grid-column: 1/3;
              grid-row: 4/5;

              button {
                width: unset;
                min-width: 215px;
              }
            }

          }

          .button-wrapper {
            button {
              padding: 11px 20px 10px;
              min-width: 215px;
              width: unset;

              span {
                line-height: 19px;
              }
            }
          }
        }
      }
    }
  }
}
