$gap: 16px;

.symptom-gallery-item {
  display: flex;

  a,
  .no-link-image {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .item-image {
      margin: 0 10px 0 0;
      width: 100px;
      height: auto;
      margin-bottom:1rem;
    }

    .item-title {
      color: inherit;
      font-size: 1.2rem;
      letter-spacing: 0.4px;
      line-height: inherit;
      font-weight: normal;
      margin-left:0px;
      margin-bottom:0.5rem;
    }
  }
  
  .item-alt{
    a{
      display:inline;
      width:auto;
    }
  }
}
