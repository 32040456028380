.page-container {
  .content-wrap {
    .MuiContainer-root {
      .select-container {
        margin-bottom: 0;

        &.has-error {
          height: auto;
          margin-bottom: 41px;

          p {
            &.Mui-error {
              position: absolute;
              bottom: -22px;
            }
          }
        }
      }
    }
  }
}
