@import "color";

$desktop_width_from: 1280px;
$desktop_nav_width_from: 1280px;
$navbar-height: 6rem;
$navbar-font-size: 0.95rem;
$mobile-menu: 2rem;
$section-padding: 6rem;
$section-padding-mobile: 3rem;



@mixin children-indicator($top: 0) {
  height: 10px;
  margin-left: 5px;
  position: relative;
  width: 13px;

  span {
    background-color: #0063be;
    display: inline-block;
    height: 2px;
    position: absolute;
    top: $top;
    transition: all .2s ease;
    width: 8px;

    &:first-of-type {
      left: 0;
      transform: rotate(45deg);
    }

    &:last-of-type {
      right: 0;
      transform: rotate(-45deg);
    }
  }
}

@mixin hover-children-indicator() {
  span {
    background-color: $orange-1;

    &:first-of-type {
      transform: rotate(-45deg);
    }

    &:last-of-type {
      transform: rotate(45deg);
    }
  }
}

@mixin hide-tablet-image-frame {
  .content-with-image-inner-container {
    .styled-section-image {
      .left-rectangle, .right-rectangle {
        display: none;
      }

      .styled-section-image-inner {
        border: 0;
        box-shadow: none !important;

        .image-wrapper::before {
          display: none;
        }

        .left-plus-sign, .right-plus-sign {
          display: none;
        }
      }
    }
  }
}
