.page-container {
    .content-wrap {
        .edit-form.toolbox.appointments {
            &.edit,
            &.create {
                > div {
                    &:nth-of-type(3) {
                        flex-basis: 182px;
                    }

                    &:nth-of-type(4) {
                        flex-basis: 134px;
                        justify-content: left;
                        margin-left: 14px;

                        .MuiTextField-root {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .toolbox-container.appointments {
            .toolbox-list {
                .group-name {
                    text-align: left;
                    margin-left: 22px;
                }
            }
        }
    }
}
