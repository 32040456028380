@import "../_util/_color.scss";

@mixin switchColor($color) {
  .switch {
    $color: $color;
  
    input {
      &:checked + .slider {
        background-color: $color;
      }
  
      &:focus + .slider {
        box-shadow: 0 0 1px $color;
      }

      &:checked + .slider:before {
        background-color: white;
      }
    }
  
    .value-label {
      &.off {
        color: $color;
      }
    }
  
    .slider {
      border: 2px solid $color;
  
      &:before {
        background-color: $color;
      }
    }
  }
}

.switch {
  $color: $orange-1;
  position: relative;
  display: inline-block;
  width: 102px;
  height: 45px;
  text-align: left;
  min-width: 102px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $orange-1;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $orange-1;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(53px);
      -ms-transform: translateX(53px);
      transform: translateX(53px);
      background-color: white;
    }
  }

  .value-label {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.6125px;
    position: absolute;
    margin: 14px 0 0 14px;
    text-transform: uppercase;

    &.on {
      color: white;
    }

    &.off {
      color: $orange-1;
      margin-left: 50px;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: 2px solid $orange-1;
    border-radius: 40px;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 34px;
      width: 34px;
      left: 6px;
      bottom: 4px;
      background-color: $orange-1;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
      transition: all 0.2s ease-in;
    }
  }
}
