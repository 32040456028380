$border-padding-on-side: Max(40px, unquote("(100vw - 1400px) / 2"));

@import '../../_util/mixin.scss';

@media (min-width: 1320px) {
  .page-container {
    .content-wrap {
      .my-progress {
        padding: 100px $border-padding-on-side 0;
        max-width: 100%;
        display: flex;

        .page-outer-container {
          max-width: 1400px;
          margin-inline: auto;
          position: relative;

          .page-inner-container {
            margin-bottom: 20px;
            max-width: Min(935px, calc(935 / 1400 * 100%));

            h1 {
              font-size: 40px;
              line-height: 48px;
            }
          }

          .subtitle {
            margin: 0;
            max-width: Min(935px, calc(935 / 1400 * 100%));

            p {
              font-size: 18px;
              line-height: 30px;
            }
          }

          .content-container {
            display: flex;
            flex-wrap: wrap;
            gap: 22px;

            .left-side {
              max-width: Min(935px, calc(935 / 1400 * 100%));
              width: 100%;

              .toolbox-list {
                margin-bottom: 50px;
                max-width: unset;

                .MuiTabs-root {
                  .MuiTabs-flexContainer {
                    button {
                      padding: 8px;

                      span {
                        font-size: 18px;
                        line-height: 30px;
                      }
                    }
                  }
                }
              }

              .toolbox-summary-container {
                gap: 30px;

                & > .MuiBox-root {
                  padding: 40px;
                  border-radius: 20px;

                  p {
                    &.toolbox-group {
                      font-size: 36px;
                      line-height: 44px;
                    }

                    &.empty-message {
                      font-size: 18px;
                      line-height: 30px;
                    }
                  }

                  .list-item {
                    margin-bottom: 0;

                    &:first-of-type {
                      margin-top: 15px;
                    }

                    button {
                      padding: 40px;

                      .name {
                        font-size: 18px;
                        line-height: 30px;
                      }
                    }
                  }
                }
              }
            }

            .right-side {
              margin-top: -155px;
              gap: 30px;
              max-width: Min(440px, calc((1400 - 935 - 25) / 1400 * 100%));
              //position: absolute;
              //top: 0;
              //right: 0;

              .card-container {
                border-radius: 20px;
                padding: 40px;

                img {
                  max-height: unset;
                  max-width: 80px;
                  flex: 0 1 80px;
                }

                h5 {
                  margin: 0 0 0 15px;
                  line-height: 44px;
                  font-size: Clamp(30px, unquote("2vw + 4px"), 36px);;
                }

                .progress-tile-text {
                  font-size: 18px;
                  line-height: 30px;
                }

                .progress-cta-container {
                  margin-top: 35px;
                  padding: 11px 15px 10px;
                }
              }
            }

            .toolbox-pdf-section {
              padding: 50px $border-padding-on-side;
              margin-top: 50px;
              border-radius: unset;
              margin-inline: Min(-40px, unquote("(100vw - 1400px) / 2 * -1"));
              font-size: Clamp(16px, unquote("1vw + 3px"), 20px);
              width: 100vw;

              .toolbox-pdf-inner-container {
                gap: 30px;
                max-width: 1400px;
                flex-direction: row;
              }

              &-card {
                border-radius: 20px;
                padding: 40px;
                width: 100%;

                position: relative;
                height: 100%;

                max-width: 455px;

                &-image-container {
                  max-height: 57px;
                  max-width: 80px;
                  flex: 0 1 80px;
                }

                &-image {
                  max-height: 57px;
                  max-width: 80px;
                }

                &-title {
                  margin: 0 0 0 15px;
                  line-height: 44px;
                  font-size: Clamp(28px, unquote("2vw + 2px"), 36px);

                  max-height: 57px;
                }

                &-description {
                  margin-top: 15px;
                  padding-top: 15px;
                  font-size: 16px;
                  line-height: 22px;

                  margin-bottom: 55px;
                  height: calc(100% - 40px - 57px - 30px); //button height + title height + border padding and margin
                }

                &-cta-container {
                  flex-basis: 100%;
                  border-radius: 20px;
                  background: $orange-1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 11px 15px 10px;

                  position: absolute;
                  bottom: 40px;
                  margin: 0;
                  left: 40px;
                  right: 40px;

                  a {
                    font-size: 16px;
                    color: #fff;
                    font-weight: normal;
                    letter-spacing: 0;
                    line-height: 19px;
                    text-transform: uppercase;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}
