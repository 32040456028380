@import '../../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
    .page-container {
        .content-wrap {
            .goals {
                .page-outer-container {
                    max-width: 935px;
                    margin: auto;
                    padding: 100px 0 50px;
                    
                }

                .page-inner-container {
                    .main-title {
                        font-size: 40px;
                    }
                } 
                
                .toolbox-list {
                    .Tabs {
                        button span {
                            font-size: 18px;
                        }
                    }

                    .button-text {
                        span {
                            font-size: 18px;
                        }
    
                        &:hover {
                            background: $orange-1;
                            box-shadow: 0px 2px 12px rgba(24, 44, 76, 0.14);
    
                            span {
                                color: #FFF;
                            }
                        }
                    }
                }

                .subtitle {
                    font-size: 18px;
                    line-height: 1.5;
                }

                .custom-goal-label {
                    margin-right: 12px;
                    margin-bottom: 0px;
                }

                .default-button-container{
                    max-width: 635px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                }
            }
        }
    }
}
