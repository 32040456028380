@import '../../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
    .page-container {
        .content-wrap {
            .edit-form.toolbox.appointments {
                
                &.edit,
                &.create {
                    > div {
                        &:nth-of-type(2) {
                            flex-basis: 100%;
                        }
                    }

                    
                }

                &.create {
                    .button-container {
                        margin-top: 13px;
                    }
                }

                &.edit {
                    .button-container {
                        margin-top: 6px;
                    }
                }
            }
        }
    }
}
