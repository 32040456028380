@import '../../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .page-container {
    .content-wrap {
      main {
        padding: 0 167px;

        &.thank-you-for-registering {
          padding: 100px Max(40px, unquote("(100vw - 1400px) / 2"));

          .thank-you-inner-container {

            h1 {
              font-size: 40px;
              line-height: 48px;
            }

            .text-wrapper {
              p {
                font-size: 18px;
                line-height: 30px;
              }
            }
          }
        }
      }

      .MuiTypography-h3,
      .MuiBreadcrumbs-root {
        text-align: start;
      }

      .register-form {
        margin-top: 99px;

        & > div {
          &:nth-of-type(2) {
            margin-top: 35px;
          }
        }

        .MuiBreadcrumbs-root {
          display: block;
        }

        .main-title {
          font-size: 38px;
          line-height: 50px;
          text-align: left;
        }

        .MuiInput-root,
        .MuiTextField-root,
        .input-container {
          max-width: 443px;
        }

        .register-form-items {
          form {
            max-width: 700px;

            & > div {
              & > label {
                & > span {
                  font-size: 16px;
                }
              }
            }

            button {
              margin: 0;
              max-width: 255px;

              span {
                font-weight: bold;
              }
            }
          }
        }
      }

      .register-main-page {
        align-items: center;
        background: #f4f8fa;
        justify-content: center;

        .register-card {
          max-width: 935px;
          width: 100%;
          flex-basis: unset;

          .register-box {
            display: flex;
            border: 5px solid #fff;
            padding: 30px;
            border-radius: 20px;
            margin-bottom: 85px;
            margin-top: 85px;
            flex-direction: row;

            .register-left-side {
              background: #fff;
              border-radius: 20px 0 0 20px;

              h1 {
                color: #00c2df;
                font-size: 40px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 48px;
                padding: 50px 50px 0 50px;
                text-align: left;
              }

              .register-card-content {
                margin: 20px 0 0;
                padding: 0 50px;

                p {
                  font-size: 16px;
                  line-height: 25px;
                  letter-spacing: 0;
                  color: #003565;
                  font-weight: normal;
                }

                ul {
                  margin-top: 20px;
                  padding: 0;

                  li {
                    font-size: 16px;
                    line-height: 25px;
                    color: #003565;
                    font-weight: normal;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    margin: 8px 0;

                    &:last-child {
                      margin-bottom: 0;
                    }

                    &:first-child {
                      margin-top: 0;
                    }
                  }
                }
              }

              .register-card-note {
                padding: 30px 0 50px;
                margin: 30px 50px 0;
                font-size: 16px;
                font-weight: normal;
                line-height: 25px;
                letter-spacing: 0;
                border-top: 2px solid #d3d3d3;

                .foot-note {
                  margin: 0;
                }
              }
            }

            .button-outer-container {
              background: #0063be;
              border-radius: 0 20px 20px 0;
              display: flex;
              width: 100%;
              max-width: 320px;
              padding: 0;

              .register-buttons-container {
                margin: auto 50px;
                flex-direction: column;
                gap: 50px;

                & > div {
                  min-height: 200px;
                  min-width: 224px;
                  margin: 50px 0;
                  display: flex;
                  background: #fff;
                  border-radius: 20px;

                  &:first-child {
                    margin-bottom: 0;
                  }

                  &:last-child {
                    margin-top: 0;
                  }
                }

                .register-button {
                  .button-content-container {
                    padding: 26px;

                    .button-label {
                      font-size: 16px;
                      line-height: 21px;
                    }

                    img {
                      max-width: 120px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .register-page {
        background: #f4f8fa;
        width: 100%;
        max-width: unset;
        padding: 0;
        display: flex;

        .register-form {
          margin: 84px auto;
          max-width: 695px;
          width: 100%;
          border: 5px solid #fff;
          padding: 30px;
          border-radius: 20px;

          &.register-hcp-form {
            max-width: 922px;

            .register-form-inner-container {
              padding: 50px;

              h1 {
                &.main-title {
                  text-align: center;
                }
              }

              .register-form-items {
                margin-top: 30px;

                form {
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);

                  .button-container {
                    grid-column: 1/3;
                  }
                }
              }
            }
          }

          .register-form-inner-container {
            background: #fff;
            padding: 50px 95px;
            border-radius: 20px;

            h1 {
              color: #00c2df;
              font-weight: bold;
              font-size: 40px;
              letter-spacing: 0;
              line-height: 48px;
            }

            .register-form-items {
              form {
                margin-top: 30px;
                row-gap: 30px;

                .button-container {
                  justify-self: center;
                  width: 100%;

                  button {
                    height: unset;
                    max-width: unset;
                    padding: 10.5px 19px;
                    box-shadow: none;

                    span {
                      font-size: 16px;
                      line-height: 19px;
                      letter-spacing: 0;
                      font-weight: normal;
                    }
                  }
                }

                .input-container {
                  label {
                    color: #d3d3d3;
                  }
                }

                .select-container {
                  label {
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: 0;
                    line-height: 19px;
                    color: #d3d3d3;

                    &.MuiInputLabel-shrink {
                      color: #003565;
                    }

                    &.Mui-focused {
                      color: #003565;
                    }
                  }

                  .checkbox-label {
                    .checkmark {
                      border: 1px solid #d3d3d3;
                      outline: none;
                      height: 15px;
                      width: 15px;
                      border-radius: 50%;
                      top: -10px;
                      left: 0;

                      &::after {
                        left: 4px;
                        width: 4px;
                        height: 8px;
                        border-width: 0 1px 1px 0;
                        border-color: #003565;
                      }
                    }
                  }

                  .MuiFormControlLabel-label {
                    p,
                    a {
                      font-size: 16px;
                      letter-spacing: 0;
                      line-height: 25px;
                      font-weight: normal;
                      color: #003565;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
