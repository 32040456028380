.checkbox-grid {
    .checkbox-label {
        .checkmark {
          border: 1px solid #d3d3d3;
          outline: none;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          top: 5px;
          left: 0;
    
          &::after {
            left: 4px;
            width: 4px;
            height: 8px;
            border-width: 0 1px 1px 0;
            border-color: #003565;
          }
        }
    
        .MuiFormControlLabel-label {
          p,
          a {
            font-size: 16px;
            letter-spacing: 0;
            line-height: 19px;
            font-weight: normal;
            color: #003565;
          }
        }
      }

      .MuiFormControlLabel-label {
        font-size: 16px;
        color: #003565;
      }
}

