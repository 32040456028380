.page-container {
  .content-wrap {
    .reminder {
      padding: 50px 25px;
      background: #F4F8FA;

      .page-outer-container {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .page-inner-container {
          .main-title {
            margin: 0;
            font-size: 30px;
            color: #00C2DF;
            line-height: 36px;
            letter-spacing: 0;
            font-weight: bold;
          }
        }

        .subtitle {
          display: flex;
          flex-direction: column;
          gap: 20px;

          p {
            margin: 0;
            font-size: 16px;
            line-height: 22px;
            color: #003565;
            letter-spacing: 0;
          }
        }

        .content-container {
          margin-top: 15px;

          .reminder-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 0;

            .switch-container {
              margin: 0;
              display: flex;
              align-items: center;
              gap: 15px;
              padding-right: 30px;

              .reminder-switch-label {
                font-size: 22px;
                line-height: 26px;
                color: #0063BE;
                letter-spacing: 0;
                font-weight: bold;
                flex: 1;
              }
            }

            .reminders-box {
              display: flex;
              flex-direction: column;
              gap: 20px;

              .tab-container {
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;

                .MuiTabs-root {
                  min-height: unset;
                  border-radius: 25px;
                  width: 100%;

                  button {
                    min-height: unset;
                    height: unset;
                    padding: 16px 25px 15px;
                  }
                }
              }

              .button-wrapper {
                margin-top: 10px;
                 
                button {
                  margin-left: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
