@media (min-width: 768px) {
  .home {
    .hero {
      padding-bottom:12rem;
      position:relative;
      
      .ellipse,
      .ellipse-2 {
        left: -47%;
        top: 58vw;
        width: 170%;
      }

      .ellipse-2 {
        left: -53%;
        top: 54vw;
      }

      .hero-image-container {
        position:absolute;
        left:0px;
        top:0px;
        width:100%;
        height:100%;
        padding-bottom:6rem;
        
        &::after {
          height: 20%;
          display:none;
        }
      }
      
      .hero-img {
        img {
          //width: 100%;
          //height: 100%;
          object-position: center;
        }
      }      
    }

    .hero-content {
      background-color: transparent;
      padding: 8rem 2rem 5rem 2rem;
      position: relative;
      text-align: left;
      top: 0px;
      width:65%;
      
      &-container {
        p {
          margin: 0;
          font-size: 1.2rem;
          line-height: 1.3;
          font-weight: normal;
        } 
      }  
    }

    .lower-section {
      margin: 0;
      background-color: transparent;
      display: flex;
      justify-content: center;
      position: absolute;
      height:12rem;
      bottom:0px;

      & * {
        box-sizing: border-box;
      }
      
      .home-page-carousel {
        height:12rem;
        padding-left:2rem;
        padding-right:2rem;
        .carousel-item {
          height:12rem;
          width:100%;
          
          picture {
            img {
              width:100%;
              height:100%;
              object-fit:cover;
              object-position:center;
            }
          }
  
          .text {
            
            h2 {
              font-size: 1.2rem;
              font-weight: bold;
              line-height: 1.3;
              margin: auto auto 2rem;  
            }
          }          
          
            
        }
      }

      .carousel-wrapper {
        & .hidden {
          display: block;
        }

        .carousel {
          .move {
            display: none;
          }

          .photo {
            height: 550px;
            width: 535px;
          }
        }
      }

      .gallery {
        width: 1440px;
        max-width: 768px;
        display: flex;
        flex-direction: row;

        & > div {
          margin: 0;
          position: absolute;

          &.photo {
            picture {
              img {
                height: 365px;
                width: 354px;
                object-fit: cover;
              }
            }
          }

          & > .text {
            bottom: 0px;
          }
        }

        .hidden {
          display: none;
        }

        .move {
          display: flex;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          justify-content: center;
          bottom: 70px;
          color: white;
          font-weight: normal;
          font-size: 23px;
          line-height: 40px;
          letter-spacing: 0.4px;

          .left,
          .right {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
              width: 55px;
              height: 55px;
            }
          }

          .status {
            display: flex;
            align-items: center;
            width: 55px;
            justify-content: center;
            margin: 0 25px 0 27px;
          }
        }
      }

      .photo {
        position: absolute;
        border-radius: 30px;
        top: 50px;
        width: 354px;
        height: 365px;
        transition: all 2s linear;

        &:first-of-type {
          transform: translate(calc(-100% - 40px), 40px);
        }

        &.active {
          transform: translate(0%, -40px);
        }

        &:nth-of-type(3) {
          transform: translate(calc(100% + 40px), 40px);
        }

        &.arc-down-right {
          animation: 0.5s linear 0s 1 normal forwards running arc-down;
        }

        &.arc-down-left {
          animation: 0.5s linear 0s 1 normal forwards running arc-down-left;
        }

        &.arc-up-right {
          animation: 0.5s linear 0s 1 normal forwards running arc-up;
        }

        &.arc-up-left {
          animation: 0.5s linear 0s 1 normal forwards running arc-up-left;
        }

        &.slide-in-right {
          animation: 0.45s linear 0.05s 1 normal forwards running slide-in-left, fade-out 0.5s;
          animation-fill-mode: forwards;
        }

        &.slide-out-right {
          animation: 0.5s linear 0s 1 normal forwards running slide-out-right, fade-in 0.5s;
          animation-fill-mode: forwards;
        }

        &.slide-in-left {
          animation: 0.45s linear 0.05s 1 normal forwards running slide-in-right, fade-out 0.5s;
          animation-fill-mode: forwards;
        }

        &.slide-out-left {
          animation: 0.5s linear 0s 1 normal forwards running slide-out-left, fade-in 0.5s;
          animation-fill-mode: forwards;
        }
      }
    }
    
    .left-side-linear-blur {
      background: transparent linear-gradient(90deg, #FFFFFF 30%, #ffffff0f 100%) 0 0 no-repeat padding-box;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }    
    
  }
}
