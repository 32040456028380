@import "../../scss/_util/mixin";

.footer {
  background: white;
  color: $black-1;
  padding: $section-padding-mobile 2rem $section-padding-mobile;

  .disclaimer {
    &.situational {
      margin: 0 0 25px 0;
    }

    p,
    a {
      color: #003565;
      font-family: 'interstate', arial, sans-serif;
      font-weight: normal;
      letter-spacing: 0;
      font-size: 0.8rem;
      line-height: 1.4;
    }

    a {
      text-decoration: underline;
    }
  }

  p,
  a {
    font-size: 0.8rem;
    line-height: 1.4;
    margin: 0;
    text-decoration: none;
    color: $black-1;
  }

  .bottom {
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 2rem;
  }

  .logo {
    margin-bottom:2rem;

    img {
      max-width: 240px;
      min-width: 240px;
      width: 100%;
      height: auto;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .links {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;

    a {
      padding: 0;
      color: #0063BE;
      font-weight: normal;
      font-size: 0.8rem;
      line-height: 1.4;

      &:last-child {
        margin-right: 0;
      }
    }

    div {
      display: flex;
      font-size: 0.8rem;
      line-height: 1.4;
      width: 50%;
      padding-bottom:0.5rem;

      &:last-child {
        a {
          &::after {
            content: "";
            margin: 0;
          }
        }
      }
    }
  }
}

.footer-smpc-static, .footer-smpc-fixed{
    padding: 0rem;
    border: 0px solid #c7c7c7;
    margin-bottom: 2rem;
    max-width: 1200px;
    margin: 0px auto 6rem;
    
    div.smpc-block{
      margin-bottom:1rem;
    }
    
    div:last-child{
      margin-bottom:0px;
    }
    
    h4{
      margin:0px 0px 0.25rem;
      font-size:1rem;
      line-height:1.4em;
    }
    
    p{
      font-size:0.8rem;
      line-height:1.4em;
      margin:0px 0px 0.5rem;
    }    
}

.footer-smpc-fixed{
    position: fixed;
    width: 100%;
    top: calc(100vh - 10rem);
    left: 50%;
    transform:translateX(-50%);
    z-index: 10;
    max-width: 1200px;
    margin-bottom: 0px;
    background: rgba(255,255,255,0.9);
    transition:top 0.3s ease;
    height:75vh;
    overflow:hidden;
    padding:2rem 8rem 3rem 3rem;
    border:0px;
    box-shadow:0px -20px 20px rgba(0,0,0,0.06);
    backdrop-filter:blur(8px);
    scrollbar-width:thin;
    
    
    .smpc-inner{
      height:auto;
    }
    
    &.hide-smpc{
      top: 100vh;
    }
    
    &.smpc-expanded{
      top:25vh;
      overflow-x:hidden;
      overflow-y:auto;
      
      &.hide-smpc{
        top:100vh;
      }
    }
    
    .open-smpc{
      position:absolute;
      top:1rem;
      right:2rem;
      cursor:pointer;
      font-size:1.5rem;
    }
    
}

.preparation-container {
  display: flex;
  flex-direction:column;
  font-size: 0.8rem;
  line-height: 1.4;
  gap:1rem;
    
  .date {
    p {
      font-size: 0.8rem;
      line-height: 1.4;      
    }
  }

  .legal-number {

  }
}

table.footer-stats{
  border-collapse: collapse;width: 100%;border: 1px solid #e4eaec;margin: 1rem 0px;
  
  tbody{
    tr{
      background: #ffffff;
      
      &:nth-child(odd){
        background: #f4f8fa;
      }
      
      td{
        padding: 1rem;width: 25%;
        
        p{
        width: 100% !important;
        text-align: left !important;
        color:rgba(0,0,0,1) !important;
        font-size:0.8rem !important;
        }
        
        &.col-lg{
          width:35%;
        }
        
        &.col-sm{
          width:15%;
        }
      }
    }
  }
}