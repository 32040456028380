@import '../../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .page-container {
    .content-wrap {
      .register-form {
        &.register-hcp-form {
          .register-form-items {
            margin: 56px 0 70px;
          }
        }
      }
    }
  }
}
