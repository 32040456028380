.tab-data-wrapper {
  padding-top: 25px;

  .alternate-background {
    > div {
      border-radius: 10px;

      .link {
        &-wrapper {
          all: unset;
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 25px;
          cursor: pointer;

          p {
            margin: 0;
            font-size: 16px;
            color: #003565;
            font-weight: 500;
            line-height: 1.3;
            letter-spacing: 0;

            &.date {
              font-weight: 400;
            }
          }
        }

        &:nth-child(2n) {
          background: white;
        }

        &.transparent {
          background: transparent;
        }
      }

      &.odd {
        > div {
          &:nth-child(2n-1) {
            background: white;
          }

          &:nth-child(2n) {
            background: transparent;
          }

          &.transparent {
            background: transparent;
          }
        }
      }
    }
  }
}

.toolbox-list {
  max-width: 635px;
  width: 100%;
  transition: all 0.2s;
  text-align: left;

  .disabled {
    opacity: 0.38;
  }

  .date {
    font-weight: 300;
    font-size: 16px;
    line-height: 140.5%;
    min-width: 100px;
  }
}

.flex-grow {
  flex-grow: 1;
}

.flare {
  max-width: 350px;
  background-color: white;
  border-radius: 20px;
  margin: 0 25px;

  & > div {
    padding: 36px 35px;

    &.open {
      padding: {
        right: 21px;
        left: 19px;
      }
    }

    &.success {
      padding: 34px 64px 21px 64px;
    }
  }

  img {
    margin-top: 15px;
  }

  form {
    margin: 23px 0 0 0;

    .button-container {
      display: flex;
      justify-content: space-between;
    }

    .input {
      border: 2px solid #e0ecf2;
      box-sizing: border-box;
      border-radius: 10px;
      resize: none;
      width: 100%;

      &:focus {
        outline: none !important;
        border: 2px solid $orange-1;
      }
    }

    input {
      height: 50px;
      padding: 11px 0px 11px 16px;
    }

    textarea {
      margin-top: 10px;
      margin-bottom: 25px;
      height: 177px;
      padding: 13px 40px 0 16px;
    }

    button {
      width: 140px;
    }
  }
}

h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.4px;
  color: $orange-1;
  margin: 13px 0 0;
}

.body-18 {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;

  &.text-align-center {
    text-align: center;
  }

  &.font-weight-light {
    font-weight: 300;
  }

  > p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.4px;
    margin:0;
  }
}

.body-18-bold {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  color: $orange-1;
}

.body-16 {
  font-weight: 300;
  font-size: 16px;
  line-height: 140.5%;
  color: $blue-7;
}

.body-16-bold {
  font-weight: 500;
  font-size: 17px;
  line-height: 140.5%;
  color: $blue-1;
}

.offer-info-bold {
  font-weight: bold;
  font-size: 16px;
  line-height: 140.5%;
  color: $blue-7;
  margin-right: 20px;
}

.offer-info {
  font-weight: 300;
  font-size: 16px;
  line-height: 140.5%;
  text-decoration-line: underline;
  color: $blue-7;
}

.goal {
  font-weight: 400;
  font-size: 16px;
  line-height: 140.5%;
  color: $blue-7;
}

.subhead {
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: $blue-7;
}

.page-container {
  .content-wrap {
    .medications {
      padding: 50px 25px;
      background: #f4f8fa;

      .page-outer-container {
        .page-header {
          .header-wrapper {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .page-inner-container {
              .main-title {
                color: #00c2df;
                font-size: 30px;
                line-height: 36px;
                letter-spacing: 0;
                font-weight: bold;
                margin: 0;
              }
            }

            .subtitle {
              color: #003565;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
              font-weight: normal;

              p {
                margin: 0;
              }
            }

            .editing {
              margin-top: 5px;

              a {
                button {
                  border-radius: 20px;
                  width: 100%;
                  height: unset;
                  min-height: unset;
                  padding: 11px 20px 10px;
                  box-shadow: none;

                  span {
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 19px;
                    font-weight: normal;
                  }
                }
              }
            }
          }

          button {
            border-radius: 20px;
            width: 100%;
            height: unset;
            min-height: unset;
            padding: 11px 20px 10px;
            box-shadow: none;
            margin-top: 20px;

            span {
              font-size: 16px;
              letter-spacing: 0;
              line-height: 19px;
              font-weight: normal;
            }
          }
        }

        .content-container {
          .toolbox-list {
            .tab-data-wrapper {
              border-radius: 10px;
              background: #fff;
              padding: 25px;
              margin-top: 25px;

              .table-header-wrapper {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .table-header {
                  color: #0063be;
                  font-weight: bold;
                  font-size: 22px;
                  letter-spacing: 0;
                  line-height: 26px;
                  border-bottom: 2px solid rgba(0, 99, 190, 0.5);
                  padding-bottom: 15px;
                }

                .table-column-row {
                  display: flex;

                  & > div {
                    color: #003565;
                    font-weight: bold;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 20px;

                    &.row-empty {
                      font-weight: normal;
                    }
                  }

                  .row-name {
                    flex: 1 0 calc(116 / 275 * 100%);
                  }

                  .row-date {
                    flex: 1 0 calc(73 / 275 * 100%);
                    text-align: center;
                  }

                  .row-time {
                    flex: 1 0 calc(51 / 275 * 100%);
                    text-align: center;
                  }
                }
              }

              .alternate-background {
                display: flex;
                flex-direction: column;
                gap: 15px;
                margin-top: 10px;

                .MuiBox-root {
                  .link-wrapper {
                    padding: 0;

                    p {
                      font-size: 14px;
                      letter-spacing: 0;
                      line-height: 22px;
                      font-weight: normal;
                      color: #003565;
                    }

                    &.medication-link-wrapper {
                      .medication-name {
                        flex: 1 0 calc(116 / 275 * 100%);
                      }

                      .date {
                        flex: 1 0 calc(73 / 275 * 100%);
                        text-align: center;
                      }

                      .time {
                        flex: 1 0 calc(51 / 275 * 100%);
                        text-align: center;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .goals {
      .page-outer-container {
        .content-container {
          .toolbox-list {
            .tab-data-wrapper {
              .alternate-background {
                & > div {
                  &:nth-child(2n + 1) {
                    background: #fff;
                  }
                }
              }

              .table-empty-wrapper {
                background: #fff;
                padding: 25px;
                border-radius: 10px;

                .table-empty {
                  .row-empty {
                    color: #003565;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .toolbox-container {
      min-height: 55vh;
    }

    .no-padding-mobile {
      padding: 0;
    }

    a {
      .edit-button {
        width: 55px;
        height: 55px;
        background-color: $orange-1;
      }
    }

    .relative {
      position: relative;
    }

    .center-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .center-align {
      display: flex;
      align-items: center;
    }

    .justify-center-mobile {
      justify-content: center;
    }

    .flex {
      display: flex;
    }

    .space-between-desktop {
      display: inherit;
    }

    .space-between-all {
      display: flex;
      justify-content: space-between;
      width: inherit;
    }

    .text-align-left {
      text-align: left;
    }

    .time-picker {
      .chevron {
        &::before {
          bottom: -3px;
        }
      }
    }

    .justify-flex-end-all {
      justify-content: flex-end;
      margin-left: auto;
    }

    .mobile-breadcrumbs {
      display: flex;

      a {
        display: flex;
        align-items: center;

        img {
          height: 0.7em;
          width: auto;
        }
      }

      p {
        margin: 2px 0 0 10px;
        font-size: 16px;
        font-weight: 500;
        color: $orange-1;
        letter-spacing: 0;
      }
    }
  }
}

$border-color: #d3d3d3;

// This could be moved to it's own component but not
// sure if all input are like this on new design
.DatePicker,
.TimePicker {
  display: flex;
  position: relative;

  .MuiFormControl-root {
    max-width: unset;
    width: 100%;
    margin: 0;
    height: unset;
  }

  .MuiFormLabel-root {
    &.MuiInputLabel-shrink {
      position: relative;
      transform: unset;
      font-weight: 600;
      margin-bottom: 10px;
    }

    &.Mui-focused {
      color: rgba(24, 44, 76, 1);
    }
  }

  .MuiInputBase-input {
    font-size: 16px;
    padding-left: 20px;
    font-weight: 500;
    letter-spacing: 0;
  }
}

.ToggleCheckbox {
  > label {
    margin: 0;
  }

  .container {
    margin-bottom: 16px;
  }

  .checkmark {
    border-radius: 100%;
    color: #003565;
    width: 15px;
    height: unset;
    aspect-ratio: 1/1;

    &::after {
      left: 4px;
      top: 0px;
      width: 7px;
      height: 12px;
    }
  }
}

#component-helper-text {
  margin-top: 20px;
  word-break: break-word;
}

.ActionButton__Wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;

  button {
    width: 100%;
    height: 40px;
    font-size: 16px;
    box-shadow: none;
    border-width: 1px;

    span {
      font-size: inherit;
    }

    &.MuiButton-textPrimary {
      &:hover {
        border: 1px solid $orange-1;
      }
    }
  }
}

.Dialog__Toolbox {
  .MuiDialog-paper {
    .MuiDialogTitle-root {
      > * {
        color: $blue-1;
        font-size: 11px;
        letter-spacing: 0;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }

    .MuiDialogContent-root {
      .MuiTypography-body1 {
        color: #003565;
        text-align: start;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.3;
        //margin-bottom: 20px;
        margin-bottom: 0;
      }

      & > .MuiBox-root {
        margin-top: 20px;

        form {
          &.toolbox {
            &.medications, &.goals {
              display: grid;
              grid-template-columns: 1fr;
              grid-column-gap: 32px;
              grid-row-gap: 20px;

              .DatePicker,
              .TimePicker {
                margin: 0;

                > label {
                  padding: 0;
                }
              }

              .TimePicker {
                margin-top: 20px;
              }

              .input-container {
                max-width: unset;
                width: 100%;
                margin: 0;
                height: unset;
                flex-direction: column-reverse;

                label {
                  position: relative;
                  transform: unset;
                  font-weight: 600;
                  margin: 0 0 10px;
                  padding: 0;
                }
              }

              .MuiFormHelperText-root {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-reverse-mobile {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.form-container {
  .edit-form {
    display: flex;
    flex-direction: column;

    button {
      display: flex;
      width: 326px;
      margin: {
        top: 21px;
      }
    }
  }
}

.toolbox-modal {
  .popup-button-container {
    button {
      margin: 19px 0 0 0;
      width: 176px;

      &.hide-mobile {
        display: none;
      }
    }
  }

  &.goals {
    padding-top: 32px;

    .popup-button-container {
      button {
        width: 200px;
      }
    }

    &.delete {
      padding: 51px 60px 48px;
    }
  }
}
