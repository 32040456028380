@import '../../../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .symptom-gallery-container {
    .symptom-gallery-item {
      flex-direction: row;
      justify-content: flex-start;
      width:100%;
      
      a,
      .no-link-image {
        align-items: center;
      }
      
      .item-title {
        text-align: left;
        margin-left: 50px;
        width: 100%;
        flex-shrink:1;
        font-size:1.2rem;
        font-weight:normal;
        color:inherit;
        
        a{
          display:inline;
        }
      }

      .item-image {
        margin: 0 10px 0 0;
      }
      
      .item-alt{
        width:50%;
        flex-shrink:0;
        
        a{
          display:inline;
        }        
        
      }

      a,
      .no-link-image {
        flex-direction: row;
        width: 100%;
      }

      &.long-title {
        grid-column: span 2;
      }
      a{
        display:flex;
      }
    }
  }
}
