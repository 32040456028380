.page-container {
  .content-wrap {
    .my-progress {
      padding-top: 50px;
      background: #F4F8FA;

      .page-outer-container {
        .page-inner-container {
          margin-bottom: 15px;

          h1 {
            margin: 0;
            font-weight: bold;
            font-size: 30px;
            line-height: 36px;
            letter-spacing: 0;
            color: #00C2DF;
          }
        }

        .subtitle {
          margin-bottom: 20px;

          p {
            margin: 0;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px;
            font-weight: normal;
            color: #003565;
          }
        }

        .content-container {
          .left-side {
            .toolbox-list {
              margin-bottom: 25px;

              .MuiTabs-root {
                min-height: unset;
                border-radius: 25px;

                .MuiTabs-flexContainer {
                  button {
                    min-width: unset;
                    width: 100%;
                    height: auto;
                    min-height: unset;
                    padding: 12px;

                    span {
                      text-transform: uppercase;
                      font-size: 16px;
                      line-height: 22px;
                    }

                    &:first-child {

                    }

                    &:last-child {

                    }
                  }
                }
              }
            }

            .toolbox-summary-container {
              margin: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;

              & > .MuiBox-root {
                background: #fff;
                padding: 25px;
                border-radius: 10px;

                p {
                  &.toolbox-group {
                    font-size: 22px;
                    line-height: 26px;
                    letter-spacing: 0;
                    font-weight: bold;
                    color: #0063BE;
                    margin: 0;
                  }

                  &.empty-message {
                    border-top: 2px solid rgba(0, 99, 190, .5);
                    margin-top: 15px;
                    padding: 15px 0 0;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0;
                    font-weight: normal;
                    color: #003565;
                    margin-bottom: 0;
                  }
                }

                .list-item {
                  margin-bottom: 0;

                  &:first-of-type {
                    border-top: 2px solid rgba(0, 99, 190, .5);
                    margin-top: 15px;
                    padding-top: 15px;
                  }

                  &:nth-last-of-type(even) {
                    button {
                      background: #fff;
                    }
                  }

                  button {
                    all: unset;
                  }

                  button {
                    width: 100%;
                    padding: 15px;
                    background: #F4F8FA;
                    border-radius: 20px;
                    cursor: pointer;

                    .name {
                      font-size: 16px;
                      line-height: 22px;
                      letter-spacing: 0;
                      font-weight: normal;
                      color: #003565;
                    }
                  }
                }
              }
            }
          }

          .right-side {
            margin-top: 15px;
            display: flex;
            gap: 15px;
            flex-direction: column;

            .card-container {
              align-items: center;
              //aspect-ratio: 1 / 1;
              background: $white-1;
              border-radius: 10px;
              display: flex;
              flex-direction: row;
              padding: 25px;
              width: 100%;
              flex-wrap: wrap;

              img {
                height: auto;
                max-height: 34px;
                max-width: 48px;
                width: 100%;
                flex: 0 1 48px;
              }

              h5 {
                margin: 0 0 0 10px;
                color: #0063BE;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: 0;
                font-weight: bold;
                flex: 1 1 auto;
                text-align: left;
              }

              .progress-tile-text {
                flex-basis: 100%;
                margin-top: 15px;
                padding-top: 15px;
                border-top: 2px solid rgba(0, 99, 190, .5);
                font-size: 16px;
                line-height: 22px;
                font-weight: normal;
                letter-spacing: 0;
                color: #003565;
              }

              .progress-cta-container {
                flex-basis: 100%;
                margin-top: 15px;
                border-radius: 20px;
                background: $orange-1;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 11px 15px 10px;

                span {
                  font-size: 16px;
                  color: #fff;
                  font-weight: normal;
                  letter-spacing: 0;
                  line-height: 19px;
                }
              }

              .link-tile {
                width: 100%;
                display: flex;
                align-items: center;
              }
            }
          }

          .toolbox-pdf-section {
            align-items: center;
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 50px 25px;
            position: relative;
            margin: 25px -25px 0;

            .toolbox-pdf-inner-container {
              display: flex;
              flex-direction: column;
              gap: 15px;
            }

            &-card {
              align-items: center;
              display: flex;
              flex-direction: row;
              justify-content: center;
              border-radius: 10px;
              background: #F4F8FA;
              padding: 25px;
              flex-wrap: wrap;

              &-image-container {
                max-height: 34px;
                max-width: 48px;
                width: 100%;
                flex: 0 1 48px;
              }

              &-image {
                display: block;
                width: 100%;
                height: auto;
                max-height: 34px;
                max-width: 48px;
              }

              &-title {
                margin: 0 0 0 10px;
                color: #0063BE;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: 0;
                font-weight: bold;
                flex: 1 1 auto;
                text-align: left;
              }

              &-description {
                flex-basis: 100%;
                margin-top: 15px;
                padding-top: 15px;
                border-top: 2px solid rgba(0, 99, 190, .5);
                font-size: 16px;
                line-height: 22px;
                font-weight: normal;
                letter-spacing: 0;
                color: #003565;
              }

              &-cta-container {
                flex-basis: 100%;
                margin-top: 15px;
                border-radius: 20px;
                background: $orange-1;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 11px 15px 10px;

                a {
                  font-size: 16px;
                  color: #fff;
                  font-weight: normal;
                  letter-spacing: 0;
                  line-height: 19px;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }
  }
}
