// control navbar width from here
.getting-started-menu-item {
  .level-2 {
    width: max-content;
  }
}

.healthy-living-menu-item {
  .level-2 {
    width: 360px;
  }
}

.wellbeing-and-support-menu-item {
  .level-2 {
    width: 360px;
  }

}
.hcp-access-menu-item {
  .level-2 {
    width: 330px;
  }
}

.idacio®-menu-item {
  .level-2 {
      width: 300px;
  }
}

.tyenne®-menu-item {
  .level-2 {
    width: 330px;
  }
}

.make-a-request-menu-item {
  .level-3 {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-bottom: 15px;
  }
}

.level-2:has(.make-a-request-menu-item):has(.open) {
  padding-bottom: 0!important;
}
.level-2 {
  .make-a-request-menu-item {
    &.open {
      .level-3 {
        padding-bottom: 15px;
      };
    }
  }
}

.travel-menu-item {
  .level-3 {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-bottom: 15px;
  }
}
.level-2:has(.travel-menu-item):has(.open) {
  padding-bottom: 0!important;
}
.level-2 {
  .travel-menu-item {
    &.open {
      .level-3 {
        padding-bottom: 15px;
      };
    }
  }
}
