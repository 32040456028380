@media (min-width: 768px) {
    .page-container {
        .content-wrap {
            .goals {
                .page-outer-container {
                    max-width: unset;
                }

                .page-header {
                    flex-direction: row;
                    justify-content: space-between;
    
                    > div {
                        max-width: 575px;
                    }
    
                    .button-create {
                        width: auto;
                        align-self: flex-end;
    
                        span {
                            font-size: 16px;
                            letter-spacing: 0;
                        }
                    }
                }
            }
        }
    }
}