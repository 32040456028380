.single-card {
  &-inner-container {
    display: flex;
    flex-direction: column;
    position: relative;

    &.image-has-title {
      [data-rmiz-wrap="visible"] {
        display: flex;
        flex-direction: column;
      }

      .single-card-image-alt {
        color: $blue-1;
        font-weight: 500;
        width: 100%;
        text-align: center;
      }
    }

    [data-rmiz-wrap="visible"] {
      margin: 30px auto 0;
    }
  }

  &-gallery-container {
    border-radius: 30px;
    background: $grey-1;
    padding: 25px 27px 30px 26px;
  }

  &-title {
    color: $blue-7;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 34px;
    margin: 0 25px 15px;
    text-transform: uppercase;
  }

  &-description {
    color: $blue-7;
    font-weight: 300;
    margin: 0 25px;

    p {
      margin: 0;
    }

    ul{
      list-style-type: none;

      li {
        position: relative;
        margin: 12px 12px 12px 30px;

        &::before {
          content: "";
          position: absolute;
          left: -46px;
          height: 29px;
          width: 29px;
          background-image: url('../../../../resources/images/common/tickbox.png');
        }
      }
    }
  }

  &-image {
    border-radius: 30px;
    height: auto;
    margin-bottom: 23px;
    width: 100%;
  }
}
