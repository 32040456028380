@import '../_util/mixin.scss';

@media (min-width: $desktop_width_from) {
  .page-container {
    .content-wrap {
      padding: 0;

      .login-page {
        background: #f4f8fa;
        padding: 84px 0;

        .login-form {
          border: 5px solid #fff;
          border-radius: 20px;
          display: flex;
          flex: unset;
          margin: auto;
          max-width: 935px;
          padding: 30px;

          .login-left-side {
            background: #fff;
            border-radius: 20px 0 0 20px;
            padding: 50px;

            .main-title {
              h1 {
                font-size: 40px;
                line-height: 48px;
              }
            }

            & > p {
              font-size: 16px;
              line-height: 24px;
            }

            form {
              margin: 20px 0 20px;
            }

            .login-page-register-container {
              margin-top: 25px;
              padding-top: 30px;
              
              &.doc-check-allowed{
                margin-top: 10px;
                margin-bottom: 25px;
                padding-top: 0px;
                padding-bottom:25px;
                border-top:0px;
                border-bottom:1px solid #d3d3d3;
              }
            }
          }

          .login-image-container {
            background: #fff;
            border-radius: 0 20px 20px 0;
            max-width: 309px;
            width: 100%;
            display: block;

            img {
              display: block;
              border-radius: 0 20px 20px 0;
              height: 100%;
              width: auto;
              object-fit: cover;
              object-position: center;
            }
          }

          .MuiBreadcrumbs-root {
            display: block;
          }

          & > div {
            padding-top: 0;
          }

          .main-title {
            margin-bottom: 0;
            padding-top: 55px;

            h3 {
              text-align: left;
            }
          }

          .submit-button-container {
            margin: 116px 0 0 0;
            max-width: 255px;
          }
        }

        .register-card {
          margin-left: 15px;

          & > div {
            margin: 0;
            padding: 44px 39px 39px;
          }

          .MuiTypography-h4 {
            margin: 0;

            & > div {
              font-size: 38px;
              line-height: 50px;
            }
          }

          .register-card-content {
            margin: 40px 0 0;

            p {
              margin-bottom: 20px;
            }

            ul {
              li {
                margin-bottom: 22px;
              }
            }
          }

          .register-buttons-container {
            flex-direction: row;
            flex-wrap: nowrap;
            margin-top: 38px;

            & > div {
              margin-bottom: 0;

              &:first-of-type {
                margin-right: 15px;
              }

              &:last-of-type {
                margin-left: 15px;
              }

              button {
                margin: 0;
              }
            }
          }

          .MuiTypography-subtitle1 {
            margin: 32px 0 0;
          }
        }
      }
    }
  }
}
